<template>
  <div class="play-rule">
    <h4><nut-icon name="tips" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>{{$t('gameRules.h1')}}</h4>
    <dl>
      <dt>{{$t('gameRules.dt1')}}</dt>
      <dd>{{$t('gameRules.dd1_1')}}</dd>
      <dd>{{$t('gameRules.dd1_2')}}</dd>
      <dd>{{$t('gameRules.dd1_3')}}</dd>
      <dd class="img"><img :src="require('../assets/images/' + imgUrl + 'bg68.jpg')" /></dd>

      <dt>{{$t('gameRules.dt2')}}</dt>
      <dd>{{$t('gameRules.dd2_1')}}</dd>
      <dd><strong>{{$t('gameRules.dd2_2')}}</strong></dd>

      <dt>{{$t('gameRules.dt3')}}</dt>
      <dd>{{$t('gameRules.dd3')}}</dd>
      <dd class="img"><img :src="require('../assets/images/' + imgUrl + 'bg69.jpg')" /></dd>
      
      <dt>{{$t('gameRules.dt4')}}</dt>
      <dd>{{$t('gameRules.dd4')}}</dd>
      <dd class="img"><img :src="require('../assets/images/' + imgUrl + 'bg70.jpg')" /></dd>

      <!-- nut-button block type="info" @click="rulePopup = false">{{$t('gameRules.btn')}}</!-->
    </dl>

    

  </div> 
</template>

<script>

export default {
  name: 'GameRules',
  props: {},
  data() {
    return {
      imgUrl:''
    };
  },
  methods: {
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
  },
  setup() {}
};
</script>

<style scoped>
.popclass-pp {
  padding: 15px 5px 10px 15px !important;
  border-radius: 5px;
  width: 70%;
}
.popclass-pp h4 {
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 16px;
  margin: 0 !important;
  border-bottom: 1px solid #eee;
}
.popclass-pp .txbd {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.play-rule {
  padding: 20px;
}

.play-rule h4 {
  margin: 5px 0;
  font-size: 17px;
}
.nut-popup h4.h4 {
  margin: 20px 10px;
}
.play-rule dl {
  position: absolute;
  overflow-y: scroll;
  top: 50px;
  bottom: 20px;
}

.play-rule dt {
  margin: 20px 0; padding-left: 10px; height: 22px; line-height: 22px;
  font-size: 17px; font-weight: 500; border-left:4px solid #3d2505 ;
}

.play-rule dd {
  margin:0 10px 5px 10px;
}
.play-rule dd.img {
  margin:0 10px 5px 0px;
}
.play-rule dd img{
  width: 100%;
}
.play-rule dd strong{ color: #3d2505;}
.play-rule .nut-button{ margin-right: 20px; width: auto !important;}
</style>
