/**
 * 注册
 * @param inviteCode
 */
export function eventTrackingRegister(inviteCode, invokeFb) {
    console.log("[eventTrackingRegister] " + inviteCode + "," + invokeFb);

    //套壳App
    shellAppEvent('register', inviteCode);

    //tiktok
    try {
        window.bb88.onEvent("signup", JSON.stringify(
            {
                "user_id": inviteCode,
            }
        ));
        console.log("tiktok reg succeed")
    } catch (e) {
        console.log("tiktok reg failed: ", e)
    }

    //源头上包
    try {
        window.jsBridge.postMessage("register", JSON.stringify(
            {
                "user_id": inviteCode,
            }
        ));
        console.log("yuantou reg succeed")
    } catch (e) {
        console.log("yuantou reg failed: ", e)
    }

    //fb
    if (invokeFb) {
        try {
            window.fbq('track', 'Lead', {content_ids: inviteCode});
            console.log("fb reg succeed")
        } catch (e) {
            console.log("fb reg failed: ", e)
        }
    }
}

/**
 * 登录
 * @param inviteCode
 */
export function eventTrackingLogin(inviteCode, invokeFb) {
    console.log("[eventTrackingLogin] " + inviteCode + "," + invokeFb);

    //tiktok
    try {
        window.bb88.onEvent("login", JSON.stringify(
            {
                "user_id": inviteCode,  //用户唯一标识
            }
        ));
        console.log("tiktok login succeed")
    } catch (e) {
        console.log("tiktok login failed: ", e)
    }

    //fb
    if (invokeFb) {
        try {
            // window.fbq('track', 'Lead', {content_ids: inviteCode});
            console.log("fb login succeed")
        } catch (e) {
            console.log("fb login failed: ", e)
        }
    }
}

/**
 * 付费
 * @param inviteCode
 * @param amount
 */
export function eventTrackingPay(inviteCode, invokeFb, amount, firstTime) {
    console.log("[eventTrackingPay] " + inviteCode + "," + invokeFb + "," + amount + "," + firstTime);

    //套壳App
    shellAppEvent('play', inviteCode);

    //tiktok
    try {
        window.bb88.onEvent("pay", JSON.stringify(
            {
                "user_id": inviteCode,
                "amount": amount
            }
        ));
        console.log("tiktok recharge succeed")
    } catch (e) {
        console.log("tiktok recharge failed: ", e)
    }

    //源头上包
    try {
        if (firstTime) {
            window.jsBridge.postMessage("firstrecharge", JSON.stringify(
                {
                    "user_id": inviteCode,
                    "amount": amount
                }
            ));
        } else {
            window.jsBridge.postMessage("recharge", JSON.stringify(
                {
                    "user_id": inviteCode,
                    "amount": amount
                }
            ));
        }
        console.log("yuantou recharge succeed")
    } catch (e) {
        console.log("yuantou recharge failed: ", e)
    }

    //fb
    if (invokeFb) {
        try {
            window.fbq('track', 'Purchase', {content_ids: inviteCode, currency: 'Rs', value: amount});
            console.log("fb recharge succeed")
        } catch (e) {
            console.log("fb recharge failed: ", e)
        }
    }
}

/**
 * 提现
 * @param inviteCode
 * @param amount
 */
export function eventTrackingWithdraw(inviteCode, invokeFb, amount) {
    console.log("[eventTrackingWithdraw] " + inviteCode + "," + invokeFb + "," + amount);

    //源头上包
    try {
        window.jsBridge.postMessage("withdrawOrderSuccess", JSON.stringify(
            {
                "user_id": inviteCode,
                "amount": amount
            }
        ));
        console.log("yuantou withdraw succeed")
    } catch (e) {
        console.log("yuantou withdraw failed: ", e)
    }

    //fb
    if (invokeFb) {
        try {
            // window.fbq('track', 'Lead', {content_ids: inviteCode});
            console.log("fb withdraw succeed")
        } catch (e) {
            console.log("fb withdraw failed: ", e)
        }
    }
}

function shellAppEvent(ename, uid) {
    //eslint-disable-next-line
    if (window['luck_m'] && window['luck_m']['onEvent_andironsBranch'] && luck_m.onEvent_andironsBranch != null) {
        //eslint-disable-next-line
        luck_m.onEvent_andironsBranch(ename, uid);
    }
}
