<template>
  <div >
    <nut-navbar
      :left-show="false"
      :title="$t('HELP.H1')"
      :fixed=true
      @on-click-icon="iconClick"
      titIcon="horizontal"
      class="mb0 nav-icon-right nut-navbar--fixed">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="pt50 scroll-body">
      <div class="p20" style="padding:10px 5px !important;  background: #f0efef;border-bottom:1px solid #e0ded7;">
          <p style="margin: 0; text-align: center; line-height: 20px; margin: -5px 0 10px 0; color: #f29305;">{{ $t('HELP.TITLE') }}</p>
          <div class="type-title">
            <span :class="listType === 'Recharge' ? 'a' : ''" @click="typeClick('Recharge')">Recharge</span>
            <span :class="listType === 'Withdraw' ? 'a' : ''" @click="typeClick('Withdraw')">Withdraw</span>
            <span :class="listType === 'Promotion' ? 'a' : ''" @click="typeClick('Promotion')">Cooperation</span>
            <span :class="listType === 'Feedback' ? 'a' : ''" @click="typeClick('Feedback')">Experience&Feedback</span>
            <span :class="listType === 'Others' ? 'a' : ''" @click="typeClick('Others')">Others</span>
          </div>
      </div>

      <div class="help-list" v-if="listType === 'Recharge'">
        <nut-divider><strong class="nut-strong">Recharge</strong></nut-divider>


        <nut-collapse v-model:active="n1" :accordion="true" >
          <nut-collapse-item name="name1" title="I have recharged, but System shows processing" >
            <div class="tx">
              <p class="mb30">1 / Please wait for 5minutes-2hour to recharge successfully.</p>
              <p >2 / I have waited more than 2 hour, but it is still not successful.</p>
              <p class="btn" @click="formClick('recharge1')">
                <strong>Please click here</strong> to submit your recharge order amount, 12-digit UTR number, and the transfer screenshot include UTR number and recipient Information
              </p>
            </div>
          </nut-collapse-item>
          <nut-collapse-item name="name2" title="I haven't recharge yet, how do I recharge" >
            <div class="tx">
              <p class="mb30">You can go to the recharge page of System, your funds are 100% safe and secure.</p>
            </div>
          </nut-collapse-item>
          <nut-collapse-item name="name3" title="I tried to recharge, but it shows recharge failed/channel failed" >
            <div class="tx">
              <p class="mb30">Please choose another channel to complete the recharge.</p>
            </div>
          </nut-collapse-item>
        </nut-collapse>

      </div>

      <div class="help-list"  v-else-if="listType === 'Withdraw'">
        <nut-divider><strong class="nut-strong">Withdraw</strong></nut-divider>
        <nut-collapse  v-model:active="n2" :accordion="true" >
          <nut-collapse-item name="name4" title="I am VIP 0.">
            <div class="tx">
              <p class="mb30">Recharge to become VIP1 in order to withdraw, This is our platform rules, this is to keep the user's account safe.</p>
            </div>
          </nut-collapse-item>

          <nut-collapse-item name="name5" title="I am VIP1+ or Agent.">

            <div class="tx mb30">
              <p class="mb30 icon-2" @click="q_withdraw1 = !q_withdraw1">I have already submitted a withdrawal, but it shows that the withdrawal is in processing.</p>
              <div class="tx-tx" v-if="q_withdraw1">
                  <p class="mb30">1 / Bank processing,waiting for 5m-48h withdrawal success.</p>
                  <p class="mb30">2 / I've been waiting for more than 48 hours and I haven't received it yet:</p>
                  <p class="btn" @click="formClick('withdraw1')">
                    <strong>Please click here</strong> to provide screenshot of your withdrawal record and withdrawal order number.
                  </p>
              </div>
            </div>

            <div class="tx mb30">
              <p class="mb30 icon-2" @click="q_withdraw2 = !q_withdraw2">System shows a successful withdrawal, but I didn't receive the money.</p>
              <div class="tx-tx" v-if="q_withdraw2">
                  <p class="mb30">1 / This may be due to a delay by the bank or the internet, please be patient and wait for 1-3 business days. </p>
                  <p class="mb30">2 / I do not receive the money after 3 business days:</p>
                  <p class="btn" @click="formClick('withdraw2')">
                    <strong>Please click here</strong> Provide screenshot of bank PDF statements  from the date the withdrawal order was submitted to the present and Withdrawal order number.
                  </p>
              </div>
            </div>  

            <div class="tx mb30">
              <p class="mb30 icon-2" @click="q_withdraw3 = !q_withdraw3">Withdrawal failed.</p>
              <div class="tx-tx" v-if="q_withdraw3">
                  <p class="mb30">1 / Please make sure your personal bank information is correct, or change to another bank and resubmit.</p>
                  <p class="mb30">2 / I have completed the above checks and resubmitted more than 3 times and failed again:</p>
                  <p class="btn" @click="formClick('withdraw3')">
                    <strong>Please click here</strong> to provide screenshot of your withdrawal record and withdrawal order number..
                  </p>
              </div>
            </div>

            <div class="tx mb30">
              <p class="mb30 icon-2" @click="q_withdraw4 = !q_withdraw4">I can't withdraw now.</p>
              <div class="tx-tx" v-if="q_withdraw4">
                  <p @click="q_withdraw4_1 = !q_withdraw4_1" class="icon-3">1 / The system tells me that you have too few input amount, please keep playing.</p>
                  <p class="mb30 f-r" v-if="q_withdraw4_1">This means that you are triggering the financial regulator's money laundering controls because you are withdraw cash immediately after recharge within a short period of time. So you just need to continue to play the game as normal and continue to try to withdraw cash until you succeed.</p>
                  
                  <p class="mb30 icon-3" @click="q_withdraw4_2 = !q_withdraw4_2">2 / The system shows the VIP description, reminding me to upgrade the VIP level to complete the withdrawal:</p>
                  <p class="mb30 f-r" v-if="q_withdraw4_2">
                    Please upgrade to the corresponding VIP level to complete the withdrawal, different VIP level corresponds to different withdrawal amount. <strong @click="showVipDescription">Click here</strong> to check the VIP description
                  </p>
              </div>
            </div>

          </nut-collapse-item>

          <nut-collapse-item name="name6" title="Bank Card Problem.">
            <div class="tx">
              <p class="mb30">1 / If you encounter problems when binding your bank card or filling out your personal information, 
                please follow the instructions to check if you have filled in the information accurately.
              </p>
              <p >2 / I still get an error after double-checking, </p>
              <p class="btn" @click="formClick('withdraw4')">
                <strong>Please click here</strong> , upload a screenshot of the problem, and fill in your description.
              </p>
            </div>
          </nut-collapse-item>

        </nut-collapse>
      </div>

      <div class="help-list"  v-else-if="listType === 'Promotion'">
        <nut-divider><strong class="nut-strong">Cooperation</strong></nut-divider>
        <div class="tx2 mb30">
          If you are a blogger with a large number of subscribers or work as a promoter, 
          our game is perfect for you. After you promote our game, 
          you can receive very generous commissions within the game. 
          If you would like to contact us to discuss further cooperation after promoting our games.
        </div>
        <div class="tx">
          <p class="btn" @click="formClick('business1')">
              <strong>Please click here</strong> to fill in your channel link or Telegram or WhatsAPP account.
            </p>
        </div>
        
      </div>

      <div class="help-list"  v-else-if="listType === 'Feedback'">
        <nut-divider><strong class="nut-strong">Feedback</strong></nut-divider>
        <div class="tx2 mb30">
          If you are one of our experienced players and have some good suggestions to tell us, 
          or if you have encountered some problems during the game
        </div>
        <div class="tx">
          <p class="btn" @click="formClick('feedback1')">
              <strong>Please click here</strong> upload a screenshot of the problem, and fill in your suggestions.
            </p>
        </div>


        
      </div>

      <div class="help-list"  v-else-if="listType === 'Others'">
        <nut-divider><strong class="nut-strong">Others</strong></nut-divider>
        <div class="tx2 mb30">
          If you are experiencing a problem that does not fall into one of the above categories, 
           <strong></strong> 
        </div>
        <div class="tx">
          <p class="btn">
            please send an e-mail to 
            <br/>
            <strong style="font-size: 16px;">{{ commonInfo.serviceEmail }}</strong>
            <br/>
            (NOTE  This e-mail will only deal with special problems other than those listed above, 
          otherwise it will not be answered).
          </p>
        </div>
        
      </div>

      <div class="note" v-show="formBtn">
        <strong>Note:</strong> You must submit your question in the corresponding option in order to be viewed and resolved by our relevant customer service staff, otherwise your question will not be processed and will not be answered.
      </div>

      <nut-button block type="info" v-show="formBtn" @click="iconClick" style="margin: 0 2%; width: 96% !important;">
          <span>Help History</span>
      </nut-button>
      <div class="note2" v-if="listType !== 'Others'">
        If you have already submitted a help request, please click here to check the process
      </div>
      

    </div>
    
  </div>

  <!--**************************************** form ****************************************-->
  <nut-popup pop-class="popclass pop-cpf" closeable round :close-on-click-overlay=false @close="closeForm"
      :style="{ padding: '20px',width:'352px', height:'auto' }" v-model:visible="formPopup" :z-index="100">
      <div class="formbox">
        <h3></h3>
        <nut-form>

          <nut-form-item label="Recharge order amount" v-if="formType === 'recharge1'">
            <nut-input v-model="formData.recharge_order_amount" placeholder="please input" type="text" />
          </nut-form-item>

          <nut-form-item label="12-digit UTR number" v-if="formType === 'recharge1'">
            <nut-input v-model="formData.UTR_number" placeholder="please input" type="text" />
          </nut-form-item>

          <nut-form-item label="Withdrawal order number" v-if="formType === 'withdraw1' || formType === 'withdraw2' || formType === 'withdraw3'">
            <nut-input v-model="formData.withdrawal_order_number" placeholder="please input" type="text" />
          </nut-form-item>

          <nut-form-item label="Description" v-if="formType === 'withdraw4' || formType === 'feedback1'">
            <nut-input v-model="formData.description" placeholder="please input" type="text" />
          </nut-form-item>

          <nut-form-item label="Screenshot" 
            v-if="formType === 'recharge1' || formType === 'withdraw1' || formType === 'withdraw2' || formType === 'withdraw3' || formType === 'withdraw4' || formType === 'feedback1'">
            
            <div class="file-main">

                <div class="file-box" @click="callFile('#file1')">
                  <!-- <img :src="uploadImgUrl" > -->
                  <img :src="uploadImgBase64_1" v-if="uploadImgBase64_1" >
                  <!-- <div class="v" v-if="uploadImgBase64" @click="checkImg($event)"></div> -->
                </div>
                <input type="file" id="file1" accept="image/*" @change="getPicture($event, 0)" style="display: none;" />


                <div class="file-box" @click="callFile('#file2')" v-if="uploadImgBase64_1">
                  <!-- <img :src="uploadImgUrl" > -->
                  <img :src="uploadImgBase64_2" v-if="uploadImgBase64_2" >
                  <!-- <div class="v" v-if="uploadImgBase64" @click="checkImg($event)"></div> -->
                </div>
                <input type="file" id="file2" accept="image/*" @change="getPicture($event, 1)" style="display: none;" />


                <div class="file-box" @click="callFile('#file3')" v-if="uploadImgBase64_2">
                  <!-- <img :src="uploadImgUrl" > -->
                  <img :src="uploadImgBase64_3" v-if="uploadImgBase64_3" >
                  <!-- <div class="v" v-if="uploadImgBase64" @click="checkImg($event)"></div> -->
                </div>
                <input type="file" id="file3" accept="image/*" @change="getPicture($event, 2)" style="display: none;" />

                <div class="lz"  v-if="formType === 'recharge1'" >
                    <img src="../assets/images/bg500.jpg" @click="showZoom = !showZoom" />
                </div>


            </div>


          </nut-form-item>

          <p class="form-note" v-if="formType === 'recharge1'">Note: Must include UTR number and recipient Information.</p>
          <p class="form-note" v-if="formType === 'withdraw1' || formType === 'withdraw3'">Screenshot of withdrawal record.</p>
          <p class="form-note" v-if="formType === 'withdraw2'">Screenshot of bank PDF statements from the date the withdrawal order was submitted to the present.</p>
          <p class="form-note" v-if="formType === 'withdraw4' || formType === 'feedback1'">Screenshot of your problem.</p>

          <nut-form-item label="Telegram Account" v-if="formType === 'business1'">
            <nut-input v-model="formData.telegram_account" placeholder="please input" type="text" />
          </nut-form-item>

          <nut-form-item label="Whatsapp Accout" v-if="formType === 'business1'">
            <nut-input v-model="formData.whatsapp_account" placeholder="please input" type="text" />
          </nut-form-item>

          <nut-form-item label="Your Promote Channel Link" v-if="formType === 'business1'">
            <nut-input v-model="formData.url" placeholder="please input" type="text" />
            <img style="height: 40px;" src="../assets/images/bg401.jpg">
          </nut-form-item>
          
        </nut-form>
      </div>
      <nut-button block type="info" v-show="formBtn" :disabled="btnDisabled" @click="submitClick" style="margin: 0 2% 10px 2%; width: 96% !important;">
          <span>Submit</span>
      </nut-button>
  </nut-popup>

  <nut-popup pop-class="pop-vip" v-model:visible="vipPopup" :z-index="100">
      <VipRules  popTitle="VIP" upgradeVip="show"></VipRules>
      <div class="login-btn m10">
        <nut-button block type="info" @click="vipPopup = false" >{{$t('global.Got_It')}}</nut-button>
      </div>
    </nut-popup>

    <div class="lz-zoom" v-if="showZoom"  @click="showZoom = !showZoom" >
        <img src="../assets/images/bg500-zoom.jpg" />
    </div>
    
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import { useRouter } from "vue-router";
import { Toast } from '@nutui/nutui';
import { getCountry } from "@/assets/js/environment";
import {isNotBlank, isBlank} from "@/assets/js/commonUtil";
import axios from "axios";
import VipRules from "../components/VipRules2.vue";


export default {
  components: {
    VipRules: VipRules,
  },
  data() {
    return {
      i18n:{
        popTitle_1: this.$t('recharge.popTitle_1'),
        popTitle_2: this.$t('recharge.popTitle_2'),
      }
    };
  },
  methods: {
  },
  created() {
  },
  setup() {

    const _data = getCurrentInstance();
    const state = reactive({
      n1: [],
      n2: [],
      country: '',
      listType: '',
      formType: '',
      formBtn: true,
      formPopup: false,
      formData:{
        recharge_order_amount: '',
        UTR_number: '',
        description: '',
        telegram_account: '',
        whatsapp_account: '',
        url: '',
      },
      vipPopup:false,
      popTitle:'',
      content: '',
      imgs: {
        img1: null,
        img2: null,
        img3: null,
      },
      btnDisabled: false,
      uploadComplete: false,
      showPreview: false,  //图片预览
      uploadImgUrl_1: '',
      uploadImgUrl_2: '',
      uploadImgUrl_3: '',
      uploadImgBase64_1: '',
      uploadImgBase64_2: '',
      uploadImgBase64_3: '',
      SystemTicket2TypeEnum:{
        recharge:'recharge',
        withdraw:'withdraw',
        invite:'invite',
        others:'others',
      },
      q_withdraw1: false,
      q_withdraw2: false,
      q_withdraw3: false,
      q_withdraw4: false,
      q_withdraw4_1: false,
      q_withdraw4_2: false,
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      showZoom: false,
    });
    const router = useRouter();
    const methods = {
      
      backClick() {
        router.go(-1);
      },

      iconClick() {
        router.push({
          name: "complaintsSuggestions2",
        });
      },
      showVipDescription(){
        state.vipPopup = true; 
        state.popTitle = _data.data.i18n.popTitle_1
      },
      typeClick(t){
        switch (t) {
          case 'Recharge':
            state.listType = "Recharge"
            state.formBtn = true
            break;
          case 'Withdraw':
            state.listType = "Withdraw"
            state.formBtn = true
            break;
          case 'Promotion':
            state.listType = "Promotion"
            state.formBtn = true
            break;
          case 'Feedback':
            state.listType = "Feedback"
            state.formBtn = true
            break;
          case 'Others':
            state.listType = "Others"
            state.formBtn = false
            break;
          default:
            break;
        }
      },

      formClick(t){
        state.formPopup = true
        state.formType = t
      },

      closeForm(){
        state.formData = {
          recharge_order_amount: '',
          UTR_number: '',
          description: '',
          telegram_account: '',
          whatsapp_account: '',
          url: '',
        }
        state.imgs = {
          img1: null,
          img2: null,
          img3: null,
        }
        //state.fromImg = []
      },
      textToast(msg) {
        Toast.loading(msg, {
          id:"loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover:true,
        });
      },

      // submitClick(){
      //   state.content = ''
      //   for (const key in state.formData) {
      //     if(isNotBlank(state.formData[key])){
      //       state.content += key + ' : ' + state.formData[key] + ';'
      //     }
      //   }
      //   axios
      //     .post("/api/system/ticket2/create", {
      //       type: state.formType,
      //       content: state.content,
      //       imgs: state.fromImg
      //     })
      //     .then((response) => {
      //       if (response.data.code === 0) {
      //         Toast.success("success!");
      //         setTimeout(() => {
      //           router.push("/complaintsSuggestions2");
      //         }, 1000);
      //       } else {
      //         if (response.data.msg != undefined) {
      //           Toast.fail(response.data.msg);
      //         }
      //       }
      //     })
      //     .catch(function (error) {
      //       console.log(error);
      //       Toast.fail(this.$t('global.networkError'));
      //     });
      // },

    getPicture(e, n){
      var reader = new FileReader();
      switch (n) {
          case 0:
            //state.uploadImgUrl_1 = window.URL.createObjectURL(e.target.files[n]);
            reader.onload = (e) => {
              state.uploadImgBase64_1 = e.target.result
            }
            state.imgs.img1 = e.target.files[0]
            reader.readAsDataURL(e.target.files[0])
            break;
          case 1:
            //state.uploadImgUrl_2 = window.URL.createObjectURL(e.target.files[n]);
            reader.onload = (e) => {
              state.uploadImgBase64_2 = e.target.result
            }
            state.imgs.img2 = e.target.files[0]
            reader.readAsDataURL(e.target.files[0])
            break;
          case 2:
            //state.uploadImgUrl_3 = window.URL.createObjectURL(e.target.files[n]);
            reader.onload = (e) => {
              state.uploadImgBase64_3 = e.target.result
            }
            state.imgs.img3 = e.target.files[0]
            reader.readAsDataURL(e.target.files[0])
            break;
          default:
            break;
        }
    },
    callFile(f){
        let fileDom = document.querySelector(f)
        fileDom.click()
    },
    checkImg(e){
      this.showPreview = true;
      e.stopPropagation();
      e.preventDefault()
    },
      //上传图片
      submitClick(){
        state.btnDisabled = true
        state.content = ''
        
        
        if(
          state.formType === 'recharge1' || 
          state.formType === 'withdraw1' || 
          state.formType === 'withdraw2' || 
          state.formType === 'withdraw3' || 
          state.formType === 'withdraw4' || 
          state.formType === 'feedback1'
          ){
            if(isBlank(state.imgs.img1) && isBlank(state.imgs.img2) && isBlank(state.imgs.img3)){
              Toast.fail('Upload at least one picture!');
              state.btnDisabled = false
              return
            }
        }
        for (const key in state.formData) {
          let title = ''
          if(isNotBlank(state.formData[key])){
            switch (key) {
              case 'withdrawal_order_number':
                title = 'Withdrawal order number'
                break;
              case 'recharge_order_amount':
                title = 'Recharge amount'
                break;
              case 'UTR_number':
                title = 'UTR number'
                break;
              case 'description':
                title = 'Description'
                break;
              case 'telegram_account':
                title = 'Telegram account'
                break;
              case 'whatsapp_account':
                title = 'WhatsApp account'
                break;
              case 'url':
                title = 'URL'
                break;
              default:
                title = key
                break;
            }
            state.content += title + ' : ' + state.formData[key] + '; '
          }
        }
        let _formData = new FormData();
        let _uploadImgForm = {
          content: state.content,
          type: state.formType,
        }
        for( var key in _uploadImgForm){
          _formData.append(key, _uploadImgForm[key])
        }
        if(isNotBlank(state.imgs.img1)){
          _formData.append('imgs',state.imgs.img1)
        }
        if(isNotBlank(state.imgs.img2)){
          _formData.append('imgs',state.imgs.img2)
        }
        if(isNotBlank(state.imgs.img3)){
          _formData.append('imgs',state.imgs.img3)
        }
        
        if(state.listType === "Promotion" &&  isBlank(state.formData.url)){
          Toast.fail('Please input Your Promote Channel Link');
          state.btnDisabled = false
          return
        }
        axios({
          method: "post",
          url: "/api/system/ticket2/create",
          headers:{
            "Content-Type": "multipart/form-data"
          },
          withCredentials:true,
          data: _formData
        }).then((res) => {
          if (res.data.code === 0) {
              Toast.success("success!");
              setTimeout(() => {
                router.push("/complaintsSuggestions2");
              }, 1000);
            } else if (res.data.code === 1004) {
              let _res = res.data.data
              for(var i in _res){	
                switch (_res[i].fieldName) {
                  case "content":
                  Toast.fail(_res[i].fieldName + ' ' + _res[i].description);
                  break;
                }
              }
            }
          state.btnDisabled = false
        }).catch(function (error) {
          console.log(error);
          state.btnDisabled = false
        });
        
      },


    };
    onMounted(() => {
      state.country = getCountry();
      if(state.country !== 'in'){
        router.push({
          name: "myHome",
        });
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

<style scoped>

.type-title{ padding: 0; text-align: center; display: flex;  justify-content: space-between; flex-wrap: wrap; }
.type-title span{ margin: 0 0 10px 0; line-height: 40px; height: 40px; width: 32%; background: #fff;
   border: 1px solid #3d2505; color: #3d2505; border-radius:5px; padding:0; display: inline-block;}
.type-title span:nth-child(4){ width: 49%;}
.type-title span:nth-child(5){ width: 49%;}
.type-title span.a{color: #fff; background: #3d2505;}
.note{ padding: 20px; color:#888683; background: #fffcf2; margin: 20px 10px; border-radius: 5px;border:1px solid #e0ded7;}

.note2{ padding: 10px 20px 30px 20px; color:#888683; margin: 0px 10px; }
.nut-strong{ font-size: 16px; color: #3d2505;}

.help-list .tx{ padding: 0 10px;}
.help-list .mb30{ margin-bottom: 30px;}
.help-list .tx p{ color: #000; margin: 10px 0; font-size: 15px;}
.help-list .tx p.btn{ border: 1px solid #f9cfae; border-radius: 5px; text-align: center; 
  padding: 10px; margin-top: 0px; background: #fffcf2; color: #ff7200;font-size: 12px;}
.help-list .tx p.btn strong{font-size: 14px;}
.help-list .tx2{ padding: 0 20px;}
.help-list .tx-tx{ border: 1px solid #eee; background: #fff; border-radius: 5px; padding: 10px;}
.help-list .tx-tx p{ color: #3d2505;}
.help-list .tx-tx p.f-r{ padding: 10px; color: #ff7200; border-radius: 8px; border:1px solid #e0ded7;border-top:3px solid #ff7200; }

.formbox .form-note{background: #fffcf2; color: #ff7200; border-radius: 5px;border:1px solid #e0ded7; padding: 5px 10px; }

.icon-2{background: url(../assets/images/bg301b.png) 0px 3px no-repeat; background-size: 22px auto; 
  padding: 0 10px 10px 30px;min-height: 30px; border-bottom:1px dashed #ccc; margin-top: 0;}
  .icon-3{background: url(../assets/images/bg301b.png) 15px 10px no-repeat; background-size: 16px auto; 
  padding: 0 10px 10px 45px;min-height: 30px; border-bottom:1px dashed #ccc; margin-top: 0;}

.file-main{ display: flex;}
.file-box{ margin-right: 10px;}

.lz img{ width: 52px; height: auto;}
.lz-zoom{ position: absolute; left: 10px; top: 50px; right: 10px; z-index: 400;}
.lz-zoom img{ width: 100%; height: auto;}
</style>

<style>
.help-list .nut-collapse-item .collapse-item{ color: #3d2505; font-weight: 500; border-bottom: 1px dashed #e9e9e9;
  background: url(../assets/images/bg183.png) 5px 13px no-repeat; background-size: 20px auto;}
.help-list .nut-collapse-item .collapse-icon-title{ padding-left: 6px;}

.nav-icon-right .nut-navbar__title .nut-icon {position: absolute !important;right: 10px !important;}

.help-list .nut-collapse-item .collapse-wrapper .collapse-content{ background: #f0efe6; padding-left: 5px; }

.formbox .nut-form-item__label{ width: 100%;}
.formbox .nut-cell-group__warp .nut-cell{ display: block; border-bottom: 0;}
.formbox .nut-input, .formbox .nut-form-item__body__slots .nut-textarea{padding: 10px 0px !important;}
.formbox .nut-textarea__textarea{ height: 100px; border:1px solid #e0ded7;border-radius: 5px; padding: 10px;}
.formbox .nut-cell-group .nut-cell:after{ border-bottom: 0;}
.formbox .nut-form-item__body{ margin-top: 5px;}
</style>