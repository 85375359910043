
<template enter-active-class='animate__animated animate__fadeInDown' leave-active-class='animate__animated animate__fadeOutUp' :duration="1000">
  <div class="login userLogin">
    <div class="login-bn">
      <h2>&nbsp;</h2>
    </div>
    <div class="login-bd">
      <h3>{{ $t('userLogin.smsSignIn') }}</h3>
      <SetLanguage></SetLanguage>

      <div class="login-inp">
        <p class="fs10 fc-999">{{ $t('userLogin.mobileNumber')}}</p>
        <p class="err-info" v-show="errText.mobile">{{errText.mobile}}</p>
        <p class="err-info" v-show="state.mobileErrText">{{state.mobileErrText}}</p>
        <div v-if="country === 'in'" class="nation">+91</div>
        <div v-if="country === 'ph'" class="nation">+63</div>
        <div v-if="country === 'br'" class="nation">+55</div>
        <div v-if="country === 'vn'" class="nation">+84</div>
        <login-input
          label=""
          :placeholder="$t('userLogin.mobileNumberPlaceholder')"
          type="number"
          rule="^.{1,18}$"
          class="pl40"
          @inputChange="(res) => (mobile = res)"
        />
      </div>
      <div class="login-inp">
        <p class="fs10 fc-999">{{ $t('userRegister.verificationCode')}}</p>
        <p class="err-info" v-show="errText.authCode">{{errText.authCode}}</p>
        <div class="nut-input-inner w100">
          <login-input
            label=""
            :placeholder="$t('userRegister.verificationCodePlaceholder')"
            type="number"
            rule="^\d{6}$"
            maxLength="6"
            @inputChange="(res) => (authCode = res)"
          />
          <nut-button
            type="info"
            size="mini"
            v-show="state.sendAuthCode"
            @click="getCode(mobile)"
            >{{ $t('userRegister.OTP') }}</nut-button
          >
          
          <nut-button
            type="info"
            size="mini"
            v-show="!state.sendAuthCode"
            :disabled="true"
            >{{ state.second }} s</nut-button
          >
        </div>

        <div v-if="country === 'vn'" class="optzalo">Người dùng VT vui lòng sử dụng zalo để nhận otp</div>
      </div>

      <div class="login-btn">
        <nut-button block type="info" @click="loginSubmit">{{ $t('userLogin.login') }}</nut-button>
      </div>
      <nut-row>
        <nut-col :span="12">
          <div class="flex-content">
            <span class="a-lk" @click="registerClick">{{ $t('userLogin.register')}}</span>
          </div>
        </nut-col>
        <nut-col :span="12">
          <div class="flex-content ta-right">
            <span class="a-lk" @click="passwordLoginClick">{{ $t('userLogin.passwordLogin')}}</span>
          </div>
        </nut-col>
      </nut-row>
    </div>

    <div class="tg-btn" v-if="country === 'in' && template === 's1'" @click="toTelegram">
        <img src="../assets/images/bg312.png" />
    </div>

    <div class="tg-btn" v-else-if="country === 'br' && template === 's1'" @click="toTelegram">
        <img src="../assets/images/bg313.png"  />
    </div>

    <div class="login-tx">
      {{ $t('global.safeDays1')}} <strong>{{ state.runningDays }}</strong> {{ $t('global.safeDays2')}}
    </div>
  </div>
  <img style="width:96%;margin-left: 3%;" :src="require('../assets/images/' + imgUrl + 'bg145.jpg')"/>
</template>

<script>
import { reactive, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
import axios from "axios";
import loginInput from "@/components/loginInput.vue";
import SetLanguage from "@/components/SetLanguage.vue";
import { isBlank, delSpace } from "@/assets/js/commonUtil";
import { getLastTimeGameName, getCountry } from "@/assets/js/environment";
import {eventTrackingLogin} from "@/assets/js/eventTracking";
export default {
  components: {
    loginInput: loginInput,
    SetLanguage: SetLanguage
  },
  data() {
    return {
      country: '',
      template: '',
      imgUrl: '',
      mobile: "",
      authCode: "",
      inviteCode: null,
      shareCode: null,
      shareBonusReceiveCode: "",
      amount: "",
      errText:{
        mobile: "",
        authCode: ""
      },
      i18n:{
        successfully: this.$t('global.successfully'),
        errorfully: this.$t('global.errorfully'),
        mobileError: this.$t('global.INVALID_INPUT_VALUE'),
      }
    };
  },
  created() {
    this.country = getCountry()
    this.template = document.querySelector('body').getAttribute('s')
    this.imgUrl = localStorage.getItem('countryImgUrl')
    if(!isBlank(localStorage.getItem("invite"))) {
      this.inviteCode = localStorage.getItem("invite")
    }
    if(!isBlank(localStorage.getItem("shareBonusCode"))) {
      this.shareCode = localStorage.getItem("shareBonusCode")
    }
  },
  
  methods: {

    //async registerSubmit() {
    loginSubmit() {
      this.errText.mobile = ""
      this.errText.authCode = ""
      if(this.mobile === ""){
        this.errText.mobile = this.$t('global.errInfoRequired')
        return
      }
      if(this.authCode === ""){
        this.errText.authCode = this.$t('global.errInfoRequired')
        return
      }
      Toast.loading(this.$t('global.loading'), {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
      });
      this.mobile = delSpace(this.mobile)
      this.authCode = delSpace(this.authCode)
      axios
        .post("/api/user/login/auth-code", {
          mobile: this.mobile,
          authCode: this.authCode,
          recallInviteCode: this.inviteCode,
          recallShareBonusCode: this.shareCode
        })
        .then((response) => {
          if (response.data.code === 0) {
            //埋点
            eventTrackingLogin(response.data.data.inviteCode, response.data.data.invokeFb);

            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("hasPassword", response.data.data.hasPassword);
            localStorage.removeItem("invite");
            if (!isBlank(this.shareCode)) {
              if(parseInt(localStorage.getItem("needVip")) > 0){
                //this.$router.push("receiveBonus?c=" + this.shareCode);
                window.location.href = "receiveBonus?c=" + this.shareCode
              }else{
                axios
                .post("/api/user/share-bonus/receive", {
                  shareBonusCode: this.shareCode,
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    this.shareBonusReceiveCode =res.data.data.shareBonusReceiveCode;
                    this.amount = res.data.data.shareBonusAmount;
                    localStorage.setItem("shareBonusReceiveCode",this.shareBonusReceiveCode);
                    localStorage.setItem("shareBonusAmount", this.amount);
                    localStorage.removeItem("shareBonusCode");
                    setTimeout(() => {
                      this.$router.push({
                        path: "/shareBonus",
                        query: {
                          amount: this.amount,
                        },
                      });
                    }, 1000);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
              }
            } else {
              setTimeout(() => {
                this.$router.push({ path: '/'+ getLastTimeGameName() });
              }, 1000);
            }

            Toast.hide("loading");
            Toast.success(this.$t('userLogin.loginSuccess'));
          }else if (response.data.code === 1004){
            Toast.hide("loading");
            let res = response.data.data
            for(var i in res){	
              switch (res[i].fieldName) {
                case "mobile":
                  this.errText.mobile = res[i].description
                  break;
                case "password":
                  this.errText.authCode = res[i].description
                  break;
              }
            }
          }else{
            Toast.hide("loading");
          }
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        });
    },
  },

  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      runningDays: "",
      sendAuthCode: true /* 通过v-show控制显示‘获取按钮’还是‘倒计时’ */,
      second: 60 /* 一分钟 倒计时*/,
      timer: null /* 倒计时 计数器,防止点击的时候触发多个setInterval*/,
      mobileErrText: '',
      country: '',
      commonInfo: ''
    });

    const getCode = (m) => {
      let mobile = m;
      state.mobileErrText = ''
      if (mobile != "") {
        axios
          .post("/api/system/sms/send", {
            mobile: mobile,
            type: "login_code",
          })
          .then(function (response) {
            if (response.data.code === 0) {
              //Toast.success(_data.data.i18n.successfully);
              Toast.success(_data.data.i18n.successfully, {
                duration: 4000
              });
              if (state.sendAuthCode) {
                state.sendAuthCode = false;
                let interval = window.setInterval(function () {
                  setStorage(state.second);
                  if (state.second-- <= 0) {
                    //如果 倒计时完毕 重新赋值
                    state.second = 60;
                    state.sendAuthCode = true;
                    window.clearInterval(interval);
                  }
                }, 1000);
              }
            } else if (response.data.code === 1004){
              let res = response.data.data
              Toast.fail(_data.data.i18n.mobileError);
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "mobile":
                    state.mobileErrText = res[i].description
                    break;
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        state.mobileErrText = _data.data.i18n.mobileError
      }
    };
    /**
     * 存储 验证码 防止刷新
     * `用处`: 防止页面刷新 发送验证码状态改变
     * */
    const setStorage = (parm) => {
      localStorage.setItem("dalay", parm);
      localStorage.setItem("_time", new Date().getTime());
    };
    /**
     * 获取 缓存 数据
     * `用处`: 防止页面刷新 发送验证码状态改变
     * */
    const getStorage = () => {
      let localDelay = {};
      localDelay.delay = localStorage.getItem("dalay");
      localDelay.sec = localStorage.getItem("_time");
      return localDelay;
    };
    /**
     *  判断
     *  */
    const judgeCode = () => {
      // 获取缓存中的数据
      let localDelay = getStorage();
      let secTime = parseInt((new Date().getTime() - localDelay.sec) / 1000);
      if (secTime > localDelay.delay) {
        state.sendAuthCode = true;
      } else {
        state.sendAuthCode = false;
        let _delay = localDelay.delay - secTime;
        state.second = _delay;
        state.timer = setInterval(() => {
          if (_delay > 1) {
            _delay--;
            setStorage(_delay);
            state.second = _delay;
            state.sendAuthCode = false;
          } else {
            // 让浏览器打开的时候,显示剩余的时间
            state.sendAuthCode = true;
            window.clearInterval(state.timer);
          }
        }, 1000);
      }
    };
    judgeCode();
    const router = useRouter();
    const passwordLoginClick = () => {
      router.push({
        name: "userLogin",
      });
    };
    const registerClick = () => {
      router.push({
        name: "userRegister",
      });
    };
    const methods = {
      toTelegram(){
        window.open(state.commonInfo.telegramChannelUrl,"_self");

      },
    };
    onMounted(() => {
      state.country = getCountry();
      if(window.localStorage.getItem('commonInfo')){
        state.commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
        state.runningDays = state.commonInfo.runningDays
      }else{
        axios.get("/api/system/common", {}).then((response)=> {
          state.commonInfo = response.data.data
          state.runningDays = response.data.data.runningDays
        })
      }


    });
    return {
      state,
      ...methods,
      getCode,
      passwordLoginClick,
      registerClick,
    };
  },
};
</script>

<style scoped>
.login-inp {
  position: relative;
}
.login-inp .pl40 {
  margin-left: 66px;
  width: auto !important;
}

.login-bd {
  padding: 20px;
  background: #fff;
  margin: 20px;
  box-shadow: 0px 0px 5px #d9d2f4;
  border-radius: 10px;
}
.login-bd h3 {
  margin: 10px 0 30px 0;
  font-size: 20px;
  color: #333;
  font-weight: 400;
  text-align: center;
}
.login-bd .nut-input {
  padding: 10px 0 10px 0px;
}
.login-bd .nut-input .input-text {
  padding: 0 35px 0 0px;
}
.a-lk {
  font-size: 12px;
  color: #994509;
  display: block;
  line-height: 14px;
}

.login-btn {
  margin: 30px 0 10px 0;
}
.login-tx {
  padding: 20px 0;
  color: #809c54;
  text-align: center;
  background: #f1f7e8;
}
.login-tx strong {
  font-size: 20px;
  border: 1px solid #bac6a7;
  box-shadow: 0px 0px 3px #d9d2f4;
  margin-right: 2px;
  padding: 2px 4px;
  border-radius: 4px;
  background: url(../assets/images/bg06.png) center center no-repeat;
}
.login-bn {
  padding: 88px 20px 20px 20px;
}
.login-bn h2 {
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: center;
  color: #efe7f4;
}
</style>
