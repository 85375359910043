// EN
module.exports = {
  // 枚举
  filtersText:{
    wait_audit: 'Wait Audit',
    audit_accept: 'Audit Accept',
    audit_reject: 'Audit Reject',
    processing: 'Processing',
    success: 'Success',
    failed: 'Failed',
  },
  // 通用
  global:{
    language: 'ngôn ngữ',
    recharge: 'Nạp tiền', //充值
    invite: 'mời',        //邀请
    reward: 'Nhiệm vụ',   //任务
    my: 'của tôi',        //我的
    myWin: 'chiến thắng',
    loading: "Đang tải...",                                   //加载中
    okText: "OK",                                             //ok按钮文字 1
    cancelText: "Hủy bỏ",                                     //取消按钮文字 1 
    safeDays1: 'Hoạt động an toàn ',                          //安全运营天数： safeDays1 +  ??? + safeDays2
    safeDays2: 'ngày an toàn',
    dataListInfo :'Chỉ hiển thị dữ liệu trong vòng 3 tháng!', //只展示3个月数据  2  Only show the Last 3 Month Data
    noMore:'đã hết!',                                         //没有更多数据了
    noData:'không có dữ liệu!',                               //没有更多数据了
    inputRequired:'Vui lòng điền vào phần được yêu cầu!',     //请填写必填项
    errInfoRequired:'Phần bắt buộc',                          //必填项错误提示
    mobileError: 'Định dạng sai số điện thoại!',              //电话号码错误提示
    passwordsError: 'hai mật khẩu khác nhau!',                //两次密码不一致
    agreeError: 'Vui lòng đồng ý với thỏa thuận đăng ký',     //请同意注册协议
    successfully_OPT: 'Mã xác minh giọng nói đã được gửi',   //短信验证码发送成功
    successfully: 'thành công',   //成功
    errorfully: 'Số điện thoại sai!',                         //短信验证码发送失败
    networkError:'mạng bất thường!',                          //网络错误
    eMail:'thư phản hồi: ',                                   //提现底部提示1
    WithdrawalPartners:'Thanh toán/rút tiền cho đối tác sau.',  //提现底部提示2
    submittedSuccess:'Đã gửi!',                               //提交成功
    confirm:'xác nhận',                                       //确定按钮
    submit:'nộp',                                             //提交按钮
    save:'Lưu',                                               //保存按钮
    view:'Kiểm tra',                                          //查看按钮
    downloadApp: 'tải ứng dụng',                              //下载app
    addDesktop: 'Lưu vào Desktop',                            //添加到桌面
    addDesktopTitle: 'Để tiện cho bạn, đề nghị lưu thêm ứng dụng của chúng tôi trên desktop',  //为了你更方便的使用，建议把我们添加到桌面
    addDesktopText1a: '1、click vào',               //1、点击 xx 图标   (第一步)
    addDesktopText1b: 'biểu tượng',                  //1、点击 xx 图标
    addDesktopText2: '2、Chọn "lưu vào desktop"" ',   //  (第二步)选择 "添加到主屏幕"
    rechargeBtnText:'Nạp tiền',  //充值按钮
    withdrawalBtnText:'Rút tiền',  //提现按钮
    inviteBtnText:'mời',  //邀请按钮
    slogan1: '100% an toàn và trung thực, không gian lận!',
    Today: 'Hôm nay', 
    Week: '1 Tuần', 
    Month1: '1 tháng',
    Month3: '3 tháng', 
    Your_invitation_link_has_been_copied: 'Liên kết lời mời của bạn đã được sao chép!',
    hotGame: 'Trò chơi hấp dẫn',
    newGame: 'Trò chơi mới',
    top10: 'Top 10 người chơi',
    NON_VIP_CAN_ONLY_INPUT: 'Không vip chỉ có thể đặt cược',
    INVALID_INPUT_VALUE: 'giá trị đầu vào không hợp lệ.',
    REQUEST_FAILED_PLEASE_TRY_LATER_OR_CONTACT_US: 'Yêu cầu không thành công. Vui lòng thử lại sau hoặc liên hệ với chúng tôi.',
    SERVER_ERROR: 'Lỗi máy chủ. Vui lòng thử lại sau hoặc liên hệ với chúng tôi.', 
    SIGN_UP_BONUS_1: 'Đăng ký để nhận ', //Sign Up To Get ** Bonus >>
    SIGN_UP_BONUS_2: ' tiền thưởng >>',
    SIGN_UP_BONUS_3: 'Đăng ký nhận coupon giảm giá ',
    ALREADY_HAVE_AN_ACCOUNT: 'Bạn đã có tài khoản?',
    RECEBER_CUPOM_DE_DESCONTO_DE: 'nhận phiếu ưu đãi ',
    OBTER_CUPOM: 'đã nhận phiếu ưu đãi',
    DISCOUNT_COUPON: 'phiếu ưu đãi',
    VALID: 'có hiệu quả',
    COUPON: 'phiếu ưu đãi',
    EXPIRED: 'quá hạn',
    USAR: 'sử dụng',
    FUN_MODE: 'chế độ giải trí',
    RECIBO: 'đã vào tài khoản',
    PAY: 'thanh toán',
    FUN_MODE_i: 'Số dư tài khoản trong chế độ chơi thử là ảo, hãy chọn chế độ thực chiến để bắt đầu trải nghiệm trò chơi thực sự',
    VIP_BONUS_1: ' Chỉ người dùng VIP ',
    VIP_BONUS_2: '+ mới có thể nhận được tiền thưởng này, vui lòng nâng cấp cấp bậc VIP của bạn để được nhận.',
    DEMO_END_TEXT: 'Bạn đã hết cơ hội thử sức hôm nay, hãy chuyển sang chế độ thực chiến để bắt đầu chiến thắng thực sự.',
    TO_REAL_MODE: 'Chuyển sang chế độ thực chiến',  //前往实战模式
    Prediction_Channel: 'kênh dự đoán',  //预测频道
    Prediction_Channel_Text: 'Chỉ người dùng VIP1+ mới có thể vào kênh dự đoán, vui lòng nâng cấp VIP của bạn.',//只有VIP１＋用户可以进入预测频道，请提升你的VIP等级
    Got_It: 'tôi hiểu rồi',   //我知道了
    Upgrade: 'nâng cấp',   //升级
    Recharge_Dialog_Title: 'số dư không đủ',  //余额不足
    Recharge_Dialog_Text: 'Bạn có thể nạp tiền hoặc vào chế độ chơi thử để trải nghiệm game', //你可以充值或者进入试玩模式来体验游戏
    Recharge_Dialog_Btn: 'Chơi', //玩
    Received:'nhận được',  //领取
    Total:'tổng cộng',   //总计
    Lucky_Lifafa:'tiền thưởng may mắn', 
    Easy_share_Earn_Rupees:'Dễ dàng chia sẻ và kiếm tiền', //轻松分享，赚钱
    Remaining_today: 'hôm nay còn lại',  //今天剩余
    pieces:'phần', //份
    Play_now:'chơi ngay bây giờ',   //现在去玩

    DOWNLOAD_APP: 'Tải xuống　APP',
    DOWNLOAD_APP_TEXT:'Để có trải nghiệm trò chơi tốt hơn, nhận phần thưởng nhanh hơn và nhận thông tin có giá trị',
    DOWNLOAD_APP_TEXT2: 'Tải xuống ứng dụng ngay bây giờ để nhận tiền thưởng ',
    DOWNLOAD_APP_TEXT3: '!',
    DOWNLOAD_APP_TEXT4: 'Vui lòng truy cập APP để nhận phần thưởng!',
    DOWNLOAD_APP_BTN: 'Tải xuống',
    DOWNLOAD_APP_CANCEL: 'Hủy bỏ',

    Through_Email1: 'Nếu có bất kỳ vấn đề gì, vui lòng liên hệ với chúng tôi qua E-mail: wingo100x',
    Through_Email2: 'gmail.com, giờ làm việc: 7:00-20:00',

    downloadPopup_h3: 'New update available',
    downloadPopup_p: "Please download the latest app from Google Play.",
    downloadPopup_later: 'Update later'

  },
  // 飞机游戏页
  game:{
    h1: 'Crash',
    tips: 'gợi ý', //提示
    gamePaused: 'Tạm dừng trò chơi', //游戏暂停
    paused: 'Tạm dừng',   //暂停
    playTime: 'thời gian chơi', //播放时间
    gameWillStartingIn: 'Trò chơi sẽ bắt đầu',
    youWin: 'bạn đã thắng',
    receiveBonus: 'nhận tiền thưởng',
    nextGame: 'Ván tiếp theo',  
    all: 'toàn bộ',
    start: 'bắt đầu',
    stop: 'dừng lại',
    autoStop: 'tự động dừng',  
    myBet: 'Đơn hàng của tôi',
    players: 'người chơi',
    myHistory: 'Đơn hàng của tôi',
    winRatio: 'dừng lại',   
    winAmount: 'thắng',
    betAmount: 'Vốn',
    last20: 'Chỉ hiển thị 20 người chơi gần đây!',
    stopPlayer: 'bỏ trốn',
    bet: 'đặt cược',
    status: 'trạng thái',
    ratio: 'dừng lại',
    feeAmount: 'thời gian',
    escape: 'thoát',
    more: 'Chỉ hiển thị đơn hàng hôm nay, bấm vào để xem thêm',
    noHistory: 'Không có dữ liệu cược',
    historData:'Dữ liệu trò chơi trước đó',
    notEnough: 'Số tiền trong ví của bạn không đủ',
    pleaseWithdraw: 'Để đảm bảo an toàn cho tiền của bạn, vui lòng rút tiền mặt.',
    //pleaseWithdraw2:'Chúc mừng chiến thắng! Để đảm bảo an toàn cho tiền của bạn, vui lòng rút tiền mặt.',
    pleaseWithdraw1:'Chúc mừng!',
    pleaseWithdraw2:'bạn đã kiếm được nhiều hơn ',
    // pleaseWithdraw_200:'₹200',
    pleaseWithdraw3:'trước khi tiếp tục chơi,',
    pleaseWithdraw4:'Vui lòng rút tiền để đảm bảo ',
    pleaseWithdraw5:'Bạn sẽ nhận được tiền.',
    go: 'bắt đầu',
    gotitBtn: 'đã rõ',
    info1: 'mời một người bạn',  // You can invite to fill up to xxxx
    info2: 'Lấy lại tiền thưởng bắt đầu ',  // You can invite to fill up to xxxx
    waitForTheNextRound: 'Chờ ván sau!',
    info3: 'VIP0 chỉ có thể cược ',
    DialogTitle: 'Chúc mừng đã thắng lớn!',
    DialogContent1: 'Đối với VIP',
    DialogContent2: ', Hệ số ăn tối đa khi bạn thoát là ',
    DialogContent3: 'x, Bạn có thể nâng cấp vip để gỡ giới hạn.',
  },
  // 红绿游戏页
  PARITY:{
    H1: 'Ngang nhau',
    NAME1: 'Parkash', //99
    NAME2: 'Desai', //99
    NAME3: 'Lakshmi', //99
    PLEASE_WAIT: 'Vui lòng chờ',
    ROUND: 'Round',
    NEXT_ROUND_PREDICTION_STSRT_AT: 'Vòng dự đoán tiếp theo bắt đầu lúc',
    THIS_PREDICTION_STARTS_AT: 'Vòng dự đoán này bắt đầu lúc',
    LIVE_PREDICTION: 'Thời gian dự đoán',
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_1: 'Room dự đoán hiện có thể tiến hành dự đoán ',  //1 LIVE Prediction is now available in Prediction room!
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_2: 'dự đoán ', // 2
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_3: 'Room!',       // 3
    MY_CHOICE: 'lựa chọn của tôi',
    COUNT_DOWN: 'đếm ngược',
    SELECT_TIME: 'thời gian lựa chọn',
    RESULT_TIME2: 'kết quả kiểm tra',
    RESULT_TIME3: 'Thời gian lăn',
    PAUSED_TIME: 'tạm ngừng',
    MY_POINT: 'đặt cược của tôi', 
    VIP: 'Vip',
    JOIN_GREEN: 'chọn màu xanh lá cây',
    JOIN_VIOLET: 'chọn màu tím',
    JOIN_RED: 'chọn màu đỏ',
    JOIN_NUMBER: 'chọn số',
    RECORD: 'Ghi chép',
    CONTINUOUS: 'liên tục',
    PROBABILITY: 'Tính khả thi',
    NEXT_PAGE: 'Trang tiếp theo',
    UPGRADE: 'nâng cấp',
    EVERYONE_ORDER: "Đơn hàng của mọi người",
    MY_ORDER: 'Đơn hàng của tôi',
    BALANCE: 'Số dư',
    FEE: 'Chi phí',
    JOIN: 'chọn',
    CONTRACT_POINT: 'Giá trị hợp đồng',
    ALL: 'tất cả',
    NUMBER: 'số lượng',
    VIP1_INPUT_FREELY: 'Vip1+ tự cược',
    VIP0_ROOKIE_GUIDE: 'Hướng Dẫn Người Mới Vip0',
    FOLLOW_ADVICE_AND_YOU_WIN_FINALLY: "hãy làm theo gợi ý và cuối cùng bạn sẽ thắng!",
    INPUT: 'mua',
    LOSE: 'thất bại',
    WIN: 'thắng',
    SO_THIS_ROUND_SHOULD_INPUT: 'Vì vậy, vòng này nên mua',
    CONFIRM: 'xác nhận',
    TOTAL_PROFIT: 'tổng lợi nhuận',
    PROFIT: 'lợi nhuận',
    USER: 'tài khoản',
    SELECT: 'chọn',
    POINT: 'mua',
    PERIOD: 'Giai đoạn',
    RESULT: 'kết quả',
    AMOUNT: 'thắng',
    RESULT_TIME:'thời gian',
    BTC_PRICE: 'giá bitcoin',
    SERIAL_NUM: 'số seri',
    NEW: 'mới',
    SUGGEST: 'gợi ý',
    PREDICT: 'dự đoán',
    SERIAL: 'thứ tự',
    PROFIT_WIN_INPUT :'Lợi nhuận (Thắng - mua)',
    RESULTS_ORIGIN: 'nguồn kết quả',
    PREDICTION_GAMEPLAY: 'chơi dự đoán',
    INVESTMENT_SKILLS: 'kỹ năng đầu tư',
    ANALYST_INTRODUCTION: 'giới thiệu nhà phân tích',
    RESULTS_ORIGIN_TEXT_1: 'Kết quả đến từ chữ số cuối cùng của chỉ số tổng hợp của 10 loại tiền kỹ thuật số hàng đầu của Coinbase.',
    PREDICTION_GAMEPLAY_TEXT_1: 'Mỗi ngày hệ thống cung cấp 7 kết quả dự đoán từ các nhà phân tích hợp tác, mỗi lần dự đoán 7-15 ván để tham khảo.',
    PREDICTION_GAMEPLAY_TEXT_2: 'Thời điêmr công bố kết quả dự đoán:',
    PREDICTION_GAMEPLAY_TEXT_3: 'Chiến lược đầu tư hiệu quả kết hợp với dự báo của nhà phân tích có thể tăng tỷ lệ thắng trong dài hạn.',
    PREDICTION_GAMEPLAY_TEXT_4: '(Kết quả dự báo chỉ mang tính chất tham khảo và không đảm bảo độ chính xác)',
    INVESTMENT_SKILLS_TEXT_1: 'Cả tỷ lệ chiến thắng và chiến lược đầu tư đều quan trọng.',
    INVESTMENT_SKILLS_TEXT_2: '1.Chiến lược đầu tư',
    INVESTMENT_SKILLS_TEXT_3: 'Chiến lược đầu tư đơn giản [cược theo số nhân]: Nếu thua vòng này, hãy nhân đôi số tiền cược ở vòng tiếp theo cho đến khi thắng.',
    INVESTMENT_SKILLS_TEXT_4: 'Ví dụ (cược nhân 3)',
    INVESTMENT_SKILLS_TEXT_5: '2.Ghép cặp cược',
    INVESTMENT_SKILLS_TEXT_6: 'Học cách quan sát “liên tục”, khi kết quả giống nhau xuất hiện liên tục, hãy chọn kết quả ngược lại, tỷ lệ trúng thường cao hơn.',
    INVESTMENT_SKILLS_TEXT_7: 'Kênh online chính thức với nhiều mẹo hơn >>',
    INITIAL_BALANCE: 'số dư ban đầu',
    INVESTMENT_PLAN: 'Kế hoạch đầu tư',
    TOTAL_ASSETS: 'Tổng tài sản',
    ANALYST_INTRODUCTION_1: 'Tốt nghiệp Học viện Công nghệ Massachusetts với tài năng toán học xuất sắc, tập trung vào thực hành giao dịch dựa trên lý thuyết toán học và phương pháp xác suất, đồng thời đã làm việc trong lĩnh vực phân tích dữ liệu Phố Wall hơn 8 năm.',
    ANALYST_INTRODUCTION_2: 'Hơn 5 năm kinh nghiệm đầu tư blockchain, sở trường là phân tích giá, bằng thạc sĩ toán tài chính của Học viện Công nghệ Illinois, từng làm việc tại bộ phận giao dịch của Mt.Gox.',
    ANALYST_INTRODUCTION_3: 'Cử nhân Kinh tế tài chính của Đại học Toronto, cực nhạy với con số, từng thực hiện giao dịch tăng trưởng 51,1 lần trong 28 ngày.',
    CONGRSTS_XXX_WIN_XXX_1: 'Chúc mừng ',   // Congrats 900***0000 Win 8888
    CONGRSTS_XXX_WIN_XXX_2: ' thắng ',
    GAME_WILL_COMING_SOON: 'trò chơi sắp ra mắt!',
    ROOM_NOT_EXISTS: 'Room không tồn tại!',
    PLEASE_CONFIRM_YOUR_INPUT: 'Vui lòng xác nhận số tiền cược của bạn!',
    AS_A_ROOKIE_YOU_CAN_ONLY_INPUT: 'người mới chỉ được đặt cược ',
    ONLY_FOR_VIP1: 'Chỉ dành cho VIP1+',
    ONLY_FOR_VIP2: 'Chỉ dành cho VIP2+',
    CONNECTING: 'kết nối',
    OOPS_PLEASE_CHECK_YOUR_NETWORK: 'Ối! Vui lòng kiểm tra kết nối mạng của bạn',
    TRY_AGAIN:'thử lại',
    BTC_PRICE_BY_BINANCE: 'Giá BTC trên Binance',
  },
  //转盘
  WHEEL:{
    H1: 'WHEEL', //99
    HISTORY: 'Lịch sử',
    BET_TIME: 'Chọn thời gian', 
    PLAYING:'trong tiến trình', 
    PLEASE_WAIT: 'Vui lòng chờ', 
    CLICK_TO_BET: 'bấm để chọn', 
    VIP_ONLY: 'VIP1+ Only', 
    RULE_H1: 'wheel Luật lệ',
    RULE_T1: '1.cách chơi?', 
    RULE_T2: 'Người chơi có thể chọn màu của bàn xoay, Chọn số tiền đặt cược trước, sau đó chọn màu.', 
    RULE_T3: '2.Win', 
    RULE_T4: 'Tỷ lệ cược của màu xanh và màu vàng là 2 lần, đặt cược 100, thắng 200.', 
    RULE_T5: 'Tỷ lệ đỏ là 9X, cược 100, thắng 900.', 
  },
  // 挖雷游戏页
  MINE:{
    H1: 'Mỏ',
    ROW:'loại',
    START: 'bắt đầu',
    BONUS: 'Tiền thưởng',
    NEXT: 'Người tiếp theo',
    STOP: 'dừng lại',
    POINT: 'số tiền đặt cược',
    SELECT: 'chọn',
    WIN_AMOUNT: 'Số tiền trúng thưởng',
    ORDER_NUM: 'mã số đơn hàng',
    STOP_AND_GET_BONUS: 'dừng lại và nhận tiền thưởng!',
    CHOOSE_GAME_AMOUNT: 'chọn số tiền trò chơi',
  },
  // 登录页
  userLogin: {
    signIn: 'Đăng nhập',                     //登录标题
    smsSignIn: 'đăng nhập bằng tin nhắn SMS',//SMS登录标题
    mobileNumber: 'số điện thoại',           //手机输入框标题
    mobileNumberPlaceholder:'0 bắt đầu', //手机输入框默认提示
    password: 'mật khẩu',                    //密码输入框标题
    passwordPlaceholder: '6~32 ký tự',      //密码输入框默认提示
    login: 'Đăng nhập',                          //确认登录 按钮
    loginSuccess: 'đăng nhập thành công!',          //登录成功 文字提示
    forgotPassword: 'quên mật khẩu?',      //忘记密码 链接
    register: 'đăng ký',                    //注册连接 链接
    SMSLogin: 'Đăng nhập xác minh SMS',      //短信登录 链接
    passwordLogin: 'mật khẩu đăng nhập',          //密码登录 链接
    signIn_text:'đặt lại mật khẩu ',
    signIn_btn: 'Đăng nhập'
  },
  // 设置密码页
  pesetPassword: {
    h1:'đặt lại mật khẩu',              //页面标题
    old:'Mật khẩu cũ',               //旧密码输入框    1 
    oldPlaceholder:'Mật khẩu cũ',    //旧密码输入框 提示   1
    new:'mật khẩu mới',               //新密码输入框   1
    newPlaceholder:'mật khẩu mới',    //旧密码输入框 提示 1
    confirmPassword: 'Xác nhận mật khẩu',
    confirmPasswordPlaceholder: 'Xác nhận mật khẩu',
    success: 'Đặt mật khẩu thành công!', //设置成功提示  1
  },
  // 找回密码
  resetPassword:{
    h1:'đặt lại mật khẩu',
    newPassword: 'mật khẩu mới',
    newPasswordPlaceholder: '6~32 ký tự',
    confirmPassword:'Xác nhận mật khẩu',
    confirmPasswordPlaceholder:'Xác nhận mật khẩu',
    success: 'Đặt mật khẩu thành công!'
  },
  // 用户注册
  userRegister:{
    h1:'đăng ký',                                  //页面大标题
    registerTopText1: 'Nền tảng trực tuyến được chính phủ chứng nhận. Tài khoản và tiền của bạn phải chịu sự giám sát của ngân hàng. Bạn có thể rút bất cứ lúc nào.',  //注册顶部宣传文案
    registerTopText2: 'tiền thưởng đăng ký mới',         //注册顶部宣传文案 第2段： New register bonus xxx
    registerText3: 'Email doanh nghiệp:',
    registerText4: 'Email dịch vụ khách hàng:',
    verificationCode: 'mã xác nhận',          //验证码输入框标题
    verificationCodePlaceholder: '6 ký tự',        //验证码输入框默认提示
    OTP: 'OTP thoại',                                     //发送验证码 按钮
    confirmPassword: 'Xác nhận mật khẩu',            //确认密码输入框 标题
    confirmPasswordPlaceholder: 'Xác nhận mật khẩu', //确认密码输入框默认提示
    recCode: 'mã giới thiệu',                   //邀请码输入框
    recCodePlaceholder: 'không bắt buộc',             //邀请码输入框 默认提示
    iAgree: 'tôi đồng ý',                              //同意注册协议 文案
    privacyPolicy: 'Chính sách bảo mật',                //同意注册协议 链接
    registerBtn: 'đăng ký',                        //确认注册 按钮
    success:'đăng ký thành công!',                //注册成功 弹窗 文案
    signUpBonus:'đăng ký tiền thưởng!',                   //注册成功 弹窗 文案

    signUpCoupon:'Nhận được phiếu giảm giá',       //收到优惠券
  },
  
  //我的主页
  home: {
    h1: 'trang chủ của tôi', 
    mobile: 'điện thoại di động',         // 改成 phone
    vip: 'VIP',               //
    inviteCode: 'Mã mời', //
    balance:'Số dư',        //
    recharge:'nạp tiền',      //
    withdrawal:'rút tiền',  //
    vipDescription: 'Mô tả VIP',
    link:'kênh chính thức',      // Official Telegram
    link_r: 'phần thưởng',
    promotion: 'Mở rộng',            //
    envelopesCenter:'Tin nhắn hệ thống',//
    transactions:'lịch sử giao dịch',       //
    bankCard:'Thẻ ngân hàng',            //  ph: GCash
    resetPassword:'đặt lại mật khẩu',    //
    cs:'Khiếu nại và Đề xuất',     //
    about:'Về',                     //
    logout:'Đăng xuất',          //退出登陆
    vipTitle: 'Mô tả đặc quyền VIP',   //
    withdrawakQuoat: 'giới hạn rút tiền',      //
    level: 'cấp độ vip',                       //
    totalRecharge: 'Tổng số tiền nạp',          //
    profitRatio: 'tỷ suất lợi nhuận',              //
    withdrawalServices: 'dịch vụ rút tiền',//
    vipRewards: 'phần thưởng VIP',                //
    dt: 'Mô tả số tiền rút tiền mặt:',  //
    dd1: '1、Tổng số tiền rút = tổng số tiền gửi x tỷ lệ lợi nhuận;',  //
    dd2: 'Rút tiền VIP8 không bị hạn chế nạp tiền, lời được bao nhiêu rút bấy nhiêu.',  //
    gameAmount: 'Số dư game đến từ lợi nhuận đầu tư và nạp tiền trên hệ thống, ',
    agentAmount: 'số dư đại lý đến từ hệ thống tiền thưởng đại lý.'
  },
  // 设置头像
  setAvatar: {
    h1:'Cài đặt avatar',                  //页面大标题
    current:'Avatar hiện tại',         //当前头像
    avatars:'Kho Avatar:',//选择头像
    success:'Đã đổi avatar thành công!' //成功提示
  },
  //银行卡
  bankCard: {
    h1: 'Thẻ ngân hàng',
    noBankcCard: 'hiện không có liên kết thẻ ngân hàng!',
    addBankcCard: 'thêm thẻ ngân hàng',
    delete: 'xóa ?',
    editTitle: 'chỉnh sửa thẻ',      //编辑银行卡
    addTitle: 'thêm thẻ ngân hàng',        //添加银行卡
    realName: 'tên thật',            //输入框字段
    realNamePlaceholder: 'tên thật', //输入框字段 提示
    ifscCode: 'mã IFSC',            //输入框字段
    ifscCodePlaceholder: 'mã IFSC', //输入框字段 提示
    bankName: 'tên ngân hàng',            //输入框字段
    bankNamePlaceholder: 'tên ngân hàng', //输入框字段 提示
    bankAccount: 'Tài khoản ngân hàng',      //输入框字段
    bankAccountPlaceholder: 'Tài khoản ngân hàng',//输入框字段 提示
    upiVpa: 'UPI ID (Vpa)',                //输入框字段
    upiVpaPlaceholder: 'UPI ID (Vpa)',     //输入框字段 提示
    email: 'Email',                   //输入框字段
    emailPlaceholder: 'Email',        //输入框字段 提示
    success:'thành công',                //成功提示
    addcard_text:'Vui lòng đảm bảo thông tin chính xác để tránh trường hợp không nhận được tiền rút.',
    GCash: 'GCash', 
    GCashPlaceholder: 'GCash Mobile', 
  },
  //投诉 & 建议
   CS: {
    h1: 'khiếu nại và đề xuất',
    noData: 'Không có lịch sử khiếu nại và đề xuất!',
    addBtn: 'thêm khiếu nại',
    waitReply: 'Chờ hồi âm',
    replied: 'đã trả lời',
    h2: 'Ghi khiếu nại hoặc đề nghị',
    type: 'phân loại',
    typePlaceholder: 'chon phân loại',
    title: 'tiêu đề',
    titlePlaceholder:'vui lòng nhập tiêu đề',
    description: 'mô tả',
    descriptionPlaceholder: 'vui lòng nhập mô tả',
    servicTime: 'Phục vụ:10:00~16:00, Mon~Fri',
    servicTime2: 'Vui lòng cung cấp ảnh chụp màn hình vấn đề bạn gặp phải, số Upi/UTR và id của bạn gửi đến email dịch vụ khách hàng của chúng tôi:',
    recharge: 'nạp tiền',
    withdraw: 'rút tiền',
    invite: 'mời',
    gameCrash: 'trò chơi sụp đổ',
    others: 'khác',
  },
  // 消息
  envelopes: {
    h1:'Tin nhắn hệ thống',  //页面大标题
    new: 'mới',             //新消息标识
  },
  // 充值页
  recharge: {
    h1:'nạp tiền',               //页面大标题
    balance: 'Số dư',          //标题
    amount: 'số tiền',            //金额title
    amountPlaceholder: 'số tiền', //金额输入框提示
    receipt: 'thu tiền',          //手续费title
    receiptPlaceholder: 'thu tiền',//手续费输入框提示
    channel: 'kênh',          //渠道title
    success: 'Nạp tiền thành công!',//充值成功
    failSelect: 'Vui lòng chọn kênh nạp tiền!',         //请选择充值渠道！
    failEnter: 'Vui lòng nhập đúng số tiền!',           //请输入正确的金额
    pop:'Hoàn thành thanh toán trên trang mới, vui lòng chờ...',             //请在新页面完成支付
    description:'Mô tả',
    tx5: '1. Mọi thắc mắc về vấn đề nạp tiền vui lòng liên hệ theo các cách sau ',
    tx5_1: 'gọi đến dịch vụ khách hàng',
    tx5_2: ' , Giờ làm việc: 7:00-20:00',
    tx6: '2.Số tiền nạp sẽ nâng cấp bậc VIP của bạn, cấp bậc VIP cao hơn sẽ tăng số tiền rút, tiền thưởng hàng ngày, v.v. ',
    tx7: 'Kiểm tra',
    tx8: '“Mô tả VIP”',
    tx9: '3.Không tiết lộ mật khẩu của bạn để tránh bị đánh cắp. Nhân viên của chúng tôi sẽ không hỏi mật khẩu của bạn.',
    levelUpVip: 'nâng cấp ngay bây giờ!',
    popTitle_1:'Mô tả VIP',
    popTitle_2:'Mô tả VIP',
    
  },
  // 充值列表页
  rechargeList: {
    h1:'Lịch sử nạp tiền',         //页面大标题    
    giftAmount: 'số tiền thưởng',     //列表字段  
    applyAmount: 'Số tiên đăng ký',   //列表字段  
  },
  // 提现页
  withdrawal: {
    h1:'rút tiền',                       //页面大标题
    availableBalance:'Số dư khả dụng',  //可用余额
    agentAmount: 'số tiền đại lý',           //邀请金额
    gameAccount: 'tài khoản game',           //游戏金额
    transfer: 'chuyển khoản',
    withdrawAmount: 'Số tiền rút',     //提现金额 输入框标题
    handlingFee: 'phí xử lý',           //手续费 输入框标题
    receiptAmount: 'Số tiền nhận',       //收款金额 输入框标题
    bankCard: 'Thẻ ngân hàng',                 //银行卡选择 标题
    withdrawalBtn: 'rút tiền',           //提交 按钮
    errInfoBankCard: 'Vui lòng thêm thẻ ngân hàng để rút tiền.', //请先添加银行卡 提示信息
    errInfoRecharge: 'Bạn cần nạp thêm để rút tiền.', //请先充值才能提现 提示信息
    errInfopPlayMore: 'Thưa ông/bà, số tiền cược của ông/bà quá thấp, vui lòng tiếp tục trò chơi.',    //请先玩更多才能提现 提示信息
    description:'Mô tả',
    tx4: '1.Mọi thắc mắc về vấn đề rút tiền vui lòng liên hệ với chúng tôi qua các cách sau ',
    tx4b: 'Mọi thắc mắc về vấn đề rút tiền vui lòng liên hệ với chúng tôi qua các cách sau ',
    tx5: '1.Mọi thắc mắc về vấn đề rút tiền vui lòng liên hệ với chúng tôi qua các cách sau ',
    tx5b: 'Mọi thắc mắc về vấn đề rút tiền vui lòng liên hệ với chúng tôi qua các cách sau ',
    tx_contact_us:'liên hệ chúng tôi',

    tx5_1: 'gọi đến dịch vụ khách hàng',
    tx5_2: ' , Giờ làm việc: 7:00-20:00',
    tx6: '2.Cấp bậc VIP cao hơn có thể tăng giới hạn rút tiền.',
    tx7: 'See the',
    tx8: '“Mô tả VIP”',
    tx9: '3.Để ngăn chặn rửa tiền, bạn phải đặt cược đủ mới có thể rút tiền.',
    tx10: 'Chúc mừng!',
    tx11: '🎉🎉🎉Tài khoản của bạn an toàn và đã được xác minh 🤝🤝🤝Bây giờ bạn có thể tiếp tục chơi!🥳🥳🥳',
    btntx: 'Chơi'
  },
  // 提现列表页
  withdrawalList: {
    h1:'Lịch sử rút tiền',            //页面大标题
    receiptAmount: 'Số tiền nhận',    //列表字段
    applyAmount: 'Số tiền đăng ký',        //列表字段
    withdrawFee: 'Phí rút tiền',        //列表字段
    pleaseCheckBank: 'Vui lòng xác nhận rằng thông tin ngân hàng của bạn là chính xác và rút tiền lạ',   //列表字段
  },
  // 金币转换
  transfer: {
    h1:'chuyển khoản', 
    transferAmount: 'Số tiền chuyển khoản', 
    btntx: "xác nhận", 
    success: 'chuyển thành công',
    tx1: 'Số dư tài khoản trò chơi đến từ lợi nhuận nền tảng và nạp tiền. Số dư tài khoản đại lý đến từ hệ thống hoa hồng đại lý.',
    tx2: 'Nếu bạn cần, số dư tài khoản đại lý có thể được tự do chuyển sang số dư tài khoản trò chơi để chơi trò chơi. Có thể rút cả hai số dư tài khoản.', 
  },
  //钱包明细列表
  transactions:{
    h1:'Giao dịch',                   //页面大标题
    cashH1:'Giao dịch tiền mặt',
  },
  //任务页
  taskReward:{
    h1: 'Nhiệm vụ',                   //页面大标题
    day: 'ngày',                          //新手任务   xx day
    checkIn:'Điểm danh',
    checkInLocked:'lock',
    toTask: 'đi',                //去做任务按钮
    dailyQuest: 'nhiệm vụ hàng ngày',
    dailyQuestLocked: 'nhiệm vụ hàng ngày (lock)',
    dailyQuestCompleted: 'Nhiệm vụ hàng ngày (Đã hoàn thành!)',
    dailyQuestFinished: 'Dailies (Kết thúc)',
    completeTask: 'Đi',//完成任务
    receiveReward: 'nhận phần thưởng',       //领取奖励按钮
    received: 'đã nhận',                //已经领取按钮
    completed: 'Hoàn thành',              //按钮
    complete: 'Hoàn thành',                //完成百分比    100% Complete
    checkIn7Text: 'Điểm danh 7 ngày liên tiếp',      //签到进度标题  Check in for 7 consecutive days (x/x)
    vipCheckInText: 'Nâng cấp VIP để nhận thêm phần thưởng',//签到进度副标题
    checkInText: 'Điểm danh hàng ngày để nhận phần thưởng',    //7天之后的 签到标题
    missionTarget: 'Nhiệm vụ',     //任务列表标题
    getBn: 'phần thưởng đã nhận!',              //领取弹框 大标题
  },
  // 代理激励计划
  agentGrowth: {
    h1: 'tăng trưởng đại lý',
    bn1: "Kế hoạch tăng trưởng bạc triệu mặt được chia thành ",   //bn1 + xx + bn2 + xx + bn3!
    bn2: " level, mỗi level có phần thưởng phong phú. Hoàn thành level cao nhất và nhận phần thưởng ",
    bn3: " ngay lập tức.",
    target:'Mục tiêu',
    h4_1: 'điều kiện khen thưởng',
    h4_item1_1: 'mời',
    h4_item1_2: 'Người dùng hợp lệ (đăng ký và nạp tiền), bạn có thể nhận tiền mặt.',
    h4_2: 'nâng cấp kỹ năng',
    h4_item2_1: 'Bạn chia sẻ liên kết, người khác đăng ký thông qua liên kết và trở thành là cấp dưới của bạn. Khi họ nạp tiền (người dùng hợp lệ), thì bạn có thể rút tiền ',     
    h4_item2_2: 'ngay lập tức.',
  },
  // 代理激励计划
  AGENT_SUPPORT_PLAN: {
    H1: 'Agent Support Plan',  //99
    MY_SUPPORT_PLAN: 'My Support Plan', //99
    MY_SUPPORT_PLAN_LEVEL: 'My Level',//99
    TODAY_EFFECIVE_USERS_AMOUNT: 'Today Effecive Users Count',//99
    TODAY_POINTS_BONUS: 'Today Input Bonus',//99
    YESTERDAY_SUPPORT_REWARD: 'Yesterday Support Reward',//99
    BTN_TEXT: 'Click to receive reward >>',//99
    INFO_TEXT: 'If the system detects any cheating, bonuses and accounts will be frozen.',//99
    AGENT_REWAED_TOP: 'Agent Reward top 5',//99
    LEVEL: 'Level',//99
    LV: 'Lv',//99
    PHONE: 'Phone',//99
    ORIGINAL_SYSTEM_REWAED: 'Original System Reward',//99
    SUPPORT_REWARD: 'Support Reward',//99
    ONLY_TOP_5: 'Only show Top 5 data!',//99
    DAILY_REQUIRE: 'Daily Require',//99
    REWARD: 'Reward',//99
    EFFECIVE_USERS_AMOUNT: 'Effecive Users Count',//99
    POINTS_BONUS: 'Input Bonus',//99
    TOTAL_SUPPORT_REWARD: 'Total Support Reward',//99
    CONSECUTIVE_DAYS_EXTRA_REWAED: '7 Consecutive Days Extra Reward',//99
    I_E_Q: '(Invite + Effective + Quests)Bonus'//99
  },	
  // 邀请
  invite: {
    h1: 'mời',
    agentAmount: "Số dư của đại lý",
    withdraw:'rút tiền',
    totalPeople: 'tổng số người',
    effectiveNum: 'Số người dùng hiệu quả',
    h3: 'Kế hoạch tăng trưởng triệu đại lý',
    contribution: 'tiền thưởng đóng góp',
    more: 'Vuốt để xem thêm',                                           //更多提示
    affinity: 'cấp bậc',                                                //table 字段
    inviteCount: 'Số lời mời',                                         //table 字段
    effectiveCount: 'Số người dùng hiệu quả',                                   //table 字段
    effectiveBonus: 'Tiền thưởng lời mời hợp lệ',                                   //table 字段
    inviteCommissionAmount: 'tiền thưởng lời mời',                  //table 字段
    subBetCommissionRatio:'tiền thưởng cá cược',                    //table 字段
    subBetCommissionAmount:'tiền thưởng cá cược',                  //table 字段
    subFirstRechargeCommissionRatio:'Tỷ lệ thưởng khi cấp dưới nạp tiền lần đầu',//table 字段
    subFirstRechargeCommissionAmount:'Tiền thưởng khi cấp dưới nạp tiền lần đầu',//table 字段
    totalCommissionAmount:'tổng tiền thưởng',                     //table 字段
    Grade: 'Cấp bậc',
    Bonuss: 'thưởng',
    rules: 'quy tắc',                 //tab标题1
    bonus: 'thưởng',                 //tab标题2
    myCode: 'mã mời của tôi',    //tab 右侧链接标题
    you: 'Bạn',
    youTitle: 'Hệ thống đại lý 4 cấp bậc, mỗi cấp đều mang lại tiền thưởng!',
    level: 'Cấp bậc',              // 等级title
    label1: 'thưởng',     // 第1个字段名称

    rulesTitle2: 'lâu dài nhất',         // 规则2标题
    rulesText21: 'Hệ thống đại lý 4 cấp bậc, mỗi người dùng đèu mang lại tiền thưởng!', // 规则2内容
    rulesText22: 'Nếu mỗi người mời 10 người, mỗi người chỉ cần cược 10000 VNĐ/ngày, sau 30 ngày, thông qua hệ thống đại lý bốn cấp, bạn kiếm được hơn 3,500,000 VNĐ!', // 规则2内容

    rulesTitle3: 'hiệu quả nhất',         // 规则3标题
    rulesText31: 'Nhấp và chia sẻ liên kết tiền thưởng trong kênh Telegram chính thức, kiếm tiền ngay!', // 规则3内容
    rulesText32: 'Đại lý của chúng tôi, Bindiya- một người bán hàng rong, mời 100 người dùng mới đăng ký mỗi ngày bằng cách chia sẻ liên kết và kiếm được khoảng 1,500,000 VNĐ mỗi ngày! anh ấy có thể, bạn cũng có thể!',

    rulesTitle1: 'đơn giản nhất',         // 规则1标题
    rulesText11: 'Mỗi thuê bao được thưởng 300 ~ 30,000VNĐ, nhận ngay!', // 规则1内容
    rulesText12: 'Nếu bạn mời 100 người, nhận tối thiểu 40,000 VNĐ và tối đa 3,000,000 VNĐ',

    rulesTitle4: 'triệu hồi là gì?',
    rulesText41: 'Mỗi người dùng được mời mang lại cho bạn phần thưởng TỐI ĐA 30,000 VND và người bạn mời sẽ nhận được phần thưởng 30,000 VNĐ.',

    h4: 'mã mời của tôi',
    h4Title: 'Nền tảng kiếm tiền trực tuyến hot nhất năm 2023, dễ dàng kiếm 3,000~20,000 VND',
    btn: 'sao chép liên kết',
    to: 'hoặc chia sẻ với',
    shareMakeMoneya: 'chia tiền',
    info1: 'Mỗi người dùng được mời đều mang lại cho bạn phần thưởng TỐI ĐA 99 VND và những người bạn mời sẽ nhận được ',
    info2: ' phần thưởng.',
    //ShareNetwork
    url: '',        //分享链接
    title: 'Nền tảng kiếm tiền an toàn nhất ',      //分享标题
    description: 'Cách kiếm tiền nhanh chóng và dễ dàng ở vào năm 2023 nhận ngay tiền thưởng 🚀 Rút tiền trong vòng 3 phút 🚀 Giành được 100 lần 🚀 Chia sẻ phần thưởng trị giá',//分享描述
    quote: 'Tham gia càng sớm, bạn càng kiếm được nhiều tiền!',  
    hashtags: 'game,crash',  //分享关键词
    twitterUser: '',     //作者
    cashHistory:'Cash history',
    infoToast: 'Invited users must meet the effective number standard required by the system, otherwise they will not be counted. Invite more effective users, and the statistics will resume.',
    Congratulations:'Chúc mừng',
    NewAgencyIncome:'thu nhập từ tiền thưởng gần đây', 
    NewInvites:'Số người mời gần đây', 
    NewEffective:'Số lời mời hợp lệ gần đây', 
    EarnMoreNow:'kiếm tiền ngay bây giờ', 
    Last30Days: 'Nguồn dữ liệu từ 30 ngày qua',
  },
  //我的历史下注
  myPlayList: {
    h1: 'Đơn hàng của tôi',      //页面大标题
    bet: 'đặt cược: ',
    win: 'thắng: ',
    crash: 'Sụp đổ',
    ratio: 'dừng lại',
    originWinAmount: 'thắng: ',
    feeAmount: 'Chi phí: ',
    gameNum: '',
    time:'thời gian'
  },
  //领取红包页面
  receiveBonus:{
    h1: 'lãnh tiền thưởng',              //页面大标题
    name: 'Bạn bè của bạn',              // xxxx  （没有手机号的时候会显示这个字段）
    h2: 'gửi cho bạn một phần thưởng !',        // xxxx 给你发来了红包！
    h3: 'Đã gửi',
    btn: 'nhận được',               //按钮
    btn2: 'Nhận tiền thưởng mới nhất', //按钮
    success: 'nhận thành công!',      //成功提示
    user: 'người dùng',                     // 用户 xxxx 领取了红包  前
    receives: 'nhận phần thưởng !',// 用户 xxxx 领取了红包  后
    // errText: 'Vui lòng nhấp vào đây để nhận phần thưởng chia sẻ mới nhất!',
    errText: '',
  },
  //分享红包页面
  shareBonus:{
    h1: 'chia sẻ tiền thưởng',                         //页面大标题
    h2: 'Nhận tiền thưởng thành công !', //页面标题
    h3: 'Chia sẻ với người khác sẽ mang lại cho bạn lợi nhuận 100%!',              //副标题
    btn: 'chia tiền',                 //按钮
    copied: 'Liên kết chia sẻ của bạn đã được sao chép!',//复制地址之后提示文字
  },
  //小提示
  tips:{
    tips1: 'Cược tối thiểu 10, cược tối đa 10,000',
    tips2: 'Hoàn thành nhiệm vụ mỗi ngày để nhận phần thưởng!',
    tips3: 'Tên lửa có thể bay tới X100',
    tips4: 'Tìm kiếm quy luật là mấu chốt để chiến thắng',
    tips5: 'Kumar là người đầu tiên nhảy dù thoát hiểm thành công với mức X 85,77 lần, hãy gửi lời chào anh ấy.',
    tips6: 'liên tiếp xảy ra các vụ bội số thấp, cơ hội bay cao ở ván sau sẽ tăng cao hơn'     
  },
  //vip说明  
  VIP_PRIVILEGE: {
    VIP_PRIVILEGE: 'đặc quyền VIP',
    WITHDRAWAL_DESCRIPTION: 'rút tiền Mô tả',
    LEVEL: 'Cấp bậc',
    TOTAL_RECHARGE: 'Tổng số tiền nạp',
    WITHDRAWAL_QUOTA: 'Hạn ngạch rút tiền',
    REWARDS: 'phần thưởng',
    DAILY_BONUS: 'Tiền thưởng hàng ngày',
    EXTRA: 'Thêm',
    WITHDRAWAL: 'rút tiền',
    STOP_LIMIT: 'dừng giới hạn',
    INPUT_LIMIT: 'đặt cược giới hạn',
    GAMEPLAY: 'lối chơi',
    DATA_ANALYSIS: 'Phân tích dữ liệu',
    ROOM_LIMIT:'giới hạn phòng',
    MAX: 'tối đa',
    RECORD: 'Ghi',
    CONTINUOUS: 'liên tục',
    PROBABILITY: 'Tính khả thi',
    TEXT1: 'Số tiền có thể rút(Lv1-Lv3)=Số dư - Tiền thưởng ban đầu(',
    TEXT2: ')',
    TEXT3: 'Nâng cấp lên cấp VIP tương ứng để hoàn thành việc rút tiền này',
  },
  //游戏规则 飞机
  gameRules:{
    h1: 'luật chơi',
    dt1: 'cách chơi?',
    dd1_1: 'Đầu tiên, chọn số tiền bạn muốn cược. Nhấp vào "Bắt đầu" để hoàn tất đặt cược và xem hệ số nhân tăng từ 1,00 lần! ',
    dd1_2: 'Bạn có thể nhấp vào "Dừng" bất kỳ lúc nào để nhân tiền cược của mình hiện tại. Nhưng hãy cẩn thận: trò chơi có thể kết thúc bất cứ lúc nào và nếu đến lúc đó bạn vẫn chưa chạy thoát, bạn sẽ chẳng nhận được gì! ',
    dd1_3: 'Bạn cần đợi trò chơi tiếp theo bắt đầu.',
    dt2: 'Bạn cần đợi trò chơi tiếp theo bắt đầu ?',
    dd2_1: 'Nếu số tiền đặt hàng của bạn là 100 và hệ số thoát là 10,0 lần, sau khi trừ phí dịch vụ 3 VND, số tiền đặt cược của bạn là 97 VND:',
    dd2_2: '10.0X: Bạn sẽ nhận được (97 * 10) 970 VND.',
    dt3: 'Bội số có thể cao đến mức nào?',
    dd3: 'lên đến 100 lần',
    dt4: 'dừng tự động là gì?',
    dd4: 'Nếu bạn chọn tự động dừng, khi số nhân đạt đến hệ số đã, nó sẽ tự động dừng và nhận thưởng.',
    dt5: 'Tại sao các quầy đếm có màu sắc khác nhau? ',
    btn: 'tôi hiểu rồi',
  },
  //游戏规则 
  gameRulesParity:{
    h1: 'luật chơi',
    dt1: 'nhanh',
    dd1_1: 'Một vòng 30 giây, thời gian đặt cược 25 giây, 5 giây thông báo kết quả, 24 giờ liên tục, tổng cộng 2880 vòng mỗi ngày.',
    dd1_2: 'Đặt cược 100 VND',
    dd1_3: 'chọn màu xanh lá cây:',
    dd1_4: 'Nếu kết quả là 1, 3, 7, 9, bạn sẽ nhận được 195 VND và nếu kết quả là 5, bạn sẽ nhận được 142,5 VND.',
    dd1_5: 'chọn màu đỏ:',
    dd1_6: 'Nếu kết quả là 2, 4, 6, 8, bạn sẽ nhận được 195 VND và nếu kết quả là 0, bạn sẽ nhận được 142,5 VND.',
    dd1_7: 'chọn màu tím:',
    dd1_8: 'Nếu kết quả là 0,5, bạn sẽ nhận được 427,5 VND',
    dd1_9: 'chọn số:',
    dd1_10: 'Nếu kết quả là số bạn chọn, bạn sẽ nhận được 855 VND.',
    dt2: 'dự đoán',
    dd2_1: 'Một vòng quay 90 giây, thời gian đặt cược 80 giây, 10 giây thông báo kết quả, 24h không ngừng, tổng cộng 960 vòng quay/ngày.',
    dd2_2: 'Đặt cược 100 VND',
    dd2_3: 'chọn màu xanh lá cây:',
    dd2_4: 'Nếu kết quả là 1, 3, 7, 9, bạn sẽ nhận được 195 VND và nếu kết quả là 5, bạn sẽ nhận được 142,5 VND.',
    dd2_5: 'chọn màu đỏ:',
    dd2_6: 'Nếu kết quả là 2, 4, 6, 8, bạn sẽ nhận được 195 VND và nếu kết quả là 0, bạn sẽ nhận được 142,5 VND.',
    dd2_7: 'chọn màu tím:',
    dd2_8: 'Nếu kết quả là 0,5, bạn sẽ nhận được 427,5 VND',
    dd2_9: 'chọn số:',
    dd2_10: 'Nếu kết quả là số bạn chọn, bạn sẽ nhận được 855 VND.',
    dt3: 'Chân thật mới công bằng, minh bạch mới đáng tin cậy.',
    dd3_1: 'Để đảm bảo quyền lợi của mọi nhà đầu tư và tuân thủ các tiêu chuẩn chứng nhận của IBAS và MGA, việc tạo ra kết quả Chẵn lẻ được giải thích như sau',
    dd3_2: '1. Nguồn kết quả: Lấy chữ số cuối cùng của giá đóng cửa của cặp giao dịch giao ngay Binance BTC/USDT vào cuối thời gian lựa chọn Parity.',
    dd3_3: '2. Xác minh dữ liệu: Bạn có thể xem thời gian kết quả lịch sử và giá tương ứng trên trang đặt hàng và so sánh dữ liệu lịch sử của Binance.',
    dd3_4: "Lưu ý: Binance hiện là sàn giao dịch tiền điện tử lớn nhất thế giới. BTC/USDT là giá giao dịch của Bitcoin/Tether. Với khối lượng giao dịch hàng ngày là 760,000 đô la, không ai có thể thao túng giá này trong vài giây.",
    btn: 'tôi hiểu rồi',
  },
  gameRulesMine:{
    h1: 'quy tắc mỏ',
    dt1: 'Ba cách chơi: 2x2, 4x4, 6x6, mỗi cách có số ô và tiền thưởng khác nhau.',
    dt2: 'Lấy 4x4 làm ví dụ, tổng cộng có 16 ô, trong đó có một ô chôn mìn, bạn cần tìm ô không có mìn, mỗi khi tìm được sẽ có phần thưởng',
    dt3: 'Nhấp vào bắt đầu, chọn nhập số lượng vào và bắt đầu chơi.',    
    dt4: 'Nếu không tránh được mìn, trò chơi kết thúc',
  },
  
  // AB游戏页
  andarBahar:{
    H1: 'Andar Bahar',
    Period: 'Giai đoạn',
    Bet_end: 'Kết thúc cược',
    Count_Down: 'Đếm ngược',
    Cards: 'thẻ',
    My_Bet: 'Đặt cược của tôi',
    Not_bet: 'Not bet',
    Rules: 'Quy tắc',
    START: 'BẮT ĐẦU',
    Wait_for_the_next_round: 'Đợi vòng tiếp theo!',
    Bet_Joker: 'cá cược joker',
    Suit_Of_Joker_Card: 'Bộ thẻ Joker',
    Value_Of_Joker_Card: 'Giá trị của thẻ Joker',
    Bet_Deal: 'Giao dịch cá cược',
    Winning_Side: 'Bên thắng',
    Cards_To_Be_Dealt: 'Thẻ được chia',
    Record: 'Ghi',
    Bet: 'Nhưng',
    Win: 'Thắng',
    VIP_Only: 'Chỉ dành cho VIP',
    Num: 'Số sê-ri',
    Choose: 'Chọn',
    Place_your_bets:'Đặt cược',
    value: 'con số',
    SuitDeal:'Suit & Deal'
  },

  gameRulesAndar:{
    h1: 'Quy tắc Andar Bahar',
    dt1: 'Andar Bahar là một trò chơi phổ biến, dễ chơi, cực kỳ thú vị và độc đáo.',
    dd1: 'Trò chơi được chơi trực tiếp 24 giờ trong ngày và sử dụng một bộ bài tiêu chuẩn gồm 52 lá bài. Trò chơi bao gồm hai vòng. Trong vòng đầu tiên của trò chơi, người chia bài sẽ lật một lá bài – lá bài “joker” – và đặt nó lên bàn ở một vị trí được đánh dấu đặc biệt. Ở vòng thứ hai, người chia bài bắt đầu chia lần lượt từng quân bài cho hai bên Andar và Bahar. Mục đích của trò chơi là có được một lá bài có giá trị tương ứng với lá bài “người pha trò”.',
    dt2: '1.Vòng cược đầu tiên',
    dd21: '1.1.Người đặt cược có thể dự đoán chất của lá bài “joker”, có 4 chất ( ♥, ♦, ♠, ♣)',
    dd22: '1.2.Người đặt cược cũng có thể dự đoán giá trị lá bài "joker" là trên, dưới hoặc chính xác là 7.',
    dd23: '1.2.1.Để cược “Dưới 7” thắng, lá bài “joker” phải có một trong các giá trị sau: A = Át, 2 = hai, 3 = ba, 4 = bốn, 5 = năm , 6 = sáu.',
    dd24: '1.2.2.Để cược “trên 7” thắng, lá bài “joker” phải có một trong các giá trị sau: 8 = tám, 9 = chín, 10 = mười, J = jack, Q = queen , K = vua.',
    dd25: '1.2.3.Để thắng cược “chính xác là 7”, lá bài “joker” phải có giá trị là 7.',
    dt3: '2.Vòng cược thứ hai',
    dd31: '2.1.Andar Bahar',
    dd32: '2.1.1.Andar để giành chiến thắng',
    dd33: 'Cược vào lá bài trùng với lá bài "joker" sẽ được đặt về phía Andar.',
    dd34: '2.1.2.Bahar để giành chiến thắng',
    dd35: 'Cược vào quân bài giống với quân bài "joker" được đặt bên phía Bahar.',
    dd36: '2.2.Số quân bài được chia',
    dd37: 'Cược số lượng quân bài sẽ được chia cho đến khi quân bài giống với quân bài "joker" được đặt về phía Andar hoặc Bahar',
  },

  gameRulesDice:{
    h1: 'Quy tắc Dice',
    title:'Dice là một trò chơi may rủi thú vị được phổ biến trên toàn thế giới',
    dt1:'1.Làm thế nào để chơi?',
    dd1:'Trò chơi nhanh, bạn có thể đặt cược lớn hoặc nhỏ trong mỗi vòng và bạn cũng có thể đặt cược số Xúc xắc. Nếu kết quả khớp với cược của bạn, bạn có thể thắng tiền nhanh chóng.',
    dt2:'2.Thắng',
    dd2:'Đặt cược lớn và nhỏ, tỷ lệ cược là 1:2',
    dd3:'Cược số, tỷ lệ cược là 1:6',
  },

  //sicbo
  SICBO:{
    H1: 'DICE',
    BET: "Đặt cược",
    BET_BIG_OR_SMALL: "Cược Lớn Hoặc Nhỏ",
    BET_NUMBER: "Đặt cược Số",
    BET_BIG: "Đặt cược '456'",
    BET_SMALL: "Đặt cược '123'",
    VIP_1_UNLOCK: 'Vip1+ Mở khóa',
  },

  // 注册协议
  privacyPolicy:{
    p1:'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
    p2:'Interpretation and Definitions',
    p3:'Interpretation',
    p4:'The words of which the initial letter is capitalized have meanings defined under the following conditions.',
    p5:'The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
    p6:'Definitions',
    p7:'For the purposes of this Privacy Policy:',
    p8:'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
    p9:'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Coem Shop.',
    p10:'Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
    p11:'Account means a unique account created for You to access our Service or parts of our Service.',
    p12:'Website refers to Coem Shop, accessible',
    p13:'Service refers to the Website.',
    p14:'Country refers to: Uttar Pradesh, India',
    p15:'Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
    p16:'Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.',
    p17:'Personal Data is any information that relates to an identified or identifiable individual.',
    p18:'Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
    p19:'Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
    p20:'Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
    p21:'Collecting and Using Your Personal Data',
    p22:'Types of Data Collected',
    p23:'Personal Data',
    p24:'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:',
    p25:'Email address',
    p26:'First name and last name',
    p27:'Phone number',
    p28:'Address, State, Province, ZIP/Postal code, City',
    p29:'Usage Data',
    p30:'Usage Data',
    p31:'Usage Data is collected automatically when using the Service.',
    p32:"Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
    p33:'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.',
    p34:'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.',
    p35:'Tracking Technologies and Cookies',
    p36:'We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.',
    p37:'You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.',
    p38:'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.',
    p39:'We use both session and persistent Cookies for the purposes set out below:',
    p40:'Necessary / Essential Cookies',
    p41:'Type: Session Cookies',
    p42:'Administered by: Us',
    p43:'Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.',
    p44:'Cookies Policy / Notice Acceptance Cookies',
    p45:'Type: Persistent Cookies',
    p46:'Administered by: Us',
    p47:'Purpose: These Cookies identify if users have accepted the use of cookies on the Website.',
    p48:'Functionality Cookies',
    p49:'Type: Persistent Cookies',
    p50:'Administered by: Us',
    p51:'Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
    p52:'For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.',
    p53:'Use of Your Personal Data',
    p54:'The Company may use Personal Data for the following purposes:',
    p55:'To provide and maintain our Service, including to monitor the usage of our Service.',
    p56:'To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
    p57:'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
    p58:"To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
    p59:'To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
    p60:'To manage Your requests: To attend and manage Your requests to Us.',
    p61:'We may share your personal information in the following situations:',
    p62:'With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.',
    p63:'For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.',
    p64:'With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
    p65:'With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.',
    p66:'With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see You name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.', 
    p67:'Retention of Your Personal Data',
    p68:'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
    p69:'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.',
    p70:'Transfer of Your Personal Data',
    p71:"Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
    p72:'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.',
    p73:'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.',
    p74:'Disclosure of Your Personal Data',
    p75:'Business Transactions',
    p76:'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
    p77:'Law enforcement',
    p78:'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
    p79:'Other legal requirements',
    p80:'The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:',
    p81:'Comply with a legal obligation',
    p82:'Protect and defend the rights or property of the Company',
    p83:'Prevent or investigate possible wrongdoing in connection with the Service',
    p84:'Protect the personal safety of Users of the Service or the public',
    p85:'Protect against legal liability',
    p86:'Security of Your Personal Data',
    p87:'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.',
    p88:"Children's Privacy",
    p89:'Our Service does not address anyone under the age of 16. We do not knowingly collect personally identifiable information from anyone under the age of 16. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 16 without verification of parental consent, We take steps to remove that information from Our servers.',
    p90:"If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
    p91:'Links to Other Websites',
    p92:"Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
    p93:'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
    p94:'Changes to this Privacy Policy',
    p95:'We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
    p96:'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.',
    p97:'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page',
    p98:'Other',
    p99:"If the loss is caused by the user's wrong input of bank account information, the user shall be responsible for the loss.",
  },
}
