<template>
  <div>
    <div class="pt50 scroll-body">
      <div class="p20">

          <ListEmpty v-show="listNoData"></ListEmpty>

          <div class="csls" id="scroll">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div class="csls-item" v-for="(item, index) in list" :key="index" :class="'csls-item-bg'+item.level">
                <h4>
                  <span style="font-weight: 300">{{$t("myPlayList.ratio")}}:</span> <span class="fc-red">{{ item.crashRatio }}x</span>
                  <!-- <span class="tx-right-g">{{ item.serialNum }}</span> -->
                </h4>
                <p>{{$t("myPlayList.time")}}: {{ item.startTime }}</p>
              </div>
            </nut-infiniteloading>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  name: 'GameHistoryList',
  components: {
    ListEmpty: ListEmpty,
  },
  props: {
    msg: String
  },
  data() {
    return {
      //tabValue: '0',
      listNoData: true,
      list: [],
      current: 1,
      hasNext:''
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    getCoinList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/crash/history/paginate",
        method: "get",
        params: { current: c }
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }
        Toast.hide("loading")
      }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  created() {
    this.getCoinList(1)
  },
  setup() {},
};
</script>

<style scoped>
.scroll-body{
  top:0;
  left: 0;
  right:0;
  bottom: 0;
  overflow: auto
}
.nut-pagination {
  margin-top: 10px;
  justify-content: center;
}
.csls{
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 10px;right: 10px;top: 60px; bottom: 10px;
}
.csls-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee; padding-left: 10px;
}
.csls-item-bg1{border-left:5px solid #f63a3a;}
.csls-item-bg2{border-left:5px solid #f7bb47;}
.csls-item-bg3{border-left:5px solid #208f0a;}
.csls-item-bg4{border-left:5px solid #2988f6;}
.csls-item-bg5{border-left:5px solid #7854c0;}

.csls-item-bg1 .fc-red{color: #f63a3a;}
.csls-item-bg2 .fc-red{color: #f7bb47;}
.csls-item-bg3 .fc-red{color: #208f0a;}
.csls-item-bg4 .fc-red{color: #2988f6;}
.csls-item-bg5 .fc-red{color: #7854c0;}
fc-red



.csls-item h4 {
  margin: 5px 0;
  line-height: 24px;
}

.csls-item p {
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #999;
}
.csls-item .tx-right-r {
  float: right;
  line-height: 22px;
  background: #fff3f3;
  color: #cf0505;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .tx-right-g {
  float: right;
  line-height: 22px;
  color: #999;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item h4 .tx-right-r,
.csls-item h4 .tx-right-g {
  font-weight: 300;
}
.csls-answer {
  background: #f1ffe8;
  padding: 10px;
  color: #5c9834;
  margin-top: 10px;
  border-radius: 0 15px 15px 15px;
  border: 1px solid #d2e8c3;
}
.csls-item .right-btn {
  float: right;
}
</style>

<style>
.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}
</style>
