<template>
  <nut-navbar
    :left-show="false"
    :title="$t('AGENT_SUPPORT_PLAN.H1')"
    fixed=true
    class="mb0">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>

  <div class="bd">

  <div class="h2">
    <strong>{{$t('AGENT_SUPPORT_PLAN.Event_time')}}: {{ periodTime }}</strong>
  </div>
  <div class="plan-main">

    <div class="p1">
      <div class="t">
        <span>{{normalRewardCoeff}}%</span>
        <span class="r">{{ $t('AGENT_SUPPORT_PLAN.Invitee_personal_recharge_5000_1') }} /<strong>{{ normalPCount }}</strong></span>
      </div>
      <div class="b">
          <div class="f1">x{{normalRewardCoeff}}%</div>
          <div class="f2"><span>{{ $t('AGENT_SUPPORT_PLAN.Commission') }}:</span><strong>{{ $filters.currencySymbol(normalRewardAmount) }}</strong></div>
          <div class="f3"><span>{{ $t('AGENT_SUPPORT_PLAN.Recharge_Amount') }}:</span><strong>{{ $filters.currencySymbol(normalRechargeAmount) }}</strong></div>
      </div>
      <div class="i"></div>
    </div>

    <div class="p1">
      <div class="t">
        <span>{{rrewardCoeff}}%</span>
        <span class="r">{{ $t('AGENT_SUPPORT_PLAN.Invitee_personal_recharge_5000_2') }} /<strong>{{ rpcount }}</strong></span>
      </div>
      <div class="b">
          <div class="f1">x{{rrewardCoeff}}%</div>
          <div class="f2"><span>{{ $t('AGENT_SUPPORT_PLAN.Commission') }}:</span><strong>{{ $filters.currencySymbol(rrewardAmount) }}</strong></div>
          <div class="f3"><span>{{ $t('AGENT_SUPPORT_PLAN.Recharge_Amount') }}:</span><strong>{{ $filters.currencySymbol(rrechargeAmount) }}</strong></div>
      </div>
      <div class="i"></div>
    </div>

    

    <div class="p6">
      <p>{{ $t('AGENT_SUPPORT_PLAN.text1') }}</p>
      <p><span>{{ $t('AGENT_SUPPORT_PLAN.Total') }}</span> {{ $t('AGENT_SUPPORT_PLAN.Commission') }}</p>
      <strong>{{ $filters.currencySymbol(totalRewardAmount) }}</strong>
      
      <button class="btn" type="button" v-if="status === 'wait_end'" @click="disabledClick">
        {{ $t('AGENT_SUPPORT_PLAN.Claim_Commission_after') }}
        <nut-countdown :endTime="countDown" millisecond format="HH:mm:ss" @on-end="countDownEnd"
        style="display: inline-block; font-weight: 600; color: #ffe269; font-size: 20px;"/>
      </button>
      <button class="btn" type="button" v-else-if="status === 'available'" @click="receiveClick">
        {{ $t('AGENT_SUPPORT_PLAN.Receive_Reward') }}
      </button>
      <button class="btn btn-disabled" type="button" v-else-if="status === 'received'">
        {{ $t('AGENT_SUPPORT_PLAN.received') }}
      </button>
      <button class="btn btn-disabled" type="button" v-else>
        {{ $t('AGENT_SUPPORT_PLAN.text4') }}
      </button>

    </div>
    <div class="tx-box">
      <p>{{$t('AGENT_SUPPORT_PLAN.INFO_TEXT')}}</p>
    </div>

    <h3 class="title">{{ $t('AGENT_SUPPORT_PLAN.Description') }}</h3>
    <div class="p3">
      <!-- <h4><strong>Rules Description</strong></h4> -->
      
      <p><i>1</i><span>{{ $t('AGENT_SUPPORT_PLAN.p1') }}</span></p>
      <p><i>2</i><span>{{ $t('AGENT_SUPPORT_PLAN.p2') }}</span></p>
      <p><i>3</i><span>{{ $t('AGENT_SUPPORT_PLAN.p3') }}</span></p>
      <p style="color:rgb(228, 83, 51); font-weight: 600;"><i>4</i><span>{{ $t('AGENT_SUPPORT_PLAN.p5') }}</span></p>
      <p style="color:rgb(228, 83, 51); font-weight: 600;"><i>5</i>{{ $t('AGENT_SUPPORT_PLAN.p0') }}</p>
    </div>

    <!--**************************************** 弹框 ****************************************-->
    <nut-popup pop-class="pop-reward-amount" v-model:visible="showPopup">
      <div class="bd">
        <p>{{ $t('AGENT_SUPPORT_PLAN.text2') }}</p>
        <strong>{{ $filters.currencySymbol(totalRewardAmount) }}</strong>
      </div>
      <nut-button type="primary" @click="showPopup = false">{{ $t('global.confirm') }}</nut-button>
    </nut-popup>

    <nut-popup pop-class="pop-reward-amount" :close-on-click-overlay="false"  v-model:visible="endedPopup">
      <div class="bd">
        <p>{{ $t('AGENT_SUPPORT_PLAN.text4') }}</p>
      </div>
      <nut-button type="primary" @click="endPopupClick">{{ $t('global.confirm') }}</nut-button>
    </nut-popup>

  </div>

  </div>
</template>

<script>
import axios from "axios";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import {Toast} from "@nutui/nutui";
export default {
  data() {
    return {
      commonInfo: '',
      imgUrl: '',
      showPopup: false,
      endedPopup: false,
      rpcount: 0,
      rrechargeAmount: 0,
      rrewardCoeff: 0,
      rrewardAmount: 0,
      normalPCount: 0,
      normalRechargeAmount: 0,
      normalRewardCoeff: 0,
      normalRewardAmount: 0,
      totalRewardAmount: 0,
      startTime:'',
      endTime:'',
      periodTime:'',
      countDown:0,
      status:'',
    }
  },
  created() {
    this.getInviterAssest()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.commonInfo = JSON.parse(localStorage.getItem('commonInfo'))
  },
  methods: {
    getInviterAssest(){
      Toast.loading('Loading...', {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
      axios({
        url: "/api/activity/recharge",
        method: "get"
      }).then((res) => {
        if(res.data.bizCode === 'ACTIVITY_NOT_AVAILABLE'){
          this.endedPopup = true
        }
        const d = res.data.data
        this.rpcount = d.rpcount
        this.rrechargeAmount = d.rrechargeAmount
        this.rrewardCoeff = d.rrewardCoeff
        this.rrewardAmount = d.rrewardAmount
        this.normalPCount = d.normalPCount
        this.normalRechargeAmount = d.normalRechargeAmount
        this.normalRewardCoeff = d.normalRewardCoeff
        this.normalRewardAmount = d.normalRewardAmount
        this.totalRewardAmount = d.totalRewardAmount
        this.status = d.status
        //this.endTime = d.endTime.slice(6,8) + '/' + d.endTime.slice(4,6) + '/' + d.endTime.slice(0,4) + ' ' + d.endTime.slice(8,10) + ':' + d.endTime.slice(10,12) + ':' + d.endTime.slice(12,14) 
        this.endTime = d.endTime.slice(0,4) + '-' + d.endTime.slice(4,6) + '-' + d.endTime.slice(6,8) + ' ' + d.endTime.slice(8,10) + ':' + d.endTime.slice(10,12) + ':' + d.endTime.slice(12,14) 
        this.countDown = Date.now() + (Date.parse(this.endTime) - Date.now())

        this.periodTime = d.startTime.slice(6,8) + '/' + d.startTime.slice(4,6) + '~' + d.endTime.slice(6,8) + '/' + d.endTime.slice(4,6)

        Toast.hide("loading")
      }).catch(function (error) {
        console.log(error);
        Toast.hide("loading");
      })
    },
    receiveClick(){
      if(this.status !== 'available') return
      axios
        .post(" /api/activity/receive-reward")
        .then((res) => {
          if (res.data.code === 0) {
            this.showPopup = true
            this.status = 'received'
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    disabledClick(){
      Toast.fail(this.$t('AGENT_SUPPORT_PLAN.text3'))
    },
    endPopupClick(){
      this.$router.push("/agentPromotion");
    },
    countDownEnd(){
      this.status = 'available'
    },
    getTodayDate() {
      const timestamp = Date.parse(new Date()) - 1000 * 60 * 60 *24;
      const date1 = new Date(timestamp);
      var Y = date1.getFullYear();
      var M = (date1.getMonth() + 1 < 10 ? '0' + (date1.getMonth() + 1) : date1.getMonth() + 1);
      var D = date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate();
      const t = Y + '-' + M + '-' + D;
      return t
    },
  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      router,
      state,
      ...methods,
    };
  }
};
</script>

<style scoped>
  .bd{ max-width: 600px; min-width: 340px; margin: 0 auto; }
  .h2{ text-align: center; margin: 0; padding: 0; 
    background:#3c0164  url(../assets/images/in/EN/S1/bg_p125.jpg) center bottom no-repeat;background-size:100% auto;
    margin: -5px 0 -1px 0; height: 150px; position: relative; }
  .br .h2{background:#3c0164  url(../assets/images/br/PT/S1/bg_p125.jpg) center bottom no-repeat;background-size:100% auto;}
  .h2 strong{ color: #f2bb3a; bottom: 5px; left: 20px; position: absolute; font-weight: 600; font-size: 14px;}
  .h2 img{ width:100%;}
  .plan-main{ max-width: 600px; background: #5a0e7f; padding: 0px 10px 20px 10px;margin:0px auto -10px auto; }

  .p1{padding:0px;border-radius:10px;  height: 145px; margin-bottom: 15px; position: relative; overflow: hidden;
     background:#fff url(../assets/images/bg149.png) center center no-repeat;background-size:100% auto; border: 0;}
  .p1 .t{ height: 40px; background: linear-gradient(315deg, #5a0e7f 0%, #ffd162 100%);border-radius:10px 10px 0 0;
     border-radius:10px 10px 0 0; }
  .p1 .t span{ line-height: 40px; color: #fff; padding-left: 35px;font-weight: 600; font-size: 24px;
    text-shadow: 0 2px #6c360b,1px 0 #6c360b}
  .p1 .t span.r{ font-size: 12px;color: #fff;float: right; margin-right: 10px;padding-left: 0px;
     line-height: 40px;height: 40px;  font-weight: 300;}
    .p1 .t span.r strong{color: #ff9b00;padding-left: 22px;background: url(../assets/images/img24.png) 0 center no-repeat;background-size:auto 18px; 
      line-height: 40px;height: 40px; display: inline-block;  margin-left: 5px; font-size: 20px;font-weight:600;}
  .p1 .b{ position: relative;}

  .p1 .f1{ font-size: 50px; line-height: 70px; z-index: 70;opacity: .3; position: absolute; right: 0px; top:50px;
     color: #c9b09b; font-weight: 600;}
  .p1 .f2{ z-index: 80; position: absolute;left: 10px; top:15px; }
  .p1 .f2 span, .p1 .f3 span{font-size: 14px;line-height: 20px; display: block; opacity: .8; color: #330947;}
  .p1 .f2 strong, .p1 .f3 strong{font-size: 22px;line-height: 40px; color: #d0560d; }
  .p1 .f2 strong{font-size: 38px;line-height: 50px;}

  .p1 .f3{ z-index: 80; position: absolute; right: 10px; top:15px; min-width: 160px; }

  .p1 .i{ position: absolute; top:3px; left:3px; width: 30px; height: 30px;
    background: url(../assets/images/bg194.png) center 2px no-repeat; background-size:90% auto;}
    .p1:first-child .t{ height: 40px;background: linear-gradient(315deg, #3c0d6c 0%, #d9d3f6 100%); }
  .p6{ text-align: center; padding: 20px 0 0 0; margin-top:-28px;}
  .p6 p{ color: #fff; margin: 0 0 2px 0; padding: 0; color: #f1c357;  line-height: 16px; padding: 10px; background: #4b0e6f;
  border-radius:5px ; }
  .p6 p span{ font-size: 20px;}
  .p6 p:first-child{opacity: .7;color: #fff; }
  .p6 strong{ height: 80px; line-height: 80px; font-size: 50px; color: #ffd162; display: block; 
  text-shadow: 0 3px #30004f, 3px 0 #30004f, 0px 0 #fff, 0 0px #fff; letter-spacing: 3px;} 
  .p6 .btn{line-height: 40px;text-align: center;color: #fff; background: #bc5000; border-radius: 40px; font-size: 16px;
    width: 90%; margin: 0px auto 0 auto;background: linear-gradient(315deg,#8b4602,#ffd162); border: 3px solid #4e0b71;}

  .p6 .btn-disabled{background: #b1b0b8;  opacity: .8;}


  .plan-main .f12{ font-size: 12px;}
  .plan-main .f18{ font-size: 18px;}
  .plan-main h3.title{ margin: 0; padding: 0 52px 0 15px; line-height: 32px; height: 25px; color: #5a0e7f; display: inline-block; 
    font-size: 14px; border-radius: 3px 0 0 0; opacity: .8;
    background:#fff url(../assets/images/bg150.png) right 0px no-repeat;background-size:44px auto;}
  .tx-box p{ color: #fff; opacity: .5; line-height: 14px; text-align:center; padding: 0 10px; font-size: 12px;}
  .plan-main h6{ text-align: center; color: #fff; margin: 10px 0 0px 0; opacity: .3; font-size: 14px; font-weight: 300;}




  .p1 h3{ text-align: center;font-size: 16px; margin: 10px 0 30px 0; }
  .p1 h3 strong{ border-bottom:2px solid #5a0e7f; padding-bottom: 6px;color: #5a0e7f;}




  .p2{ background: #fff; padding: 10px; border-radius:0px 10px 10px 10px; margin-bottom: 10px;}
  .p2 h4{ text-align: center;font-size: 12px; margin: 0 auto -1px auto; background: #cb783a; color: #fff;
    display: inline-block; border-radius: 5px 5px 0 0;  padding: 5px 0; width: 100%;
    background: linear-gradient(315deg, #5a0e7f 0%, #ffd162 100%);}
  .p2 h4 strong{  padding-bottom: 3px;color: #fff; font-weight: 300;}
  .p2 table{ border: 1px solid #d6c291; font-size: 12px;border-radius:5px; width: 100%; margin-bottom: 20px;}
  .p2 table tr{border: 1px solid #d6c291;}
  .p2 table th{border: 1px solid #d6c291; font-weight: 300; background: #fff; line-height: 14px; padding: 5px 0; color: #333; border-radius:5px;}
  .p2 table td{border: 1px solid #d6c291;line-height: 14px;text-align: center;background: #fff; border-radius:5px; padding: 5px; color:#333;}
  .p2 table .red{ color: #bc5000; font-weight: 600;}

  .p3{ background: #fff; padding: 10px; border-radius:0px 10px 10px 10px; margin-bottom: 10px; min-height: 100px; opacity: .8;margin-top: -1px; } 
  .p3 h4{ text-align: center;font-size: 14px; margin: 0 auto 1px auto; background: #ac0c72; color: #fff;
    display: inline-block; border-radius: 5px; padding: 5px 0; width: 100%;
    background: linear-gradient(315deg, #ac0c72 0%, #5a0e7f 100%);}
  .p3 h4 strong{  padding-bottom: 3px;color: #fff; font-weight: 300;}
  .p3 p{ margin:5px 0; color: #333; margin-bottom: 10px; padding-left: 25px;}
  .p3 i{ width: 16px; height: 16px; display: inline-block; background: #3c0164; color: #fff; border: 2px salmon solid; 
    line-height: 16px; text-align: center; border-radius: 50%; margin-right: 5px; margin-left: -25px;}
  .p2 table tr.myLv td{ background: #d7efb7; color: #436c0e;}
  .f-r{ float:right; line-height: 12px; color: rgb(249, 255, 202);  margin-right: 10px;font-size: 10px; margin-top: 0px; text-align: right;}
  .f-r span{opacity: .4; }
</style>
<style>
  .pop-reward-amount{ padding: 20px; text-align: center; border-radius: 10px;}
  .pop-reward-amount .bd{ background: #fff url(../assets/images/img25.png) center 23px no-repeat; background-size:160px auto;}
  .pop-reward-amount p{ font-size: 18px;}
  .pop-reward-amount strong{height: 80px; line-height: 80px; font-size: 50px; color: #d0560d; display: block; letter-spacing: 3px;}
</style>
