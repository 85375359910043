<template>
  <div>
    <nut-navbar
      :left-show="false"
      @on-click-icon="titIconClick"
      :title="$t('bankCard.h1')"
      titIcon="uploader"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>

    <div class="pt50">
      <div class="bank-card-ls" v-if="showData">

        <div class="bank-card" v-for="(item, index) in bankList" :key="index">
          <dl v-if="country === 'ph'">
            <dd style="font-size: 22px; margin-top: 57px;">{{ item.bankInfo.gCashMobile }}</dd>
          </dl>
          <dl v-else-if="country === 'br'">
            <dd v-show="item.bankInfo.cpf"><span>CPF:</span> {{ item.bankInfo.cpf }}</dd>
            <dd v-show="item.bankInfo.phone"><span>Phone:</span> {{ item.bankInfo.phone }}</dd>
            <dd v-show="item.bankInfo.email"><span>E-mail:</span> {{ item.bankInfo.email }}</dd>
            <dd v-show="item.bankInfo.cnpj"><span>CNPJ:</span> {{ item.bankInfo.cnpj }}</dd>
          </dl>
          <dl v-else>
            <dt>{{ item.bankInfo.bankName }}</dt>
            <dd>{{ item.bankInfo.realName }}</dd>
            <dd>{{ item.bankInfo.bankAccount }}</dd>
          </dl>
          <nut-button
            shape="square"
            plain
            type="info"
            size="mini"
            icon="edit"
            @click="editClick(item)"
          ></nut-button>
          <nut-button
            shape="square"
            plain
            type="info"
            size="mini"
            icon="del2"
            @click="dialogClick(item.id)"
          ></nut-button>
        </div>
      </div>

      <div class="app-empty" v-show="noData">
        <div class="app-empty-image">
          <img :src="require('../assets/images/' + imgUrl + 'bg07.png')"/>
        </div>
        <div>
          <p>{{$t('bankCard.noBankcCard')}}</p>
          <nut-button icon="add" type="info" block @click="addBankCardClick">{{$t('bankCard.addBankcCard')}}</nut-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import { Dialog } from '@nutui/nutui';
import { getCountry } from "@/assets/js/environment";

export default {
  data() {
    return {
      country: '',
      imgUrl: '',
      showData: false,
      noData: false,
      bankList: [],
    };
  },
  methods: {
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
    dialogClick(id) {
      Dialog({
        content: this.$t('bankCard.delete'),
        okText: this.$t('global.okText'),
        cancelText: this.$t('global.cancelText'),
        onOk: () => {
          this.delBankCard(id)
        },
        onCancel: () => {
        }
      });
    },

    delBankCard(delid) {
      delid = parseInt(delid);
      axios({
        url: "/api/user/bank/delete",
        method: "post",
        params: { id: delid }
      }).then((res) => {
        Toast.success("success!");
        this.$router.go(0);
        console.log(res);
      });
    },
    editClick(item) {
      if(this.country === 'ph'){
        this.$router.push({
          path: 'addBankCard',
          query: {
            id: item.id,
            gCashMobile: item.bankInfo.gCashMobile,
          }
        })
      }else if(this.country === 'br'){
        this.$router.push({
          path: 'addBankCard',
          query: {
            id: item.id,
            realName: item.bankInfo.realName,
            cpf: item.bankInfo.cpf,
            phone: item.bankInfo.phone,
            email: item.bankInfo.email,
            cnpj: item.bankInfo.cnpj
          }
        })
      }else{
        this.$router.push({
          path: 'addBankCard',
          query: {
            id: item.id,
            realName: item.bankInfo.realName,
            ifscCode: item.bankInfo.ifscCode,
            bankName: item.bankInfo.bankName,
            bankAccount: item.bankInfo.bankAccount,
            upiVpa: item.bankInfo.upiVpa,
            email: item.bankInfo.email
          }
        })
      }
    },
    async getBankList() {
      this.textToast(this.$t('global.loading'));
      const res = await axios.get("/api/user/bank/list");
      this.bankList = res.data.data;
      if (this.bankList.length > 0) {
        this.noData = false;
        this.showData = true;
      }else{
        this.noData = true;
      }
      Toast.hide("loading");
    },
  },
  created() {
    this.country = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.getBankList();
  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const backClick = () => {
      router.push("./myHome");
    };
    const titIconClick = () => {
      router.push({
        name: "addBankCard",
      });
    };
    const addBankCardClick = () => {
      router.push({
        name: "addBankCard",
      });
    };

    onMounted(() => {
    });
    return {
      ...toRefs(state),
      addBankCardClick,
      backClick,
      titIconClick,
    };
  },
};
</script>

<style scoped>
.bank-card .nut-button--square {
  position: absolute;
  right: 34px;
  bottom: 15px;
  background: transparent !important;
  color: #fff !important;
  border: 0 !important;
  opacity: 0.7;
}
.bank-card .nut-button--square:last-child {
  right: 4px;
}
.bank-card dd span{ color:#fff; opacity: .5;}
</style>