import {math} from "@/main";
import {isBlank} from "@/assets/js/commonUtil";

export function add(n1, n2, scale) {
    if (isBlank(scale)) {
        scale = 2;
    }
    return math.format(
        math.add(
            math.bignumber(n1),
            math.bignumber(n2)
        ),
        (value) => value.toFixed(scale));
}

export function subtract(n1, n2, scale) {
    if (isBlank(scale)) {
        scale = 2;
    }
    return math.format(
        math.subtract(
            math.bignumber(n1),
            math.bignumber(n2)
        ),
        (value) => value.toFixed(scale));
}

export function multiply(n1, n2, scale) {
    if (isBlank(scale)) {
        scale = 2;
    }
    return math.format(
        math.multiply(
            math.bignumber(n1),
            math.bignumber(n2)
        ),
        (value) => value.toFixed(scale));
}

export function divide(n1, n2, scale) {
    if (isBlank(scale)) {
        scale = 2;
    }
    return math.format(
        math.divide(
            math.bignumber(n1),
            math.bignumber(n2)
        ),
        (value) => value.toFixed(scale));
}
