<template>
  <div>

  </div>

</template>

<script>
import {reactive, toRefs} from "vue";
import {useRouter} from "vue-router";
import {Toast} from "@nutui/nutui";
import {isNotBlank} from "@/assets/js/commonUtil";
import {commonEvent, createSocket, disconnectSocket, initGame} from "@/assets/js/game";

export default {
  name: "testGame",
  components: {},
  data() {
    return {
      //event
      eventEnum: {
        initRoom: "s_wl_init_room", //初始化房间
        startBet: "s_wl_start_bet", //开始下注阶段
        startRotate: "s_wl_start_rotate", //开始下注阶段
        startWait: "s_wl_start_wait", //开始等待阶段
        end: "s_wl_end", //本局结束
        playerInfo: "s_wl_player", //当前用户的玩家信息
        pause: "s_wl_pause", //游戏暂停
        betNotify: "s_wl_bet", //任意玩家的下注通知
        settle: "s_wl_settle", //结算信息
      },
      //游戏阶段
      stageEnum: {
        bet: "bet",
        rotate: "rotate",
        wait: "wait",
        pause: "pause"
      },
    };
  },
  created() {
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
  },
  setup() {
    const state = reactive({
      tabbarActiveIndex: 2,
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      telegramClick() {
        const commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
        window.open(commonInfo.telegramChannelUrl, "_self");
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },

  // ======================================== mounted ========================================
  mounted() {
    /**
     * 初始化接口
     */
    Toast.loading('Loading...', {
      id: "loading",
      duration: 0,
      bgColor: "rgba(0, 0, 0, 0.9)",
      cover: true,
    });
    initGame('dice').then((response) => {
      const data = response.data.data;
      Toast.hide("loading");
      //游客id
      if (isNotBlank(data.vtoken)) {
        localStorage.setItem("vtoken", data.vtoken);
      }
      //初始化socket（依赖房间信息）
      this.initSocket();
    });
  },

  // ======================================== beforeRouteLeave ========================================
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket('dice', this.socket);
  },

  // ======================================== methods ========================================
  methods: {
    //转化时间
    convertTime(timeSerial) {
      return timeSerial.substring(0, 2) + ':' + timeSerial.substring(2, 4)
    },

    // ======================================== websocket ========================================
    initSocket() {
      if (this.socket != null) {
        return;
      }

      //创建
      this.socket = createSocket('dice', null, null);

      /**
       * 就绪
       */
      this.socket.on(commonEvent.ready, () => {
        this.socket.emit(commonEvent.join);
      });
    },
    socketChangeRoom() {
      if (this.socket === null) {
        return;
      }
      this.socket.emit(commonEvent.join);
    },
  },
};
</script>
