<template>
  <div class="pop-list">
    <nut-elevator :index-list="dataList" :height="460" @click-item="clickItem"></nut-elevator>
  </div> 
</template>
<script>
import { reactive, toRefs } from "vue";
export default {
  name: 'BankListVN',
  data() {
    return {
      acceptKey: 'num',
      bankInfo:{
        name: '',
        id: '',
      },
      dataList: [
        {
          title: 'A',
          list: [
            {
              name: 'ABBANK',
              id: 'ABB'
            },
            {
              name: 'AGRIBANK',
              id: 'AGR'
            },
          ]
        },
        {
          title: 'B',
          list: [
            {
              name: 'BAC A BANK',
              id: 'BAB'
            },
            {
              name: 'BIDV BANK',
              id: 'BIDV'
            },
            {
              name: 'BAO VIET BANK',
              id: 'BVB'
            },
          ]
        },
        {
          title: 'C',
          list: [
            {
              name: 'CBBANK',
              id: 'CBBANK'
            },
          ]
        },
        {
          title: 'D',
          list: [
            {
              name: 'DBS',
              id: 'DBS'
            }
          ]
        },
        {
          title: 'E',
          list: [
            {
              name: 'EXIMBANK',
              id: 'EIB'
            },          
          ]
        },
        {
          title: 'G',
          list: [
            {
              name: 'GP BANK',
              id: 'GPB'
            },
          ]
        },
        {
          title: 'H',
          list: [
            {
              name: 'HD BANK',
              id: 'HDB'
            },
            {
              name: 'HONGLEONG BANK',
              id: 'HLB'
            },
            {
              name: 'HSBC',
              id: 'HSBC'
            },
          ]
        },
        {
          title: 'I',
          list: [
            {
              name: 'INDOVINA BANK',
              id: 'IVB'
            },
          ]
        },
        {
          title: 'K',
          list: [
            {
              name: 'KIENLONGBANK',
              id: 'KLB'
            },
          ]
        },
        {
          title: 'L',
          list: [
            {
              name: 'LIENVIET BANK',
              id: 'LVB'
            },
          ]
        },
        {
          title: 'M',
          list: [
            {
              name: 'MBBANK',
              id: 'MB'
            },
            {
              name: 'MARITIME BANK',
              id: 'MSB'
            },
          ]
        },
        {
          title: 'N',
          list: [
            {
              name: 'NGAN HANG A CHAU',
              id: 'ACB'
            },
            {
              name: 'NH MTV CIMB',
              id: 'CIMB'
            },
            {
              name: 'Ngân hàng TMCP Đông Á',
              id: 'DAB'
            },
            {
              name: 'NAMA BANK',
              id: 'NAB'
            },
            {
              name: 'NH TMCP QUOC DAN',
              id: 'NCB'
            },
            {
              name: 'Ngân hàng TMCP Việt Á',
              id: 'VAB'
            },
            {
              name: 'NHTMCP BAN VIET',
              id: 'VCAPB'
            },
            {
              name: 'NH LD VIET NGA',
              id: 'VRB'
            },
            {
              name: 'Ngân hàng số VPDirect',
              id: 'YOLO'
            },
          ]
        },
        {
          title: 'O',
          list: [
            {
              name: 'OCEANBANK',
              id: 'OJB'
            },
            
          ]
        },
        {
          title: 'P',
          list: [
            {
              name: 'PHUONGDONG BANK"',
              id: 'OCB'
            },
            {
              name: 'PGBANK',
              id: 'PGB'
            },
          ]
        },
        {
          title: 'S',
          list: [
            {
              name: 'SCB',
              id: 'SCB'
            },
            {
              name: 'SEABANK',
              id: 'SEAB'
            },
            {
              name: 'SAIGONBANK',
              id: 'SGB'
            },

            {
              name: 'SHB BANK',
              id: 'SHB'
            },
            {
              name: 'SHINHAN BANK VN',
              id: 'SHBVN'
            },
            {
              name: 'SACOMBANK',
              id: 'STB'
            },
          ]
        },
        {
          title: 'T',
          list: [
            {
              name: 'TECHCOMBANK',
              id: 'TCB'
            },
            {
              name: 'TIENPHONG BANK',
              id: 'TPB'
            },
          ]
        },

        {
          title: 'U',
          list: [
            {
              name: 'UNITED OVERSEAS BANK',
              id: 'UOB'
            },
          ]
        },
        {
          title: 'V',
          list: [
            {
              name: 'VIETINBANK',
              id: 'CTG'
            },
            {
              name: 'VIDPublic Bank',
              id: 'PBVN'
            },
            {
              name: 'VIETCOMBANK',
              id: 'VCB'
            },
            {
              name: 'VIB BANK',
              id: 'VIB'
            },
            {
              name: 'VIETBANK',
              id: 'VIETB'
            },
            {
              name: 'VPBANK',
              id: 'VPB'
            },
          ]
        },
        {
          title: 'W',
          list: [
            {
              name: 'WOORI BANK',
              id: 'WOO'
            },
          ]
        }
      ]
    };
  },
  methods: {
    clickItem(key, item) {
      this.bankInfo.name = item.name
      this.bankInfo.id = item.id
      this.$emit("getDataVN", this.bankInfo);
    },
  },
  setup() {
    const state = reactive({
    });
    return { ...toRefs(state)};
  }
};
</script>
<style scoped>
</style>
<style>
.pop-list { padding: 10px !important; text-align: left; height: 461px;}
.nut-elevator__bars__inner__item{ padding: 3px 5px !important;}
.nut-elevator__list__item__code:after{    height: 2px !important;background-color: rgb(79, 11, 138) !important;}
</style>ß