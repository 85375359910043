<template>
<div>
  <nut-navbar
    :left-show="false"
    :title="$t('agentGrowthPlus.h1')"
    fixed=true
    class="mb0 navbar-red"
  >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>
  

  <div class="agentList">
    <img class="img-w100" src="../assets/images/bg318.jpg" />
    <div style="  bottom: 4px; width: 100%; font-weight: 600; font-size: 15px; text-align: center; color: #fff; ">Event end time: <strong>15-05-2024  00:00</strong></div>
    <div><nut-countdown :endTime="deadline" millisecond format="HH:mm:ss"/></div>
    <h2>{{$t('agentGrowthPlus.h2')}}</h2>
    <p class="p-info">
      {{$t('agentGrowthPlus.titile')}}
      <span>
        {{$t('agentGrowthPlus.tx1')}}
        <br/>
        {{$t('agentGrowthPlus.tx2')}}
      </span>
    </p>

    
    <div class="agentList-item" v-for="(item, index) in entries" :key="index"  :taskId="item.taskId" :taskType="item.taskType">
      <div class="item-t lv1">
          <h3>{{item.entryTitle}}</h3>
          <span class="l">{{index+1}}</span>
          <span class="r" v-if="vipLevel > index+1">{{$t('taskReward.received')}}</span>
          <span class="r" v-if="vipLevel <= index+1">+{{ $filters.currencySymbol(item.rewardAmount) }}</span>
      </div>
      <div class="item-b" >
        <div v-if="vipLevel === index+1">
          <!--<h4>{{item.taskTitle}} <span class="r">{{$t('agentGrowth.target')}}:{{ item.conditionValue }}</span></h4 -->
          <h4>{{$t('agentGrowthPlus.h4_1')}}</h4>
          <p>{{$t('agentGrowthPlus.h4_item1_1')}} <strong>{{processing.conditionValue}}</strong> {{$t('agentGrowthPlus.h4_item1_2')}}</p>
          <h4>{{$t('agentGrowthPlus.h4_2')}}</h4>
          <p>{{$t('agentGrowthPlus.h4_item2_1')}}<strong>{{processing.conditionValue}}</strong>{{$t('agentGrowthPlus.h4_item2_2')}} <strong>{{ $filters.currencySymbol(processing.rewardAmount) }}</strong> {{$t('agentGrowthPlus.h4_item2_3')}}</p>
          <nut-progress
              :percentage="processing.progress"
              size="small"
              :stroke-color="bgc"
              style="margin:10px 0;width: 106%;"
            />
          <nut-button v-if="processing.status === 'processing'" block type="info" @click="goToTaskUrl(processing.conditionType)">{{$t('taskReward.completeTask')}}</nut-button>
          <nut-button v-if="processing.status === 'wait_receive'" block type="info" @click="receiveAward(processing.taskId, processing.serialNum ,processing.rewardAmount)">{{$t('taskReward.receiveReward')}}</nut-button>
          <nut-button v-if="processing.status === 'received'" block type="info" disabled=ture>{{$t('taskReward.received')}}</nut-button>
        </div>
      </div>
    </div>

    <nut-backtop></nut-backtop>
  </div>
  <nut-popup
      position="top"
      :z-index="100"
      :style="{ height: '160px', background:'#fff' }"
      @closed="topPopclose" 
      v-model:visible="taskPop"
    >
      <div class="pop-top">
        <h4>{{$t('taskReward.getBn')}}</h4>
        <p>
          <strong>+</strong><strong>{{ $filters.currencySymbol(taskAmount) }}</strong>
        </p>
      </div>
  </nut-popup>

</div>
</template>

<script>
import { reactive,toRefs,onMounted,getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import { getLastTimeGameName } from "@/assets/js/environment";

export default {
  data(){
    return{
      imgUrl:'',
      i18n:{
        loading: this.$t('global.loading'),
      }
    }
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      vipLevel:'',
      entries:[],
      processing:[],
      bgc: "#3d2505",
      taskPop: false,
      taskAmount: 0,
      startFlag: false,
      maxNum: '',
      maxRewardAmount: '',
      deadline: null,
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      receiveAward(id,num, amount){
        axios({
          url: "/api/task/receive-reward",
          method: "post",
          params: { taskId: id, serialNum: num }
        })
        .then((res) => {
          if (res.data.code === 2000) {
            Toast.fail(res.data.msg);
          } else {
            this.taskReceivePop(amount);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      taskReceivePop(a) {
        state.taskAmount = a;
        state.startFlag = true;
        state.taskPop = true;
        setTimeout(() => {
          state.startFlag = false;
          state.taskPop = false;
          location.reload()
        }, 1500);
      },

      goToTaskUrl(type) {
        switch (type) {
          case "first_recharge":
            router.push({ name: "rechargeBalance" }); //首次充值
            break;
          case "recharge_times":
            router.push({ name: "rechargeBalance" }); //充值次数达标
            break;
          case "recharge_amount":
            router.push({ name: "rechargeBalance" }); //充值金额达标
            break;
          case "first_invite":
            router.push({ name: "agentPromotion" }); //首次邀请
            break;
          case "invite_count":
            router.push({ name: "agentPromotion" }); //邀请人数达标
            break;
          case "enough_recharge_p_count":
            router.push({ name: "agentPromotion" }); //足额充值人数达标
            break;
          case "first_withdraw":       //首次提现
            router.push({
              path: 'withdrawalBalance',
              query: {
                amountType: 'cash'
              }
            })
            break;
          case "first_effective_invite":
            router.push({ name: "agentPromotion" }); 
            break;
          case "effective_invite_count":
            router.push({ name: "agentPromotion" }); 
            break;
          default:
            router.push({ path: '/'+ getLastTimeGameName() });
        }
      }
    };

    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      axios.get("/api/task/agent-invitee-recharge", {}).then(function (res) {
          state.entries = res.data.data.entries
          state.processing = res.data.data.processing
          state.vipLevel = res.data.data.processing.serialNum
          state.maxNum = res.data.data.entries.length
          state.maxRewardAmount = res.data.data.entries[state.maxNum -1].rewardAmount
          Toast.hide("loading");
        }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
        state.deadline = Date.now() + ((new Date(2024,4,15,0,0,0).getTime()) - new Date().getTime());

    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.agentList {padding: 40px 10px; padding-bottom: 20px; background: #000000;min-height: 700px; margin-top: 6px;}
.agentList h2{ margin: 10px; line-height: 30px; color: #d8ae4d; text-align: center;}
.p-info{ padding: 0; line-height: 18px; color: #fff0b5; margin-top:0px; margin-bottom: 20px; text-align: center; }
.p-info strong{ font-size: 20px; }
.p-info span{ display: block;font-size:12px;background: #4a3910; border: 1px solid #7f621d; margin-top:10px; border-radius: 3px; color: #fff; line-height: 18px; padding: 3px 0;}
.img-w100{ width:100% ;}
.navbar-red, .navbar-red .nut-navbar{
   background:linear-gradient(315deg, #231101 0%, #6f3c01 100%) !important;  box-shadow: 0 1px 7px #271000 !important;
  }
.nut-navbar__left .nut-icon { color: #ffc800 !important;}
.agentList-item{ margin-bottom: 8px;}
.agentList-item .item-t{ height: 60px; border: 1px solid #b59e7e; background: #fff9e0; border-radius:10px; position: relative;box-shadow: 0 1px 3px #753c00 }
.agentList-item .item-t.lv1{background:#fff9e0 url(../assets/images/bg17-3.png) 5px center no-repeat; background-size:45px; }
.agentList-item .item-t h3{margin:0px 0 0 60px; font-weight: 300;padding-right: 67px; line-height: 26px; padding-top: 19px;}
.agentList-item .item-t span.r{ position: absolute; right: 10px; top:0; line-height: 60px;color: #e17800; font-size: 22px; font-weight: 600;}
.agentList-item .item-t span.l{ position: absolute; left: 13px; top:0; line-height: 68px; color: #ffee00; font-size: 16px;width: 30px;text-align: center;}
.agentList-item .item-b{ background: #fff;border-radius:0 0 10px 10px;  margin:-10px 5px 0 5px;box-shadow: 0 1px 7px #753c00; padding: 20px 10px 10px 10px; }
.agentList-item .item-b h4{ padding: 5px 0px; margin: 5px 0; line-height: 20px;}
.agentList-item .item-b h4 .r{ float:right; font-weight: 400; }
.agentList-item .item-b p{padding: 0; margin: 5px 0; line-height: 20px;}

.nut-popup.popup-top{background:#ffe26f !important}
.popup-top {
  overflow: hidden;
}
.pop-top {
  background: url(../assets/images/bg80.jpg) -10px 40px no-repeat;
  height: 165px;
  background-size: 161px auto;
}
.pop-top h4 {
  padding-left: 130px;
  padding-top: 25px;
  margin-bottom: 0;
  font-size: 18px;
}
.pop-top p {
  padding-left: 130px;
  padding-top: 0;
  margin: 0;
}
.pop-top p strong {
  font-size: 36px;
  color: #940e05;
  font-weight: 500;
}

.nut-countdown{color: #fff; text-align: center; line-height: 30px; font-size: 30px; width: 100%; display: block;}
.nut-countdown .nut-cd-block{ width: 100%;}
</style>

<style>
</style>
