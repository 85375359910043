<template>
  <div class="login userLogin">
    <div class="login-bn">
      <h2>&nbsp;</h2>
    </div>
    <div class="login-bd">
      <h3>{{ $t('userLogin.signIn') }}</h3>
      <SetLanguage></SetLanguage>
      
      <div class="login-inp">
        <p class="fs10 fc-999">{{ $t('userLogin.mobileNumber')}}</p>
        <p class="err-info" v-show="errText.mobile">{{errText.mobile}}</p>
        <div v-if="country === 'in'" class="nation">+91</div>
        <div v-if="country === 'ph'" class="nation">+63</div>
        <div v-if="country === 'br'" class="nation">+55</div>
        <div v-if="country === 'vn'" class="nation">+84</div>
        <login-input
          label=""
          :placeholder="$t('userLogin.mobileNumberPlaceholder')"
          type="number"
          rule="^.{1,18}$"
          class="pl40"
          autocomplete="off"
          @inputChange="(res) => (mobile = res)"
        />
      </div>
      <div class="login-inp">
        <p class="fs10 fc-999">{{ $t('userLogin.password')}}</p>
        <p class="err-info" v-show="errText.password">{{errText.password}}</p>
        <span class="f-r" @click="forgotPasswordClick">{{ $t('userLogin.forgotPassword')}}</span>
        <login-input
          label=""
          :placeholder="$t('userLogin.passwordPlaceholder')"
          type="password"
          rule="^.{1,32}$"
          maxLength="32"
          autocomplete="off"
          @inputChange="(res) => (password = res)"
        />
      </div>
      <div class="login-btn">
        <nut-button block type="info" @click="loginSubmit">{{ $t('userLogin.login')}}</nut-button>
      </div>
      <nut-row>
        <nut-col :span="24">
          <div class="flex-content" style="text-align: center;">
            <span class="a-lk" @click="registerClick">{{ $t('userLogin.register')}}</span>
          </div>
        </nut-col>
        <!-- <nut-col :span="12">
          <div class="flex-content ta-right">
            <span class="a-lk" @click="userLoginClick">{{ $t('userLogin.SMSLogin')}}</span>
          </div>
        </nut-col> -->
      </nut-row>
    </div>

    <div class="tg-btn" v-if="country === 'in' && template === 's1'" @click="toTelegram">
        <img src="../assets/images/bg312.png" />
    </div>

    <div class="tg-btn" v-else-if="country === 'br' && template === 's1'" @click="toTelegram">
        <img src="../assets/images/bg313.png"  />
    </div>

    <div class="login-tx">
      {{ $t('global.safeDays1')}} <strong>{{ state.runningDays }}</strong> {{ $t('global.safeDays2')}}
    </div>
    <img style="width:96%;margin-left: 3%;" :src="require('../assets/images/' + imgUrl + 'bg145.jpg')"/>

  </div>
</template>

<script>
import { reactive,onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
import axios from "axios";
import loginInput from "@/components/loginInput.vue";
import SetLanguage from "@/components/SetLanguage.vue";
import { isBlank, delSpace } from "@/assets/js/commonUtil";
import { getLastTimeGameName, getCountry} from "@/assets/js/environment";
import {eventTrackingLogin} from "@/assets/js/eventTracking";

export default {
  name: "userLogin",
  components: {
    loginInput: loginInput,
    SetLanguage: SetLanguage
  },
  data() {
    return{
      country: '',
      template: '',
      commonInfo: '',
      imgUrl: '',
      mobile: "",
      password: "",
      inviteCode: null,
      shareCode: null,
      shareBonusReceiveCode: "",
      amount: "",
      errText:{
        mobile: "",
        password: ""
      }
    };
  },
  created() {
    this.country = getCountry();
    this.template = document.querySelector('body').getAttribute('s')
    this.imgUrl = localStorage.getItem('countryImgUrl')
    if(!isBlank(localStorage.getItem("invite"))) {
      this.inviteCode = localStorage.getItem("invite")
    }
    if(!isBlank(localStorage.getItem("shareBonusCode"))) {
      this.shareCode = localStorage.getItem("shareBonusCode")
    }
  },
  methods: {

    //async registerSubmit() {
    loginSubmit() {
      this.errText.mobile = ""
      this.errText.password = ""
      if(this.mobile === ""){
        this.errText.mobile = this.$t('global.errInfoRequired')
        return
      }
      if(this.password === ""){
        this.errText.password = this.$t('global.errInfoRequired')
        return
      }
      Toast.loading(this.$t('global.loading'), {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
      });
      this.mobile = delSpace(this.mobile)
      this.password = delSpace(this.password)
      axios
      .post("/api/user/login/password", {
        mobile: this.mobile,
        password: this.password,
        recallInviteCode: this.inviteCode,
        recallShareBonusCode: this.shareCode
      })
      .then((response) => {
        if (response.data.code === 0) {
          //埋点
          eventTrackingLogin(response.data.data.inviteCode, response.data.data.invokeFb);

          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("hasPassword", response.data.data.hasPassword);
          localStorage.removeItem("invite");
            if (!isBlank(this.shareCode)) {
              if(parseInt(localStorage.getItem("needVip")) > 0){
                //this.$router.push("receiveBonus?c=" + this.shareCode);
                window.location.href = "receiveBonus?c=" + this.shareCode

              }else{
                axios
                .post("/api/user/share-bonus/receive", {
                  shareBonusCode: this.shareCode,
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    this.shareBonusReceiveCode = res.data.data.shareBonusReceiveCode;
                    this.amount = res.data.data.shareBonusAmount;
                    localStorage.setItem("shareBonusReceiveCode",this.shareBonusReceiveCode);
                    localStorage.setItem("shareBonusAmount", this.amount);
                    localStorage.removeItem("shareBonusCode");
                    setTimeout(() => {
                      this.$router.push({
                        path: "/shareBonus",
                        query: {
                          amount: this.amount,
                        },
                      });
                    }, 1000);
                  } else {
                    if (res.data.msg != undefined) {
                      Toast.fail(res.data.msg);
                      this.errMsg = res.data.msg;
                    } else {
                      Toast.fail("error!");
                    }
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
              }

            } else {
              setTimeout(() => {
                this.$router.push({ path: '/'+ getLastTimeGameName() });
              }, 1000);
            }

            Toast.hide("loading");
            Toast.success(this.$t('userLogin.loginSuccess'));
            
        }else if (response.data.code === 1004){
          Toast.hide("loading");
          let res = response.data.data
          for(var i in res){	
            switch (res[i].fieldName) {
              case "mobile":
                this.errText.mobile = res[i].description
                break;
              case "password":
                this.errText.password = res[i].description
                break;
            }
          }
        }else{
          Toast.hide("loading");
        }
      })
      .catch(function (error) {
        console.log(error);
        Toast.hide("loading");
      });
    },
  },

  setup() {
    const state = reactive({
      country: '',
      runningDays: '',
      commonInfo: ''
    });
    const router = useRouter();
    const userLoginClick = () => {
      router.push({
        name: "userSMSLogin",
      });
    };
    const registerClick = () => {
      router.push({
        name: "userRegister",
      });
    };
    const forgotPasswordClick = () => {
      router.push({
        name: "forgotPassword",
      });
    };
    const methods = {
      toTelegram(){
        window.open(state.commonInfo.telegramChannelUrl,"_self");
      },
    };
    onMounted(() => {
      state.country = getCountry();
      if(window.localStorage.getItem('commonInfo')){
        state.commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
        state.runningDays = state.commonInfo.runningDays
      }else{
        axios.get("/api/system/common", {}).then((response)=> {
          state.commonInfo = response.data.data
          state.runningDays = response.data.data.runningDays
        })
      }
    })
    return {
      state,
      ...methods,
      userLoginClick,
      registerClick,
      forgotPasswordClick
    };
  },
};
</script>

<style scoped>
.login-inp{ position: relative;}
.login-inp .pl40{ margin-left: 66px; width: auto !important;}
.login-inp .f-r{ position: absolute;top:40px; right:0px;z-index: 300; font-size: 12px; color:#3d2505;}

.login-bd {
  padding: 20px;
  background: #fff;
  margin: 20px;
  box-shadow: 0px 0px 5px #d9d2f4;
  border-radius: 10px;
}
.login-bd h3 {
  margin: 10px 0 30px 0;
  font-size: 20px;
  color: #333;
  font-weight: 400;
  text-align: center;
}
.login-bd .nut-input {
  padding: 10px 0 10px 0px;
}
.login-bd .nut-input .input-text {
  padding: 0 35px 0 0px;
}
.a-lk {
  font-size: 12px;
  color: #3d2505;
  display: block;
  line-height: 14px;
}

.login-btn {
  margin: 30px 0 10px 0;
}
.login-tx {
  padding: 20px 0;
  color: #809c54;
  text-align: center;
  background: #f1f7e8;
}
.login-tx strong {
  font-size: 20px;
  border: 1px solid #bac6a7;
  box-shadow: 0px 0px 3px #d9d2f4;
  margin-right: 2px;
  padding: 2px 4px;
  border-radius: 4px;
  background: url(../assets/images/bg06.png) center center no-repeat;
}
.login-bn {
  padding: 88px 20px 20px 20px;
}
.login-bn h2 {
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: center;
  color: #efe7f4;
}

</style>
