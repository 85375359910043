<template>
  <div>
    <nut-navbar
      :left-show="false"
      :title="$t('CS.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed">
      <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>


    <div class="login">
      <div class="login-bn">&nbsp;</div>
      <div class="login-bd">
        <h3>{{$t('CS.h2')}}</h3>

        <div class="login-inp">
          <p class="fs10 fc-999">{{$t('CS.type')}}</p>
          <nut-input
            :placeholder="$t('CS.typePlaceholder')"
            readonly="readonly"
            v-model="typeInpText"
            @click="selectType"
          />
          <!--
        <nut-cell
          title="Select type"
          :desc="desc"
          @click="
            () => {
              show = true;
            }
          "
        ></nut-cell>
        <nut-picker
          v-model="selectedValue"
          v-model:visible="show"
          columns="columns" 
          cancel-text="close"
          ok-text="OK"
          title="Select type"
          @change="change"
          @confirm="confirm"
          @close="close"
        >
        </nut-picker>
-->
        </div>
        <div class="login-inp">
          <p class="fs10 fc-999">{{$t('CS.title')}}</p>
          <login-input
            label=""
            :placeholder="$t('CS.titlePlaceholder')"
            type="text"
            rule="^.{4,50}$"
            maxLength="50"
            @inputChange="(res) => (titleInpText = res)"
          />
        </div>

        <div class="login-inp">
          <p class="fs10 fc-999">{{$t('CS.description')}}</p>
          <nut-textarea
            :placeholder="$t('CS.descriptionPlaceholder')"
            v-model="textdata"
          />
        </div>

        <div class="login-btn">
          <nut-button block type="info" @click="cancelClick">{{$t('global.confirm')}}</nut-button>
        </div>
        <p class="fc-999 fs10" style="text-align: center;">
          {{$t('CS.servicTime')}}
        </p>
        <p class="fc-999 fs10" style="text-align: center;">
          {{$t('CS.servicTime2')}}
        </p>
      </div>
    </div>
    <nut-popup
      position="bottom"
      closeable
      round
      :style="{ height: '30%' }"
      v-model:visible="showRound"
    >
      <ul class="typeul">
        <li @click="selectLi('Recharge', 'recharge')">{{$t('CS.recharge')}}</li>
        <li @click="selectLi('Withdraw', 'withdraw')">{{$t('CS.withdraw')}}</li>
        <li @click="selectLi('Invite', 'invite')">{{$t('CS.invite')}}</li>
        <li @click="selectLi('Game Crash', 'game_crash')">{{$t('CS.gameCrash')}}</li>
        <li @click="selectLi('Others', 'others')">{{$t('CS.others')}}</li>
      </ul>
    </nut-popup>
  </div>
</template>

  
<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import loginInput from "@/components/loginInput.vue";
import axios from "axios";
import { Toast } from "@nutui/nutui";

export default {
  components: {
    loginInput,
  },
  data() {
    return {};
  },
  setup() {
    const state = reactive({
      showRound: false,
      typeInpVal: "",
      typeInpText: "",
      titleInpText: "",
      textdata: "",
      columns: [
        { text: "recharge", value: "recharge" },
        { text: "withdraw", value: "withdraw" },
        { text: "invite", value: "invite" },
        { text: "game_crash", value: "game_crash" },
        { text: "others", value: "others" },
      ],
    });

    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      selectType() {
        state.showRound = true;
      },
      selectLi(v, i) {
        state.typeInpVal = i;
        state.typeInpText = v;
        state.showRound = false;
      },
      cancelClick() {
        if (
          state.typeInpVal != "" &&
          state.titleInpText != "" &&
          state.textdata != ""
        ) {
          //const res = await axios
          axios
            .post("/api/system/ticket/create", {
              "type": state.typeInpVal,
              "title": state.titleInpText,
              "content": state.textdata
            })
            .then((response) => {
              if (response.data.code === 0) {
                Toast.success("success!");
                setTimeout(() => {
                  router.push("/complaintsSuggestions");
                }, 1000);
              } else {
                if (response.data.msg != undefined) {
                  Toast.fail(response.data.msg);
                }
              }
            })
            .catch(function (error) {
              console.log(error);
              Toast.fail(this.$t('global.networkError'));
            });
        } else {
          Toast.fail(this.$t('global.inputRequired'));
        }
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.login {
  background: url(../assets/images/bg15_1225c.png) 0 -50px no-repeat;
  background-size: 100% auto;
}
.login-bd {
  padding: 20px;
  background: #fff;
  margin: 20px;
  box-shadow: 0px 0px 5px #d9d2f4;
  border-radius: 10px;
}
.nut-textarea {
  padding: 0;
  height: 60px;
  border-bottom: 1px solid #eaf0fb;
}
.login-bd h3 {
  margin: 10px 0 30px 0;
  font-size: 20px;
  color: #333;
  font-weight: 400;
  text-align: center;
}
.login-bd .nut-input {
  padding: 10px 0 10px 0px;
}
.nut-cell {
  padding: 10px 0 10px 0px;
  box-shadow: none;
  border-bottom: 1px solid #eaf0fb;
}

.login-btn {
  margin: 30px 0 10px 0;
}
.login-bn {
  padding: 20px 20px 0px 20px;
}
.login-bn h2 {
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: center;
  color: #efe7f4;
}
.otpbtn {
  float: right;
}
.nut-input-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.w100 .input-text {
  width: 100% !important;
}
.typeul {
  text-align: center;
  padding: 20px;
}
.typeul li {
  line-height: 38px;
}
</style>

<style>
.nut-input .input-text {
  padding: 0 0px 0 0px !important;
  width: 60% !important;
}
</style>