
// import {isBlank, isNotBlank} from "@/assets/js/commonUtil";

/**
 * 生成机器人
 * @returns {string}
 */
export function createRobot(c,n) {
    let list = []
    for(var i = 0; i < n; i ++){
        let m = ''
        if(c === 'in'){
            m = '900****0000'
        }else if(c === 'br'){
            m = '700****0000'
        }else{
            m = '000****0000'
        }
        list.unshift({
            avatar: Math.round(Math.random()*8) + 1,
            mobile: m,
            betAmount: 100,
            escapeRatio: 5,
            escapeAmount: 50,
        });
    }
    return list
}
/**
 * 机器人下注
 * @returns {string}
 */
export function robotBet() {

}
/**
 * 机器人逃跑
 * @returns {string}
 */
export function robotEscape() {

}

/**
 * 生成概率结果
 * @param s //规则数组[50%>1-2;50%>3-4]; [50%>1;50%>2]
 * @returns {string}
 */
export function createOutcome(s) {
    let arr = [];
    let z = 1;
    for( let _v of s.split(';')){
        let _a = []
        for(let ii = 1; ii <= Number(_v.substring(0,_v.indexOf('%'))); ii++, z++){
            _a.push(z)
        }
        arr.push({a : _a, v : _v.substring(_v.indexOf('>')+1, _v.length)}) 
    }
    let n = Math.floor(Math.random() * (100 - 1)) + 1
    for( let _v of arr){
        if(_v.a.includes(n) && _v.v.indexOf('-') >= 0){
            let _n = Number(_v.v.substring(0,_v.v.indexOf('-')));
            let _m = Number(_v.v.substring(_v.v.indexOf('-')+1,_v.v.length));
            return (Math.random() * (_n - _m) + _m).toFixed(2)
        }else if(_v.a.includes(n) && _v.v.indexOf('-') < 0){
            return _v.v
        }
    }
}
/**
 * 生成历史记录
 * @param l //历史列表
 * @param n //新的结果
 * @returns {Array}
 */
export function gameHistoryList(l,n) {
    let z = 5;  //最大数量
    let arr = l;
    for(let i = arr.length; i < z; i++){
        arr.push(Number((Math.random() * (1 - 99) + 99).toFixed(2)));
    }
    if(n){
        arr.pop();
        arr.unshift(n);
    }
    return arr
}
/**
 * 生成房间号
 * @returns {Number}
 */
function roomNumber() {
    let n = Number((Math.random() * (1 - 100) + 100))
    return (Array(length).join('0') + n).slice( - 4 )
}

/**
 * 初始化设置
 */
export function initRoom() {
    let initData = {
        roomNumber: roomNumber(), //生成房间号
    }
    return initData
}