<template>
  <div></div>
</template>

<script>
import {Toast} from "@nutui/nutui";
import {isNotBlank} from "@/assets/js/commonUtil";
import {commonEvent, createSocket, disconnectSocket, gameType, initGame} from "@/assets/js/game";

export default {
  name: "gameDemo",
  components: {},
  data() {
  },
  created() {
  },
  setup() {
  },
  // ======================================== mounted ========================================
  mounted() {
    Toast.loading('Loading...', {
      id: "loading",
      duration: 0,
      bgColor: "rgba(0, 0, 0, 0.9)",
      cover: true,
    });
    /**
     * 初始化接口
     */
    initGame('andar').then((response) => {
      const data = response.data.data;
      Toast.hide("loading");
      //游客id
      if (isNotBlank(data.vtoken)) {
        localStorage.setItem("vtoken", data.vtoken);
      }
      //初始化socket
      this.initSocket();

    })
  },

  // ======================================== beforeRouteLeave ========================================
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket(gameType.WHEEL, this.socket);
  },

  // ======================================== methods ========================================
  methods: {
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
    // ======================================== websocket ========================================
    initSocket() {
      if (this.socket != null) {
        return;
      }
      // 创建
      this.socket = createSocket('andar', null, null);
      // 就绪
      this.socket.on(commonEvent.ready, () => {
        this.socket.emit(commonEvent.join);
      });
    },
  },
};
</script>

<style scoped src="../assets/css/game_wheel.css"></style>
<style scoped>
</style>
<style>
.wheel-body .canvas-container {
  margin: 0 auto !important;
}
</style>



