<template>
  <div class="play-rule">
    <h4><nut-icon name="tips" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>{{$t('gameRulesAndar.h1')}}</h4>
    <dl>
      <dt>{{$t('gameRulesAndar.dt1')}}</dt>
      <dd>{{$t('gameRulesAndar.dd1')}}</dd>

      <dt>{{$t('gameRulesAndar.dt2')}}</dt>
      <dd>{{$t('gameRulesAndar.dd21')}}</dd>
      <dd>{{$t('gameRulesAndar.dd22')}}</dd>
      <dd>{{$t('gameRulesAndar.dd23')}}</dd>
      <dd>{{$t('gameRulesAndar.dd24')}}</dd>
      <dd>{{$t('gameRulesAndar.dd25')}}</dd>

      <dt>{{$t('gameRulesAndar.dt3')}}</dt>
      <dd>{{$t('gameRulesAndar.dd31')}}</dd>
      <dd>&nbsp;</dd>
      <dd>{{$t('gameRulesAndar.dd33')}}</dd>
      <dd>{{$t('gameRulesAndar.dd34')}}</dd>
      <dd>&nbsp;</dd>
      <dd>{{$t('gameRulesAndar.dd35')}}</dd>
      <dd>&nbsp;</dd>
      <dd>{{$t('gameRulesAndar.dd36')}}</dd>
      <dd>{{$t('gameRulesAndar.dd37')}}</dd>
    </dl>

  </div> 
</template>

<script>
import { getCountry } from "@/assets/js/environment";

export default {
  name: 'GameRulesMine',
  props: {},
  data() {
    return {
      imgUrl: '',
      country: '',
    };
  },
  methods: {
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.country = getCountry()
  },
  setup() {}
};
</script>

<style scoped>
.popclass-pp {
  padding: 15px 5px 10px 15px !important;
  border-radius: 5px;
  width: 70%;
}
.popclass-pp h4 {
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 16px;
  margin: 0 !important;
  border-bottom: 1px solid #eee;
}
.popclass-pp .txbd {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.play-rule {
  padding: 10px;
}

.play-rule h4 {
  margin: 5px 0;
  font-size: 17px;
}
.nut-popup h4.h4 {
  margin: 20px 10px;
}
.play-rule dl {
  position: absolute;
  overflow-y: scroll;
  top: 50px;
  bottom: 20px; right: 10px; left: 10px;
}

.play-rule dt {
  margin: 20px 0; padding-left: 10px;  line-height: 20px;
  font-size: 14px; font-weight: 400; border-left:4px solid #3d2505 ;
}

.play-rule dd {
  margin:0px;
}
.play-rule dd.img {
  margin:0px;
}
.play-rule dd img{
  width: 100%;
}
.play-rule dd strong{ color: #3d2505;}
.play-rule .nut-button{ margin-right: 20px; width: auto !important;}
</style>
