<template>
  <div class="transactionsHistoryList">
    <nut-navbar
    :left-show="false"
      :title="$t('transactions.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>
    
    <div class="pt50 scroll-body">
      <div class="p20" style="padding:10px 5px !important">

          <div class="type-title">
            <span :class="listType === 'coin' ? 'a' : ''" @click="typeClick('coin')">{{$t('withdrawal.gameAccount')}}</span>
            <span :class="listType === 'cash' ? 'a' : ''" @click="typeClick('cash')">{{$t('withdrawal.agentAmount')}}</span>
          </div>

          <div class="tabs-nav">
            <ul>
              <li v-for="(item) in queryLimitEnum" :key="item.code" :class="item.active ? 'active' : ''" @click="tabsQueryLimit(item)">{{ item.description }}</li>
            </ul>
          </div>

          <ListEmpty v-show="listNoData"></ListEmpty>
          
          <div class="csls" id="scroll">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div
                class="csls-item"
                v-for="(item, index) in list"
                :key="index"
              >
                <h4>
                  <span :class="[item.income ? 'fc-green' : 'fc-red']"
                    >{{ item.income ? "+" : "-" }} {{ $filters.currencySymbol(item.changeAmount) }}</span
                  ><span :class="[item.income ? 'tx-right-g' : 'tx-right-r']"
                    >{{ item.preAmount }} >> {{ item.postAmount }}</span
                  >
                </h4>
                <div class="bd">{{ item.name }}</div>
                <p>{{ item.createTime }}</p>
              </div>
            </nut-infiniteloading>
          </div>

      </div>
    </div>

  </div>

    <!-- <nut-popup pop-class="pop-system" v-model:visible="systemNotification">
      <div class="hd">System Notification</div>
      <div class="bd">
        <p>
          The system has detected unusual invitation behavior for the following users on August 10,and the corresponding commission will be deducted automatically.
        </p>
        <ul class="sn-ul">
          <li>890****192 198invalid users;</li>
          <li>636****968 182invalid users;</li>
          <li>887****847 117invalid users;</li>
          <li>870****296 101invalid users;</li>
          <li>700****712 81invalid users;</li>
          <li>930****680 58invalid users;</li>
          <li>934****583 43invalid users;</li>
          <li>914****135 36invalid users;</li>
          <li>988****771 33invalid users;</li>
        </ul>
        <p>
          Please correct your invitation behavior, Yoswin will continue to provide you with more opportunities to make money.
          If you have any objection to the result, please contact the customer service email via <span>support@yoswin.com</span></p>
      </div>
      <div class="btn">
        <nut-button type="primary" @click="systemNotificationClick">{{ $t('game.gotitBtn') }}</nut-button>
      </div>
    </nut-popup> -->


</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"
//import { getCountry } from "@/assets/js/environment";

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      queryLimit:'today',
      queryLimitEnum:[
        {"code":"today","description": this.$t('global.Today'),"active":true},
        {"code":"one_week","description": this.$t('global.Week'),"active":false},
        {"code":"one_month","description": this.$t('global.Month1'),"active":false},
        {"code":"three_month","description": this.$t('global.Month3'),"active":false}],
      list: [],
      listType: 'coin',
      listNoData: true,
      current: 1,
      hasNext:'',
      systemNotification: false
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    tabsQueryLimit(li) {
      for(let k in this.queryLimitEnum){
        this.queryLimitEnum[k].active = false
      }
      li.active = true
      this.queryLimit = li.code
      this.list = []
      this.getCoinList(1);
    },
    typeClick(t){
      this.listType = t
      this.list = []
      this.getCoinList(1);
    },
    getCoinList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/fund/ledger/paginate",
        method: "get",
        params: { current: c, type: this.listType, queryLimit: this.queryLimit},
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }else{
          this.listNoData = true;
        }
        Toast.hide("loading")
      });
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
    // systemNotificationClick(){
    //   this.systemNotification = false
    //   localStorage.setItem("systemNotificationHaveRead",true);
    // }
  },
  created() {
    if(this.$route.query.listType){
      this.listType = this.$route.query.listType;
    }
    this.getCoinList(1);
    // if(getCountry() === 'in' && !localStorage.getItem("systemNotificationHaveRead")){
    //   const inviteCodeList = [ 'VNVXT7','CLK35L','T3N7FD','GWZPGK','FNLXK6','SNZ8NN','BPNUMC','SY6AM6','QYZMBW','WHTC3U' ]
    //   const myInviteCode = localStorage.getItem("inviteCode")
    //   for (let k in inviteCodeList) {
    //     if(myInviteCode === inviteCodeList[k]){
    //       this.systemNotification = true
    //     }
    //   }
    // }
  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.scroll-body{
  position: fixed;
  top:0;
  left: 0;
  right:0;
  bottom: 0;
  overflow: auto
}
.nut-pagination {
  margin-top: 10px;
  justify-content: center;
}
.csls{
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 10px;right: 10px;top: 140px; bottom: 10px;
}
.csls-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.csls-item h4 {
  margin: 5px 0;
  line-height: 24px;
}

.csls-item p {
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #999;
}
.csls-item .tx-right-r {
  float: right;
  line-height: 22px;
  background: #fff3f3;
  color: #cf0505;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .tx-right-g {
  float: right;
  line-height: 22px;
  background: #f1ffe8;
  color: #5c9834;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item h4 .tx-right-r,
.csls-item h4 .tx-right-g {
  font-weight: 300;
}
.csls-answer {
  background: #f1ffe8;
  padding: 10px;
  color: #5c9834;
  margin-top: 10px;
  border-radius: 0 15px 15px 15px;
  border: 1px solid #d2e8c3;
}
.csls-item .right-btn {
  float: right;
}
.type-title{ padding: 0 0 10px 0; text-align: center;}
.type-title span{ margin: 0 10px; line-height: 30px; height: 30px;  border: 1px solid #3d2505; color: #3d2505; border-radius:5px; padding:0 10px; display: inline-block;}
.type-title span.a{color: #fff; background: #3d2505;}
</style>

<style>
.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}
</style>