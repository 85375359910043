<template>

  <div class="app-empty" v-show="listNoData">
    <div class="app-empty-image">
      <img :src="require('../assets/images/' + imgUrl + 'bg07b.png')"/>
    </div>
    <div>
      <p>{{$t('global.dataListInfo')}}</p>
    </div>
  </div>

</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  name: 'ListEmpty',
  data() {
    return {
      imgUrl: ''
    };
  },
  methods: {
  },  
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
  },
  setup() {
    const state = reactive({});
    return {
      ...toRefs(state)
    };
  }

};
</script>

<style scoped>
</style>
<style>
</style>