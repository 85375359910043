<template>
  <nut-tabbar v-model:visible="active"  :bottom="true" :safeAreaInsetBottom="true" :unactiveColor="unactiveColor" :activeColor="activeColor">
    <!-- @tab-switch="tabSwitch"  --> 
    
    <nut-tabbar-item :tab-title="$t('global.recharge')" to="./rechargeBalance"  :img="tabbarImg" :activeImg="tabbarActiveImg"></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.invite')"   to="./agentPromotion"  icon="link"  ></nut-tabbar-item>

    <nut-tabbar-item tab-title=" " :img="lobbyBtn" :activeImg="lobbyBtn" @click="lobbyBtnClick" class="show-list"></nut-tabbar-item>

    <nut-tabbar-item :tab-title="$t('global.reward')"   to="./taskReward"      icon="date"  ></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.my')"       to="./myHome"          icon="my" ></nut-tabbar-item>
    
  </nut-tabbar>
</template>
<script>
  import { reactive, toRefs, onMounted } from "vue";
  import {useRouter} from "vue-router";
  export default{
    props:[
      'activeIndex'
    ],
    data() {
      return {};
    },
    created() {},
    methods: {
    },
    setup(props) {
      const data = reactive({
        commonInfo: '',
        homeUrl: '',
        unactiveColor:"#ad9b86",
        activeColor:"#7b4300",
        tabbarImg: require('../assets/images/bg03.png'),
        tabbarActiveImg: require('../assets/images/bg03-a.png'),
        notImg: require("../assets/images/bg04.png"),
        lobbyBtn: require('../assets/images/' + localStorage.getItem("countryImgUrl") + 'bg76b.png'),
        active: props.activeIndex,
      });
      const router = useRouter();
      const methods = {
        goToGame(n){
          router.push({name: n});
        },
        lobbyBtnClick(){
          if(data.commonInfo.games.length > 1){
            router.push({name: 'gameLobby'});
          }else{
            router.push({path: data.homeUrl});
          }
        },
      }
      onMounted(() => {
        data.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"))
        data.homeUrl = '/' + data.commonInfo.games[0].name
      })
      return {
        ...toRefs(data),
        ...methods,
       // tabSwitch,
      };
    },
  }
  
</script>

<style scoped>
  .nut-tabbar{
    padding-top: 11px;
    padding-bottom: 0px;
    border-top:0;
    background: url(../assets/images/bg01.png) center top no-repeat;
    height: 67px !important;
  }
  .nut-tabbar-bottom.nut-tabbar-safebottom{
    /*height: 67px !important; _1225 */
    /* height: 63px !important; */
    height: 67px !important;
  }
  .nut-tabbar-item{color: #3d2505; padding-top: 10px;}
  .show-list{
    margin-top: -27px;
    background: none;
    background-size: 60px;
    width: 75px; min-width: 75px;
    height:75px;
    padding: 0 6px 0 3px;
  }

</style>
<style>
  .show-list .nut-tabbar-item_icon-box .nut-tabbar-item_icon-box_icon{ width: 69px !important; height: 69px !important;}
</style>
