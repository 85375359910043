//国际化
import { createI18n } from 'vue-i18n'
import { getDefaultLanguage } from "@/assets/js/environment";
const language = getDefaultLanguage()
const i18n = createI18n({
  locale: language,		//默认
    messages: {
      in_EN:require('./en/in_EN.js'),
      in_HI:require('./hi/in_HI.js'),
      ph_EN:require('./en/ph_EN.js'),
      br_PT:require('./pt/br_PT.js'),
      vn_VI:require('./vi/vn_VI.js'),
    }
  })
  export default i18n
