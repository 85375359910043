//葡萄牙语
module.exports = {
  // 枚举
  filtersText:{
    wait_audit: 'Wait Audit',
    audit_accept: 'Audit Accept',
    audit_reject: 'Audit Reject',
    processing: 'Processing',
    success: 'Success',
    failed: 'Failed',
  },
  // 通用
  global:{
    language: 'Idioma',
    recharge: 'Recarga',   
    invite: 'Convide',    
    reward: 'Missões',    
    my: 'Meu',             
    myWin: 'Meu vitória',
    loading: "Carregamento...",                          
    okText: "OK",                                        
    cancelText: "Cancelar",                                  
    safeDays1: 'Tem operado com segurança por',            
    safeDays2: 'dias seguros',
    dataListInfo :'Mostrar dados somente dentro de 3 meses!',          
    noMore:'Não mais!',
    noData:'Sem dados!',
    inputRequired:'Favor preencher os campos obrigatórios!',
    errInfoRequired:'Isto é necessário',
    mobileError: 'Formato incorreto do número móvel!',
    passwordsError: 'Senhas diferentes duas vezes!',
    agreeError: 'Favor concordar com o acordo de registro',
    successfully_OPT: 'Com sucesso',
    successfully: 'Com sucesso',
    errorfully: 'Número de celular errado!',
    networkError:'Anomalia de rede',
    eMail:'E-mail de feedback: ',
    WithdrawalPartners:'Parceiros de pagamento/retirada abaixo.',
    submittedSuccess:'Apresentado com sucesso!',
    confirm:'Confirme',
    submit:'Enviar',
    save:'Salvar',
    view:'Visão', 
    downloadApp: 'Download do aplicativo',
    addDesktop: 'Adicionar à área de trabalho',
    addDesktopTitle: 'Para sua conveniência, é recomendável adicionar nossa página à área de trabalho',
    addDesktopText1a: '1、Clique',
    addDesktopText1b: 'ícone',
    addDesktopText2: '2、Selecione "Adicionar à área de trabalho" ',
    rechargeBtnText:'Recarga',
    withdrawalBtnText:'Retirada',
    inviteBtnText:'Convide',
    slogan1: '100% Secure&Real, Sem trapaças!',
    Today: 'Hoje',
    Week: '1 semana', 
    Month1: '1 mês',
    Month3: '3 mês', 
    Your_invitation_link_has_been_copied: 'O link do seu convite foi copiado!',
    hotGame: 'Jogo quente',
    top10: 'Jogadores Top 10',
    NON_VIP_CAN_ONLY_INPUT: 'Não VIP só pode apostar',
    INVALID_INPUT_VALUE: 'Valor de entrada inválido.',
    REQUEST_FAILED_PLEASE_TRY_LATER_OR_CONTACT_US: 'A solicitação falhou. Tente mais tarde ou entre em contato conosco.',
    SERVER_ERROR: 'Erro no servidor. Por favor, tente mais tarde ou entre em contato conosco.',
    goWin: 'Continuar a vencer',
    SIGN_UP_BONUS_1: 'Sign Up To Get ', //Sign Up To Get ** Bonus >>
    SIGN_UP_BONUS_2: ' Bonus >>',
    SIGN_UP_BONUS_3: 'Cadastre-se para obter um cupom de desconto ',
    ALREADY_HAVE_AN_ACCOUNT: 'Já tem uma conta? ', //Já tem uma conta? Iniciar sessão

    RECEBER_CUPOM_DE_DESCONTO_DE: 'Receber cupom de desconto de ',
    OBTER_CUPOM: 'Obter cupom',
    DISCOUNT_COUPON: 'Cupom de desconto',
    VALID: 'válido',
    COUPON: 'Coupon',
    EXPIRED: 'Expired',
    USAR: 'Utilização',
    FUN_MODE: 'Modo Diversão',
    RECIBO: 'Recibo',
    PAY: 'Pagar',
    FUN_MODE_i: 'A quantidade do modo diversão é uma quantidade virtual, escolha o modo real para iniciar a experiência de jogo real',

    VIP_BONUS_1: 'Este bônus está disponível apenas para usuários VIP',
    VIP_BONUS_2: '+, por favor, atualize seu nível VIP para recebê-lo.',
    DEMO_END_TEXT: 'Sua oportunidade de experimentar hoje foi usada, vá para o modo real para começar a ganhar de verdade.',
    TO_REAL_MODE: 'To real mode',
    Prediction_Channel: 'Prediction Channel',
    Prediction_Channel_Text: 'The prediction channel is only open to VIP1+ users, please upgrade your VIP level.',
    Got_It: 'Got It',
    Upgrade: 'Upgrade',

    Recharge_Dialog_Title: 'equilíbrio não é suficiente',
    Recharge_Dialog_Text: 'Você pode depositar ou entrar no modo divertido para experimentar o jogo',
    Recharge_Dialog_Btn: 'jogar',

    Received:'Recebido',
    Total:'totais',
    Lucky_Lifafa:'Lucky Lifafa',
    Easy_share_Earn_Rupees:'Compartilhamento fácil, ganhe bônus',
    Remaining_today: 'Restante hoje',
    pieces:'peças',
    Play_now:'Jogue agora',

    DOWNLOAD_APP: 'Baixar o APP',
    DOWNLOAD_APP_TEXT:'Para uma melhor experiência de jogo, receba recompensas mais rapidamente e obtenha informações valiosas',
    DOWNLOAD_APP_TEXT2: 'Baixe o aplicativo agora para reivindicar o bônus ',
    DOWNLOAD_APP_TEXT3: '!',
    DOWNLOAD_APP_TEXT4: 'Acesse o APP para receber o prêmio!',
    DOWNLOAD_APP_BTN: 'Baixar',
    DOWNLOAD_APP_CANCEL: 'Cancelar',
    
    Through_Email1: 'Se houver algum problema, entre em contato conosco por e-mail: support',
    Through_Email2: '101xclub.com, horário de trabalho: 7:00-20:00',

    downloadPopup_h3: 'New update available',
    downloadPopup_p: "Please download the latest app from Google Play.",
    downloadPopup_later: 'Update later'
  },
  // 飞机游戏页
  game:{
    h1: 'Crash',
    tips: 'Dicas',
    gamePaused: 'Jogo Pausado', 
    paused: 'Pausa',
    playTime: 'Tempo de jogo', 
    gameWillStartingIn: 'O jogo vai começar em',
    youWin: 'Ganha',
    receiveBonus: 'Receber Bônus',
    nextGame: 'Próxima rodada',  
    all: 'TODOS',
    start: 'Início',
    stop: 'Parada',
    autoStop: 'Parada automática',  
    myBet: 'Meu Ordem',
    players: 'Jogadores', 
    myHistory: 'Meu Ordem',
    winRatio: 'Parada',   
    winAmount: 'Ganhe',
    betAmount: 'aposta',
    last20: 'Mostrar apenas os últimos 20 jogadores que aderiram!',
    stopPlayer: 'escapar',
    bet: 'aposta',
    status: 'estado',
    ratio: 'Parada',
    feeAmount: 'Horário',
    escape: 'Fuga',
    more: 'Mostrar pedidos apenas hoje, clique para ver mais',
    noHistory: 'Sem histórico de apostas',
    historData:'Dados do histórico do jogo',
    notEnough: 'a quantidade de sua carteira não é suficiente',
    pleaseWithdraw: 'Para sua segurança financeira, por favor, retire.',
    //pleaseWithdraw2:'Parabéns pela vitória! Para sua segurança financeira, por favor, retire.',
    pleaseWithdraw1:'Parabéns!!',

    //pleaseWithdraw2:'Você fez mais de  ',
    // pleaseWithdraw_200:'R$100',
    //pleaseWithdraw3:'antes de continuar a jogar,',
    //pleaseWithdraw4:'por favor, retire para garantir ',
    //pleaseWithdraw5:'que você pode receber dinheiro com sucesso.',

    pleaseWithdraw2:'Você fez mais de  ',
    // pleaseWithdraw_200:'R$100',
    pleaseWithdraw3:'por favor,',
    pleaseWithdraw4:'retire para garantir ',
    pleaseWithdraw5:'que você pode receber dinheiro com sucesso.',


    go: 'Vá!',
    gotitBtn: 'Entendi',
    info1: 'Convide um amigo',
    info2: 'Bônus inicial de reinício para ',
    waitForTheNextRound: 'Aguarde a próxima rodada!',
    info3: 'O valor de entrada Vip0 só pode ser ',
    DialogTitle: 'Parabéns pela grande vitória!',
    DialogContent1: 'Para vip',
    DialogContent2: ', sua taxa máxima de parada é ',
    DialogContent3: 'x, você pode nivelar o vip para desbloquear o limite.',
  },
  // 红绿游戏页
  PARITY:{
    H1: 'PARITY', 
    NAME1: 'Russell',
    NAME2: 'Mozgov',
    NAME3: 'Emma',
    NAME1_s2: 'Lucas',
    NAME2_s2: 'Bernardo',
    NAME3_s2: 'Julia',
    PLEASE_WAIT: 'Por favor, aguarde...',
    ROUND: 'Rodada', 
    NEXT_ROUND_PREDICTION_STSRT_AT: 'A previsão da próxima rodada começa em',
    THIS_PREDICTION_STARTS_AT: 'Esta previsão começa em',
    LIVE_PREDICTION: 'PREVISÃO AO VIVO',
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_1: 'A Previsão AO VIVO está agora disponível na ',
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_2: 'sala de Previsão ',
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_3: '!',
    MY_CHOICE: 'Minha escolha',
    COUNT_DOWN: 'Contagem regressiva',
    SELECT_TIME: 'Escolha o tempo',
    RESULT_TIME2: 'Verificando', //'Esperar',
    PAUSED_TIME: 'Pausa',
    MY_POINT: 'Minha aposta',
    VIP: 'Vip',
    JOIN_GREEN: 'Selecione Verde',
    JOIN_VIOLET: 'Selecione Violet',
    JOIN_RED: 'Selecione Red',
    JOIN_NUMBER: 'Selecione número',
    RECORD: 'Registro',
    CONTINUOUS: 'Contínuo',
    PROBABILITY: 'Probabilidade',
    NEXT_PAGE: 'Próxima página',
    UPGRADE: 'Atualização',
    EVERYONE_ORDER: "Ordem de todos",
    MY_ORDER: 'Meu Ordem',
    BALANCE: 'Balanço',
    FEE: 'Taxas',
    JOIN: 'Junte-se',
    CONTRACT_POINT: 'Valor do contrato',
    ALL: 'TODOS',
    NUMBER: 'Número',
    VIP1_INPUT_FREELY: 'Vip1+ entrada livre',
    VIP0_ROOKIE_GUIDE: "Guia de Iniciantes 'Vip0",
    FOLLOW_ADVICE_AND_YOU_WIN_FINALLY: "Siga os conselhos e você finalmente ganhará!",
    INPUT: 'Aposta',
    LOSE: 'FALHA',
    WIN: 'Ganhe',
    SO_THIS_ROUND_SHOULD_INPUT: 'Portanto, esta rodada deve apostar',
    CONFIRM: 'Confirme',
    TOTAL_PROFIT: 'Lucro total',
    PROFIT: 'Lucro',
    USER: 'Usuário',
    SELECT: 'Escolha',
    POINT: 'Aposta',
    PERIOD: 'Período',
    RESULT: 'Resultado',
    AMOUNT: 'Ganhe',
    RESULT_TIME:'Horário',
    BTC_PRICE: 'BTC Preço',
    SERIAL_NUM: 'Número de série',
    NEW: 'Nova',
    SUGGEST: 'Sugerir',
    PREDICT: 'Prever',
    SERIAL: 'Serial',
    PROFIT_WIN_INPUT :'Lucro (Ganhe-aposta)',
    RESULTS_ORIGIN: 'Origem dos resultados',
    PREDICTION_GAMEPLAY: 'Jogo de Previsão',
    INVESTMENT_SKILLS: 'Habilidades de investimento',
    ANALYST_INTRODUCTION: 'Introdução do Analista',
    RESULTS_ORIGIN_TEXT_1: 'Os resultados vêm do último dígito do índice composto das 10 principais moedas digitais da Coinbase.',
    PREDICTION_GAMEPLAY_TEXT_1: 'A plataforma fornece resultados de previsão calculados por analistas cooperativos 7 vezes ao dia, 7-15 rodadas por previsão, para referência.',
    PREDICTION_GAMEPLAY_TEXT_2: 'Tempo de abertura da previsão:',
    PREDICTION_GAMEPLAY_TEXT_3: 'Estratégia de investimento eficiente combinada com previsões dos analistas pode aumentar as chances de vencer no longo prazo.',
    PREDICTION_GAMEPLAY_TEXT_4: '(Os resultados das previsões são apenas para referência, a precisão não é garantida)',
    INVESTMENT_SKILLS_TEXT_1: 'Tanto a taxa de ganho quanto a estratégia de investimento são importantes.',
    INVESTMENT_SKILLS_TEXT_2: '1.Estratégia de investimento',
    INVESTMENT_SKILLS_TEXT_3: 'Investimento simples strategy【aposta dupla】: Se você perder esta rodada, para a próxima rodada, duplique o valor investido até que você ganhe.',
    INVESTMENT_SKILLS_TEXT_4: 'Por exemplo',
    INVESTMENT_SKILLS_TEXT_5: '2.Seguir e Opostos',
    INVESTMENT_SKILLS_TEXT_6: 'Aprenda a observar "contínuo", quando o mesmo resultado aparece em uma fila, escolha o resultado oposto, e a taxa de ganho é muitas vezes maior.',
    INVESTMENT_SKILLS_TEXT_7: 'Mais dicas no canal oficial de telegramas>>',
    INITIAL_BALANCE: 'Balanço inicial',
    INVESTMENT_PLAN: 'Plano de investimento',
    TOTAL_ASSETS: 'Ativos totais',
    ANALYST_INTRODUCTION_1: 'Formado em Economia pela Universidade Estadual de Michigan, especialista em blockchain, mais de 8 anos de experiência em investimentos em moedas criptográficas.',
    ANALYST_INTRODUCTION_2: 'Mestre em Matemática pela Universidade Estadual de Moscou, excelente talento matemático, bom na análise de tendências de dados.',
    ANALYST_INTRODUCTION_3: 'Analista financeiro, formado em Economia pela Universidade de Paris, estudando a aplicação da teoria econômica à prática.',
    ANALYST_INTRODUCTION_1_s2: 'Formado pelo Massachusetts Institute of Technology, com talento matemático excepcional, tem trabalhado no campo da análise de dados de Wall Street por mais de 10 anos.',
    ANALYST_INTRODUCTION_2_s2: 'Anos de experiência em investimentos em blockchain, UCLA Master of Financial Mathematics, uma vez trabalhou no departamento comercial de Mt.Gox.',
    ANALYST_INTRODUCTION_3_s2: 'Bacharel em Economia Financeira pela Faculdade Imperial de Tecnologia, com forte sensibilidade digital, o retorno do investimento chega a 300%.',
    CONGRSTS_XXX_WIN_XXX_1: 'Parabéns ',
    CONGRSTS_XXX_WIN_XXX_2: 'Ganhe ',
    GAME_WILL_COMING_SOON: 'O jogo virá em breve!',
    ROOM_NOT_EXISTS: 'O espaço não existe!!',
    PLEASE_CONFIRM_YOUR_INPUT: 'Por favor, confirme  sua aposta!',
    AS_A_ROOKIE_YOU_CAN_ONLY_INPUT: 'Como um novato, você só pode apostar ',
    ONLY_FOR_VIP1: 'Somente para VIP1+',
    ONLY_FOR_VIP2: 'Somente para VIP2+',
    CONNECTING: 'conectando',
    OOPS_PLEASE_CHECK_YOUR_NETWORK: 'Oops! Por favor, verifique sua rede',
    TRY_AGAIN:'Tente novamente',
    BTC_PRICE_BY_BINANCE: 'Preço BTC por Binance',
  },
  //转盘
  WHEEL:{
    H1: 'WHEEL',
    HISTORY: 'Histórico',
    BET_TIME: 'Escolha o tempo',
    PLAYING:'Jogando',
    PLEASE_WAIT: 'aguarde...',
    CLICK_TO_BET: 'Clique para apostar',
    VIP_ONLY: 'Sólo VIP1+',
    RULE_H1: 'Wheel Rule',
    RULE_T1: '1.Como jogar ',
    RULE_T2: 'os jogadores podem selecionar a cor da mesa giratória,selecione primeiro o valor da aposta, depois a cor.',
    RULE_T3: '2.ganhar',
    RULE_T4: 'As probabilidades de azul e amarelo são 2X, aposta 100, ganho 200.',
    RULE_T5: 'As probabilidades de vermelho são 9X, aposta 100, ganho 900.',
  },
  // 挖雷游戏页
  MINE:{
    H1: 'MINE',
    ROW:'Fileira',
    START: 'Início',
    BONUS: 'Bônus',
    NEXT: 'Próximo',
    STOP: 'Parada',
    POINT: 'Aposta',
    SELECT: 'Selecione',
    WIN_AMOUNT: 'Valor da vitória',
    ORDER_NUM: 'Ordem número',
    STOP_AND_GET_BONUS: 'Parar e receber bônus!',
    CHOOSE_GAME_AMOUNT: 'Escolha a quantidade do jogo',
  },
  // 登录页
  userLogin: {
    signIn: 'Login',
    smsSignIn: 'Login por SMS',
    mobileNumber: 'Número de celular',
    mobileNumberPlaceholder:'Número de celular',
    password: 'Senha',
    passwordPlaceholder: 'Comprimento 6~32',
    login: 'Login',
    loginSuccess: 'Login com sucesso!',
    forgotPassword: 'Esqueci minha senha?',
    register: 'Registre-se',
    SMSLogin: 'Login de verificação por SMS',
    passwordLogin: 'Senha Login',
    signIn_text:'Já está cadastrado? ',
    signIn_btn: 'Fazer login'
  },
  // 设置密码页
  pesetPassword: {
    h1:'Peset Senha',
    old:'Senha antiga',
    oldPlaceholder:'Senha antiga',
    new:'Nova senha',
    newPlaceholder:'Nova senha',
    confirmPassword: 'Confirmar senha',
    confirmPasswordPlaceholder: 'Confirmar senha',
    success: 'Definir sucesso da senha!',
  },
  // 找回密码
  resetPassword:{
    h1:'Redefinir senha',
    newPassword: 'Nova senha',
    newPasswordPlaceholder: 'Comprimento 6~32',
    confirmPassword:'Confirmar senha',
    confirmPasswordPlaceholder:'Confirmar senha',
    success: 'Sucesso na redefinição de senha!'
  },
  // 用户注册
  userRegister:{
    h1:'Registre-se',
    registerTopText1: 'A plataforma online certificada pelo Governo.  Sua conta e fundos são regulamentados pelo Banco. Você pode sacar a qualquer momento.',
    registerTopText2: 'Novo bônus de registro',
    registerText3: 'E-mail comercial:',
    registerText4: 'E-mail de serviço:',
    verificationCode: 'Código de Verificação',
    verificationCodePlaceholder: '6 dígitos',
    OTP: 'OTP', 
    confirmPassword: 'Confirmar senha',
    confirmPasswordPlaceholder: 'Confirmar senha',
    recCode: 'Código de Recomendação',
    recCodePlaceholder: 'Não é necessário',
    iAgree: 'Eu concordo',
    privacyPolicy: 'Política de Privacidade',
    registerBtn: 'Registre-se',
    success:'Sucesso no registro!',
    signUpBonus:'bônus de registro!',
    signUpCoupon:'Cupom de desconto recebido',          //收到优惠券
  },
  //我的主页
  home: {
    h1: 'Minha casa', 
    mobile: 'Móvel',
    vip: 'VIP',
    inviteCode: 'Código de convite',
    balance:'Balanço',
    recharge:'recarga',
    withdrawal:'Retirada',
    vipDescription: 'Descrição VIP',
    cpf:'CPF',
    cpf_title:'Preencher o CPF',
    cpf_not:'Ainda não preencheu o CPF',
    cpf_text:'Para segurança da conta, por favor, preencha as informações do CPF.',
    link:'Canal Oficial',
    link_r: 'Bouns diários',
    promotion: 'Promoção',
    envelopesCenter:'Centro de Envelopes',
    transactions:'Transações',
    bankCard:'Pix',
    resetPassword:'Efinir senha',
    cs:'Reclamações & Sugestões',
    about:'Sobre',
    logout:'Logout',
    vipTitle: 'Descrição dos Privilégios VIP',
    withdrawakQuoat: 'Cota de Retirada',
    level: 'Nível VIP',
    totalRecharge: 'Recarga total',
    profitRatio: 'Relação de lucro',
    withdrawalServices: 'Serviço de Retirada',
    vipRewards: 'Prêmios VIP',
    dt: 'Descrição da quantia de retirada:',
    dd1: '1、Retirada Total = Recarga Total x Relação de Lucro;',
    dd2: 'VIP8 Retirada não está sujeita a restrições de recarga, portanto você pode retirar tanto quanto ganhar.',
    gameAmount: 'O saldo da conta do jogo vem do lucro e da recarga da plataforma.',
    agentAmount: 'O saldo da conta do agente vem do sistema de comissão do agente'
  },
  // 设置头像
  setAvatar: {
    h1:'Conjunto Avatar',
    current:'Avatar atual',
    avatars:'Avatares à escolha:',
    success:'Avatar substituído com sucesso!'
  },
  //银行卡
  bankCard: {
    h1: 'Pix',
    noBankcCard: 'Nenhum Pix está atualmente vinculado!',
    addBankcCard: 'Adicionar pix',
    delete: 'Eliminar ou não?',
    editTitle: 'Editar Pix',
    addTitle: 'Adicionar pix informações sobre a conta',
    realName: 'Nome real',
    realNamePlaceholder: 'Código IFSC',
    ifscCode: 'Código IFSC',
    ifscCodePlaceholder: 'Código IFSC',
    bankName: 'Nome do Banco',
    bankNamePlaceholder: 'Nome do Banco',
    bankAccount: 'Conta bancária',
    bankAccountPlaceholder: 'Conta bancária',
    upiVpa: 'UPI ID (Vpa)',
    upiVpaPlaceholder: 'UPI ID (Vpa)', 
    email: 'E-mail',
    emailPlaceholder: 'E-mail',
    success:'Sucesso',
    addcard_text:'Escolha um dos 4 itens a seguir para preencher.',
    GCash: 'GCash', 
    GCashPlaceholder: 'GCash Mobile',
    CPF: 'CPF',
    CPF_PLACEHOLDER: 'Por favor digite CPF',
    PHONE: 'Phone',
    PHONE_PLACEHOLDER: 'Por favor digite phone',
    EMAIL: 'E-mail',
    EMAIL_PLACEHOLDER: 'Por favor digite E-mail',
    CNPJ: 'CNPJ',
    CNPJ_PLACEHOLDER: 'Por favor digite CNPJ',
  },
  //投诉 & 建议
   CS: {
    h1: 'Sugestões de Reclamações',
    noData: 'Sem reclamações e sugestões!',
    addBtn: 'Adicionar reclamações',
    waitReply: 'Resposta "Aguarde',
    replied: 'Respondido',
    h2: 'Escrever reclamações ou sugestões',
    type: 'Tipo',
    typePlaceholder: 'Selecione o tipo',
    title: 'Título',
    titlePlaceholder:'Por favor, digite o título!',
    description: 'Descrição',
    descriptionPlaceholder: 'Por favor, digite a descrição',
    servicTime: 'Serviço:10:00~16:00, Mon~Fri',
    servicTime2: 'Por favor, forneça screenshots de seu número problem、Upi/UTR e sua identificação',
    recharge: 'Recarga',
    withdraw: 'Retirada',
    invite: 'Convide',
    gameCrash: 'Crash do jogo',
    others: 'Outros',
  },
  // 消息
  envelopes: {
    h1:'Centro de Envelopes',
    new: 'nova',
  },
  // 充值页
  recharge: {
    h1:'Recarga',
    balance: 'Balanço',
    amount: 'Montante',
    amountPlaceholder: 'Montante',
    receipt: 'Recibo',
    receiptPlaceholder: 'Montante',
    channel: 'Canal',
    success: 'Recarga de sucesso',
    failSelect: 'Favor selecionar um canal de recarga!',
    failEnter: 'Favor inserir o valor correto!',
    pop:'Complete o pagamento na nova página, por favor aguarde...', 
    description:'Descrição',
    tx5: '1.Se houver algum problema com a recarga, por favor, entre em contato conosco através ',
    tx5_1: 'telegrama de apoio',
    tx5_2: ' , horário de trabalho: 7:00-20:00',
    tx6: '2. O valor da recarga aumentará seu nível VIP, e um nível VIP mais alto aumentará seu Valor de Retirada, Bônus Diário, etc.',
    tx7: 'Veja o',
    tx8: '“Descrição VIP”',
    tx9: '3.Não revele sua senha para evitar que ela seja roubada. Nosso pessoal não pedirá sua senha.',
    levelUpVip: 'Atualização agora!',
    popTitle_1:'VIP Descrição',
    popTitle_2:'VIP Descrição',
  },
  // 充值列表页
  rechargeList: {
    h1:'Histórico de Recarga',
    giftAmount: 'Valor do Presente',
    applyAmount: 'Aplicar Valor',
  },
  // 提现页
  withdrawal: {
    h1:'Retirada',
    availableBalance:'Saldo disponível', 
    agentAmount: 'Conta do agente',
    gameAccount: "Conta do jogo'", 
    transfer: 'Transferir',
    withdrawAmount: 'Valor do saque',
    handlingFee: 'Taxa de manuseio', 
    receiptAmount: 'Valor do recibo', 
    bankCard: 'Pix', 
    withdrawalBtn: 'Retirada',
    errInfoBankCard: 'Favor preencher as informações para completar a retirada.',
    errInfoRecharge: 'Você precisa recarregar mais para se retirar.',
    errInfopPlayMore: 'Senhor/Madam, você tem muito pouco valor apostado, por favor continue jogando.',
    description:'Descrição',
    tx4: '1.Se houver algum problema com a recarga, entre em contato conosco pelo ',
    tx4b: 'Se houver algum problema com a recarga, entre em contato conosco pelo ',
    tx5: '1.Se houver algum problema com a retirada, por favor, entre em contato conosco através ',
    tx5b: 'Se houver algum problema com a retirada, por favor, entre em contato conosco através ',
    tx_contact_us:'contact us',
    tx5_1: 'telegrama de apoio',
    tx5_2: ' ,horário de funcionamento: 7:00-20:00',
    tx6: '2. O nível VIP mais alto pode aumentar o limite de retirada.',
    tx7: 'Veja o',
    tx8: '“VIP Descrição',
    tx9: '3.A fim de evitar a lavagem de dinheiro, você deve atingir uma quantidade suficiente de entrada antes de poder retirar.',
    tx10: 'Parabéns!',
    tx11: '🎉🎉🎉A segurança de sua conta foi verified🤝🤝🤝Now você pode continuar a jogar!🥳🥳🥳',
    btntx: 'Jogar',
    popBankList: 'This bank card is not supported at the moment, the following is a list of available banks!'
  },
  // 提现列表页
  withdrawalList: {
    h1:'Histórico de Retirada',
    receiptAmount: 'Valor do recibo',
    applyAmount: 'Aplicar valor',
    withdrawFee: 'Taxa de Retirada',
    pleaseCheckBank: 'Por favor, confirme se sua conta Pix está correta e se o CPF é o mesmo, depois envie novamente.',
  },
  // 金币转换
  transfer: {
    h1:'Transferência',
    transferAmount: 'Montante da transferência',
    btntx: "Confirme",
    success: 'Sucesso de transferência',
    tx1: 'O valor do jogo vem do lucro do investimento da plataforma e da recarga. O valor do agente vem do sistema de comissão do agente.',
    tx2: 'Se você precisar, a quantia do agente pode ser transferida livremente para a quantia do jogo para jogar o jogo, a quantia do jogo não pode ser transferida para a quantia do agente, ambas as quantias podem ser retiradas.',
  }, 
  //钱包明细列表
  transactions:{
    h1:'Transações',
    cashH1:'Transações em dinheiro',
  },
  //任务页
  taskReward:{
    h1: 'Missões',
    day: 'dia',
    checkIn:'Check-in',
    checkInLocked:'Fechadura',
    toTask: 'vá',
    dailyQuest: 'Tarefa diária',
    dailyQuestLocked: 'Tarefa diária',
    dailyQuestCompleted: 'Tarefa diária (Concluída!)',
    dailyQuestFinished: 'Tarefa diária (Concluído!)',
    completeTask: 'vá',

    receiveReward: 'Receber Recompensa',
    received: 'Recebido',
    completed: 'Completo',
    complete: 'Completo',
    checkIn7Text: 'Check-in por 7 dias consecutivos',
    vipCheckInText: 'Atualização VIP para obter mais bônus',
    checkInText: 'Check-in diário para receber recompensas',
    missionTarget: 'Missões',
    getBn: 'Recompensa Recebida!',
  },
  // 代理激励计划
  agentGrowth: {
    h1: 'Crescimento de Agentes',
    bn1: "O plano de crescimento de caixa do Agente Crore está dividido em ",   //bn1 + xx + bn2 + xx + bn3!
    bn2: " níveis, e cada nível tem recompensas generosas. Complete o nível mais alto e receba uma recompensa de ",
    bn3: " imediatamente.",
    target:'alvo',
    h4_1: 'Condições de recompensa',
    h4_item1_1: 'Convide',
    h4_item1_2: 'pessoa válida (registro e recarga), você pode obter dinheiro.',
    h4_2: 'Atualização de habilidades',
    h4_item2_1: 'You share the link and someone else register through the link, that person will be your subordinate. Once ',     
    h4_item2_2: ' subordinates become effective, you can get and withdraw ',   
    h4_item2_3: ' immediately.',
  },
  // 代理激励计划    //**************************************** 未翻译 *********************************************
  AGENT_SUPPORT_PLAN: {
    H1: 'Comissão de bônus de recarga',
    MY_SUPPORT_PLAN: 'My Support Plan',
    MY_SUPPORT_PLAN_LEVEL: 'My Level',
    TODAY_EFFECIVE_USERS_AMOUNT: 'Today Effecive Users Count',
    TODAY_POINTS_BONUS: 'Today Input Bonus',
    YESTERDAY_SUPPORT_REWARD: 'Yesterday Support Reward',
    BTN_TEXT: 'Click to receive reward >>',
    AGENT_REWAED_TOP: 'Agent Reward top 5',
    LEVEL: 'Level',
    LV: 'Lv',
    PHONE: 'Phone',
    ORIGINAL_SYSTEM_REWAED: 'Original System Reward',
    SUPPORT_REWARD: 'Support Reward',
    ONLY_TOP_5: 'Only show Top 5 data!',
    DAILY_REQUIRE: 'Daily Require',
    REWARD: 'Reward',
    EFFECIVE_USERS_AMOUNT: 'Effecive Users Count',
    POINTS_BONUS: 'Input Bonus',
    TOTAL_SUPPORT_REWARD: 'Total Support Reward',
    CONSECUTIVE_DAYS_EXTRA_REWAED: '7 Consecutive Days Extra Reward',
    I_E_Q: '(Invite + Effective + Quests)Bonus',
    //new
    INFO_TEXT: 'Se o sistema detectar qualquer trapaça, os bônus e as contas serão congelados.',
    Event_time:'Tempo',
    Invitee_personal_recharge_5000_1: 'Valor da recarga < 100',
    Invitee_personal_recharge_5000_2: 'Valor da recarga ≥ 100',
    Total: 'Total',
    Commission: 'Receita de comissões',
    Recharge_Amount: 'Valor da recarga',
    text1: "Quando a recarga total pessoal de um convidado chegar a 100, sua taxa de desconto da comissão de recarga mudará de 1% para 5%",
    text2: "Parabéns por ter recebido com sucesso as recompensas de comissão",
    text3: "Ainda não chegou o momento de receber a comissão",
    text4: "O evento foi encerrado!",
    received: "Recebido",
    Claim_Commission_after:'Comissão de Reivindicação após as ',
    Description:'Descrição',
    Receive_Reward:'Recompensa Recebida',
    p0: "During the current event period, your invitees must be more than 5, otherwise you will not be able to receive rewards.",
    p1: "Durante o evento, você receberá um desconto de comissão para cada recarga de seus convidados. A taxa de desconto de comissão é de 1% ou 5%.",
    p2: "A taxa de desconto de comissão de 1% é aplicável a convidados cuja recarga total pessoal seja inferior a 100 durante o evento. Por exemplo: 3 usuários recarregam 80 cada, totalizando 240, Comissão = 240 x 1%",
    p3: "A taxa de desconto de comissão de 5% é aplicável a convidados cuja recarga total pessoal seja superior a 100 durante o evento. Por exemplo: 1 usuário recarrega 1000, Comissão = 1000 x 10%.",
    p4: "Se o sistema detectar qualquer trapaça, os bônus e as contas serão congelados.",
    p5: "Após o final de cada período, receba a comissão dentro de 24 horas e, em seguida, ela entrará automaticamente no próximo período."

    
  },	
  // 邀请
  invite: {
    h1: 'Convide',
    agentAmount: "Valor do agente",
    withdraw:'Retirada',
    totalPeople: 'Total de pessoas',
    effectiveNum: 'Número Efetivo',
    h3: 'Plano de Crescimento de Agente Crore',
    contribution: 'Bônus de contribuição',
    more: 'deslize para ver mais',
    affinity: 'Grau',
    inviteCount: 'Convite à contagem',
    effectiveCount: 'Contagem Efetiva', 
    effectiveBonus: 'Bônus Efetivo', 
    inviteCommissionAmount: 'Bônus de convite',
    subBetCommissionRatio:'Bônus de pontos',
    subBetCommissionAmount:'Bônus de pontos',  
    subFirstRechargeCommissionRatio:'Sub Primeira Comissão de RecargaRatio',
    subFirstRechargeCommissionAmount:'Sub Primeira Comissão de Recarga',
    totalCommissionAmount:'Bônus Total',
    Grade: 'Grau',
    Bonuss: 'Bônus',
    rules: 'Regras',
    bonus: 'Bônus',
    myCode: 'Meu Código de Promoção',
    you: 'VOCÊ',
    youTitle: 'Sistema de agência em 4 níveis, cada nível traz comissão!',
    level: 'Grau',
    label1: 'Bônus',
    rulesTitle1: 'O mais fácil', 
    rulesText11: 'Recompensa 1~99 para cada usuário registrado, obtenha renda imediatamente!', 
    rulesText12: 'Se você convidar 100 usuários, ganhe pelo menos 400 e possivelmente até to 9900！',
    rulesTitle2: 'O mais longo',
    rulesText21: 'Usuários em quatro níveis, cada usuário gerará comissão!',
    rulesText22: 'Se cada pessoa convidar 10 pessoas, cada pessoa só precisa apostar 100 por dia, após 30 dias, através do sistema de agência em quatro níveis, você ganhará mais de 35,000!',
    rulesTitle3: 'O mais eficaz',
    rulesText31: 'Clique e compartilhe o link bônus no canal oficial de telegramas, ganhe dinheiro na ponta de seus dedos！',
    rulesText32: 'Nosso agente Heitor é um vendedor ambulante, ele convida 100 novos usuários a se inscreverem todos os dias compartilhando o link, ele provavelmente ganha 1500 todos os dias! Se ele pode, você também pode!',
    rulesTitle4: 'O que é Recall?',
    rulesText41: 'Se um usuário não tiver visitado por 21 dias, e revisitar através de seu link de convite ou link de encaminhamento de lifafa, ele será considerado como seu novo usuário convidado. Continue promovendo e você continuará recebendo novos usuários e comissões.',
    h4: 'Meu Código de Promoção',
    h4Title: 'A plataforma mais quente de ganhar dinheiro online em 2023.Ganha facilmente 3.000~20.000',
    btn: 'Copiar link',
    to: 'ou compartilhar para',
    shareMakeMoneya: 'Compartilhar ganhar dinheiro',
    info1: 'Cada usuário que você convidar lhe trará um bônus de MAX Rs.99,e aqueles que você convidar receberão  ',
    info2: ' recompensas.',
    //ShareNetwork
    url: '',
    title: 'a plataforma de ganhos mais segura',
    description: "Maneiras rápidas e fáceis de ganhar dinheiro no Brasil em 2023 A partir de Bonus🚀Withdraw Em 3m🚀100X Win🚀 10 Million Share Reward🚀100% Safe🚀 Quanto mais cedo você aderir, mais você ganha'",
    quote: 'Quanto mais cedo você se juntar, mais você ganhará!',  
    hashtags: 'game',
    twitterUser: ' ',
    cashHistory:'Histórico de caixa',
    infoToast: 'Os usuários convidados devem atender ao padrão de número efetivo exigido pelo sistema, caso contrário, não serão contados. Convide usuários mais efetivos, e as estatísticas serão retomadas.',
    
    Congratulations:'Parabéns',
    NewAgencyIncome:'Receitas recentes da agência',
    NewInvites:'Convites recentes',
    NewEffective:'Número efetivo recente',
    EarnMoreNow:'Ganhe mais agora',
    Last30Days: 'Os dados são dos últimos 30 dias',
  },
  //我的历史下注
  myPlayList: {
    h1: 'Meu Ordem',
    bet: 'Aposta: ',
    win: 'Ganhe: ',
    crash: 'Crash',
    ratio: 'Parada',
    originWinAmount: 'Ganhe: ',
    feeAmount: 'Taxas: ',
    gameNum: '',
    time:'Horário'
  },
  //领取红包页面
  receiveBonus:{
    h1: 'Receber bônus', 
    name: 'Seu amigo',
    h2: 'lhe envia um bônus!',
    h3: 'enviou',
    btn: 'Receba',
    btn2: 'Obtenha o Bônus mais Recente',
    success: 'Receber o sucesso!',
    user: 'Usuário',
    receives: 'recebe a recompensa!',
    // errText: 'Por favor, clique no botão para obter o último bônus!',
    errText: '',
  },
  //分享红包页面
  shareBonus:{
    h1: 'Bônus de ações',
    h2: 'Receber bônus com sucesso!', 
    h3: 'Compartilhar com outros lhe trará 100% de retorno!',
    btn: 'Compartilhar para ganhar dinheiro',
    copied: 'Seu link de bônus copiado!',
  },
  //小提示
  tips:{
    tips1: 'Pontos mínimos 10,Pontos máximos 10000',
    tips2: 'Obter recompensa em dinheiro completando tarefas todos os dias!',
    tips3: 'Foguetes podem voar até X100！',
    tips4: 'Encontrar padrões é a chave para vencer！',
    tips5: 'Silva é o primeiro paraquedista a ganhar 85,77 vezes com sucesso, prestando-lhe homenagem!',
    tips6: 'A queda contínua em baixa, a chance de voar muito mais alto na próxima rodada aumentará！'        
  },
  //vip说明
  VIP_PRIVILEGE: {
    VIP_PRIVILEGE: 'VIP Privilege',
    WITHDRAWAL_DESCRIPTION: 'Withdrawal Description',
    LEVEL: 'nível',
    TOTAL_RECHARGE: 'Recarga total',
    WITHDRAWAL_QUOTA: 'Quota de retirada',
    REWARDS: 'Rewards',
    DAILY_BONUS: 'Dônus diário',
    EXTRA: 'Extra',
    WITHDRAWAL: 'Retirada',
    STOP_LIMIT: 'Limite de parada',
    INPUT_LIMIT: 'Limite de entrada',
    GAMEPLAY: 'Gameplay',
    DATA_ANALYSIS: 'Análise de dados',
    ROOM_LIMIT:'Limite de espaço',
    MAX: 'Max',
    RECORD: 'Record',
    CONTINUOUS: 'Continuous',
    PROBABILITY: 'Probability',
    // TEXT1: 'Valor de retirada(Lv1-Lv3)=Balanço-Bônus inicial(',
    // TEXT2: ')Upgrade para VIP5 para retirar livremente! o limite de retirada até 5999!',
    TEXT1:'Seu nível VIP é muito baixo.',
    TEXT2:'Recarregue 20 para fazer upgrade para VIP1 e concluir esse saque.',
     
    //TEXT3: 'Atualização para o nível VIP correspondente para completar esta retirada.',
    TEXT3: 'Upgrade para VIP6 para retirar livremente! o limite de retirada até 5999!',

    TEXT4: 'Após tornar-se um usuário VIP, o valor mínimo de saque e recarga é de 100.',
  },
  //游戏规则 飞机
  gameRules:{
    h1: 'Regras de jogo',
    dt1: 'Como jogar ?',
    dd1_1: 'Primeiro, escolha quanto você quer fazer o pedido. Clique em "START" para fazer seu pedido e veja o multiplicador subir de 1,00x para cima',
    dd1_2: 'Você pode clicar em "STOP" a qualquer momento a fim de multiplicar seu ponto com o multiplicador atual. Mas tenha cuidado: O jogo pode terminar a qualquer momento, se você não tiver parado até lá, você não recebe nada!',
    dd1_3: 'Você precisa esperar pelo início do próximo jogo.',
    dt2: 'Como é calculado o montante?',
    dd2_1: 'Se o valor de seu pedido for 100, o múltiplo de fuga é 10,0x, após dedução de 3 taxas de serviço, o valor de seu contrato é 97:',
    dd2_2: '10.0X: Você receberá (97 * 10) 970.',
    dt3: 'A que altura pode ir o multiplicador?',
    dd3: 'Até 100 vezes',
    dt4: 'O que é parada automática?',
    dd4: 'Se você optar pela parada automática, quando o múltiplo alcançar o múltiplo selecionado, ele irá parar automaticamente e receber recompensas.',
    dt5: 'Por que os multiplicadores estão em cores diferentes ? ',
    btn: 'Entendi.',
  },
  //游戏规则 
  gameRulesParity:{
    h1: 'Regras de jogo',
    dt1: 'Rápido',
    dd1_1: 'Uma rodada de 30 segundos, 27 segundos para colocar um pedido, 3 segundos para anunciar o resultado, 24 horas sem parar, um total de 2880 rodadas por dia.',
    dd1_2: 'Entrada de 100 em uma rodada',
    dd1_3: 'Junte-se ao Verde:',
    dd1_4: 'Se o resultado for 1,3,7,9, você receberá 195, se o resultado for 5, você receberá 142,5.',
    dd1_5: 'Junte-se ao Red:',
    dd1_6: 'Se o resultado for 2,4,6,8, você receberá 195, se o resultado for 0, você receberá 142,5.',
    dd1_7: 'Junte-se à Violet:',
    dd1_8: 'Se o resultado for 0,5, você receberá 427,5.',
    dd1_9: 'Número de escolha:',
    dd1_10: 'Se o resultado for o número de sua escolha, você receberá 855.',
    dt2: 'Previsão',
    dd2_1: 'Uma rodada de 90 segundos, 80 segundos para colocar um pedido, 10 segundos para anunciar o resultado, 24 horas sem parar, um total de 960 rodadas por dia.',
    dd2_2: 'Entrada de 100 em uma rodada',
    dd2_3: 'Junte-se ao Verde:',
    dd2_4: 'Se o resultado for 1,3,7,9, você receberá 195, se o resultado for 5, você receberá 142,5.',
    dd2_5: 'Junte-se ao Red:',
    dd2_6: 'Se o resultado for 2,4,6,8, você receberá 195, se o resultado for 0, você receberá 142,5.',
    dd2_7: 'Junte-se à Violet:',
    dd2_8: 'Se o resultado for 0,5, você receberá 427,5.',
    dd2_9: 'Número de escolha:',
    dd2_10: 'Se o resultado for o número de sua escolha, você receberá 855.',
    dt3: 'A verdade é a verdadeira justiça, a confiabilidade deve exigir transparência',
    dd3_1: 'Para garantir os interesses de cada usuário de investimento, a fim de cumprir as normas de certificação IBAS e MGA, são dadas as seguintes instruções para a geração de resultados de número de paridade.',
    dd3_2: '1. Fonte dos resultados: Pegue o último dígito do preço de fechamento do par de negociação à vista Binance BTC/USDT no momento em que o processo de verificação dos resultados da Paridade termina.',
    dd3_3: '2. Verificação de dados: Você pode ver o tempo dos resultados históricos e o preço correspondente na página de pedido, e comparar os dados históricos de segundo nível de posição.',
    dd3_4: "Nota: Binance é atualmente a maior bolsa de moedas criptográficas do mundo. BTC/USDT refere-se ao preço comercial do Bitcoin/Tether. Com um volume de negociação de $760k/dia, ninguém pode manipular os preços em segundos.",
    btn: 'Entendi.',
  },
  gameRulesMine:{
    h1: 'Regra da mina',
    dt1: 'Três formas de jogar: 2x2, 4x4, 6x6, cada forma tem um número diferente de caixas e bônus.',
    dt2: 'Tomemos como exemplo o 4x4, um total de 16 caixas, uma das quais está enterrada com uma mina, você precisa encontrar caixas sem mina, e você receberá um bônus toda vez que encontrar uma',
    dt3: 'Clique em Iniciar, selecione o valor de entrada e comece a jogar.',    
    dt4: 'Se as minas não forem evitadas, o jogo acabou.',
  },

  gameRulesDice:{
    h1: 'Regra da Dice',
    title:'DICE é um emocionante jogo de azar popular em todo o mundo',
    dt1:'como jogar?',
    dd1:'Jogo rápido, você pode apostar Big ou Small em cada rodada e também pode apostar o número de DICE. Se o resultado corresponder à sua aposta, você poderá ganhar dinheiro rapidamente.',
    dt2:'ganhe',
    dd2:'Aposte Big e Small, as chances são de 1:2',
    dd3:'Apostando em números, as chances são de 1:6',
  },


  // AB游戏页
  andarBahar:{
    H1: 'Andar Bahar',
    Period: 'Período',
    Bet_end: 'Fim da aposta',
    Count_Down: 'Contagem regressiva',
    Cards: 'Cartões',
    My_Bet: 'Minha aposta',
    Not_bet: '/',
    Rules: 'Regras',
    START: 'INICIAR',
    Wait_for_the_next_round: 'Aguarde a próxima rodada!',
    Bet_Joker: 'Aposta Joker',
    Suit_Of_Joker_Card: 'Naipe de Joker carta',
    Value_Of_Joker_Card: 'Valor do Joker cartão',
    Bet_Deal: 'Aposta do acordo',
    Winning_Side: 'Lado vencedor',
    Cards_To_Be_Dealt: 'Cartas a serem distribuídas',
    Record: 'Registro',
    Bet: 'Aposta',
    Win: 'Ganhar',
    VIP_Only: 'Somente VIP',
    Num: 'Número de série',
    Choose: 'Escolha',
    Place_your_bets:'Faça suas apostas',
    value: 'Valor',
    SuitDeal:'Terno & Distribuir'
  },
  gameRulesAndar:{
    h1: 'Regras de jogo',
    dt1: '',
    dd1: 'O jogo é jogado ao vivo 24 horas por dia e usa um baralho padrão de 52 cartas. Na primeira rodada do jogo, o dealer revela uma carta - a carta "joker" - e a coloca na mesa em um local especialmente marcado. Na segunda rodada, o dealer começa a colocar uma carta de cada vez nos lados Andar e Bahar. O objetivo do jogo é obter uma carta que corresponda ao valor da carta "joker".',
    dt2: '1. Primeira rodada de apostas',
    dd21: '1.1 Os apostadores podem prever o naipe da carta "joker", pois há quatro naipes (copas ♥, ouros ♦, espadas ♠, paus ♣).',
    dd22: '1.2 Os apostadores também podem prever que o valor da carta "joker" será superior, inferior ou exatamente 7.',
    dd23: '1.2.1 Para que uma aposta em "menos de 7" seja vencedora, a carta "joker" deve ter um dos seguintes valores: A = ás, 2 = dois, 3 = três, 4 = quatro, 5 = cinco, 6 = seis.',
    dd24: '1.2.2 Para que uma aposta "acima de 7" ganhe, a carta "joker" deve ter um dos seguintes valores: 8 = oito, 9 = nove, 10 = dez, J = valete, Q = rainha, K = rei.',
    dd25: '1.2.3 Para que uma aposta em "exatamente 7" ganhe, a carta "joker" deve ter o valor 7.',
    dt3: '2. segunda rodada de apostas',
    dd31: '2.1.Andar Bahar',
    dd32: '2.1.1.Andar',
    dd33: 'Os apostadores podem apostar na carta que corresponde à carta "joker" a ser colocada no lado Andar.',
    dd34: '2.1.2.Bahar',
    dd35: 'Os apostadores podem apostar na carta que corresponde à carta "joker" a ser colocada no lado Bahar.',
    dd36: '2.2 Número de cartas distribuídas',
    dd37: 'Uma aposta em quantas cartas serão distribuídas até que a carta correspondente à carta "joker" seja colocada no lado do Andar ou do Bahar',
  },

  //sicbo
  SICBO:{
    H1: 'DICE',
    BET: "Aposta",
    BET_BIG_OR_SMALL: "Aposta Big ou Small",
    BET_NUMBER: "Aposta Números",
    BET_BIG: "Aposta Big",
    BET_SMALL: "Aposta Small",
    VIP_1_UNLOCK: 'Desbloqueio Vip1+',
  },

  // 注册协议
  privacyPolicy:{
    p1:'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
    p2:'Interpretation and Definitions',
    p3:'Interpretation',
    p4:'The words of which the initial letter is capitalized have meanings defined under the following conditions.',
    p5:'The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
    p6:'Definitions',
    p7:'For the purposes of this Privacy Policy:',
    p8:'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
    p9:'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Coem Shop.',
    p10:'Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
    p11:'Account means a unique account created for You to access our Service or parts of our Service.',
    p12:'Website refers to Coem Shop',
    p13:'Service refers to the Website.',
    p14:'Country refers to: Rio, Brazil',
    p15:'Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
    p16:'Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.',
    p17:'Personal Data is any information that relates to an identified or identifiable individual.',
    p18:'Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
    p19:'Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
    p20:'Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
    p21:'Collecting and Using Your Personal Data',
    p22:'Types of Data Collected',
    p23:'Personal Data',
    p24:'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:',
    p25:'Email address',
    p26:'First name and last name',
    p27:'Phone number',
    p28:'Address, State, Province, ZIP/Postal code, City',
    p29:'Usage Data',
    p30:'Usage Data',
    p31:'Usage Data is collected automatically when using the Service.',
    p32:"Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
    p33:'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.',
    p34:'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.',
    p35:'Tracking Technologies and Cookies',
    p36:'We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.',
    p37:'You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.',
    p38:'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.',
    p39:'We use both session and persistent Cookies for the purposes set out below:',
    p40:'Necessary / Essential Cookies',
    p41:'Type: Session Cookies',
    p42:'Administered by: Us',
    p43:'Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.',
    p44:'Cookies Policy / Notice Acceptance Cookies',
    p45:'Type: Persistent Cookies',
    p46:'Administered by: Us',
    p47:'Purpose: These Cookies identify if users have accepted the use of cookies on the Website.',
    p48:'Functionality Cookies',
    p49:'Type: Persistent Cookies',
    p50:'Administered by: Us',
    p51:'Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
    p52:'For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.',
    p53:'Use of Your Personal Data',
    p54:'The Company may use Personal Data for the following purposes:',
    p55:'To provide and maintain our Service, including to monitor the usage of our Service.',
    p56:'To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
    p57:'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
    p58:"To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
    p59:'To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
    p60:'To manage Your requests: To attend and manage Your requests to Us.',
    p61:'We may share your personal information in the following situations:',
    p62:'With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.',
    p63:'For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.',
    p64:'With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
    p65:'With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.',
    p66:'With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see You name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.', 
    p67:'Retention of Your Personal Data',
    p68:'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
    p69:'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.',
    p70:'Transfer of Your Personal Data',
    p71:"Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
    p72:'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.',
    p73:'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.',
    p74:'Disclosure of Your Personal Data',
    p75:'Business Transactions',
    p76:'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
    p77:'Law enforcement',
    p78:'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
    p79:'Other legal requirements',
    p80:'The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:',
    p81:'Comply with a legal obligation',
    p82:'Protect and defend the rights or property of the Company',
    p83:'Prevent or investigate possible wrongdoing in connection with the Service',
    p84:'Protect the personal safety of Users of the Service or the public',
    p85:'Protect against legal liability',
    p86:'Security of Your Personal Data',
    p87:'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.',
    p88:"Children's Privacy",
    p89:'Our Service does not address anyone under the age of 16. We do not knowingly collect personally identifiable information from anyone under the age of 16. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 16 without verification of parental consent, We take steps to remove that information from Our servers.',
    p90:"If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
    p91:'Links to Other Websites',
    p92:"Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
    p93:'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
    p94:'Changes to this Privacy Policy',
    p95:'We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
    p96:'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.',
    p97:'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page',
    p98:'Other',
    p99:"If the loss is caused by the user's wrong input of bank account information, the user shall be responsible for the loss.",
  },
}
