<template>

<nut-navbar @on-click-back="back" @on-click-title="title" title="Recharge" fixed=true class="mb0">
      <template #left>
        <div>返回</div>
      </template>
</nut-navbar>

<div class="login">
    <div class="login-bn">
       &nbsp;
    </div>
    <div class="login-bd">
        <h3>Recharge Complain</h3>
        <div class="login-inp">
            <p class="fs10 fc-999">Reason for complaint</p>
            <nut-textarea placeholder="Reason for complaint" type="text" />
        </div>
        <div class="login-inp">
            <p class="fs10 fc-999">Payment Screenshot</p>
            <nut-uploader url="http://服务地址" v-model:file-list="defaultFileList" maximum="3" multiple>
            </nut-uploader >
        </div>
        
        <div class="login-btn">
            <nut-button block type="info">Confirm</nut-button>
        </div>
    </div>
</div>
</template>

<script>
    import { reactive } from 'vue';
    export default {
        setup() {
            const state = reactive({
                val0: '初始数据',
                val1: '',
                defaultFileList: [
                    {
                    name: '文件1.png',
                    url: 'https://m.360buyimg.com/babel/jfs/t1/164410/22/25162/93384/616eac6cE6c711350/0cac53c1b82e1b05.gif',
                    status: 'success',
                    message: '上传成功',
                    type: 'image'
                    },
                    {
                    name: '文件2.png',
                    url: 'https://m.360buyimg.com/babel/jfs/t1/164410/22/25162/93384/616eac6cE6c711350/0cac53c1b82e1b05.gif',
                    status: 'uploading',
                    message: '上传中...',
                    type: 'image'
                    }
                ]

            });
            const methods = {
                back() {
                    alert('header');
                },
                title() {
                    alert('title');
                },
                right() {
                    alert('right');
                }
                
            };
            return {
                state,
                methods,
            };
        }
    }
</script>

<style scoped>
    .login{ background: url(../assets/images/bg15_1225c.png) 0 -50px no-repeat; background-size: 100% auto;}
    .login-bd{ 
        padding: 20px; 
        background: #fff; 
        margin: 20px;
        box-shadow: 0px 0px 5px #d9d2f4;
        border-radius: 10px;
    }
    .login-bd h3{ margin: 10px 0 30px 0; font-size: 20px; color: #333;font-weight: 400; text-align: center;}
    .login-bd .nut-input {padding: 10px 0 10px 0px;}
    
    
    .login-btn{ margin:30px 0 10px 0;}
    .login-bn{padding: 20px 20px 0px 20px;} 
    .login-bn h2{ padding: 0; margin: 0; font-size: 40px; text-align: center; 
        color: #efe7f4;
    }
    .otpbtn{ float: right;}
    .nut-input-inner{display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;}
    .w100 .input-text {width: 100% !important;}


    .nut-textarea {
  padding: 0;
  height: 60px;
  border-bottom: 1px solid #eaf0fb;
}
</style>

<style>
    .nut-input .input-text {padding: 0 0px 0 0px !important; width:60% !important;}
    
</style>