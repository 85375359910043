<template>
  <div>
    <nut-navbar
      :left-show="false"
      :title="$t('envelopes.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="pt50">
      
      <ListEmpty v-show="listNoData"></ListEmpty>

      <div class="csls" id="scroll" v-show="!listNoData">
        <nut-infiniteloading
            containerId = 'scroll'
            :use-window='false'
            :has-more="hasNext"
            :load-txt='$t("global.loading")'
            :load-more-txt='$t("global.noMore")'
            :load-icon= 'null'
            @load-more="loadMore"
        >
          <div class="csls-item" v-for="(item, index) in list" :key="index"   @click="envelopesClick(item.id)">
            <h4>{{ item.title }}</h4>
            <p><span class="tx-right-r"  v-show="!item.alreadyRead">{{$t('envelopes.new')}}</span>{{ item.sendTime }}</p>
          </div>
        </nut-infiniteloading>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      listNoData: false,
      current: 1,
      list: [],
      hasNext:''
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    getList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/system/letter/paginate",
        method: "get",
        params: { current: c }
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
          this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        this.listNoData = true;
        if (this.list.length > 0) {
          this.listNoData = false;
        }
      });
      Toast.hide("loading")
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
    envelopesClick(i){
      this.$router.push({
        path: 'envelopesDetail',
        query: {
          details: i
        }
      })
    },
  },
  created() {
    this.getList(1);
  },
  setup() {
    const state = reactive({});
    const router = useRouter();

    const methods = {
      backClick() {
        router.push({
          name: "myHome",
        });
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.csls{
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 20px;right: 20px;top: 60px; bottom: 20px;
}
.csls-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.csls-item h4 {
  margin: 5px 0;
  line-height: 24px;
}
.csls-item p {
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #999;
}
.csls-item .tx-right-r {
  float: right;
  line-height: 22px;
  background: #fff3f3;
  color: #cf0505;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .tx-right-g {
  float: right;
  line-height: 22px;
  background: #f1ffe8;
  color: #5c9834;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-answer {
  background: #f1ffe8;
  padding: 10px;
  color: #5c9834;
  margin-top: 10px;
  border-radius: 0 15px 15px 15px;
  border: 1px solid #d2e8c3;
}


</style>