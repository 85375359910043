<template>
  <div class="sicbo-app">

    <div class="sicbo-hd">
      <div class="c">
        <span class="title">{{ $t('SICBO.H1') }}</span>
        <span class="l" @click="rechargeClick">{{ $filters.currencySymbol(me.availableAmount) }}</span>
        <span class="r" @click="myPlayList()"><i> </i></span>
        <span class="r2" @click="rulePopClick()"><i> </i></span>
      </div>
    </div>

    <p class="gg-info">{{ $t('global.slogan1') }}</p>
    
    <div class="sicbo">

      <div class="rg-top">


        <!-- 本局号 -->
        <div class="room-tabs">
          <div class="nav">
            <span>{{ getSerialNumPrefix(currentRoomGame.serialNum) }}<strong>{{ getSerialNumPostfix(currentRoomGame.serialNum) }}</strong></span>
          </div>
        </div>


        <!-- 色子 -->
        <div class="am-saizi s1" v-if="am_saizi_0" >
          <img  v-if="am_saizi_result === 1" src="../assets/images/sicbo/gif_1.gif" loop="1">
          <img  v-else-if="am_saizi_result === 2" src="../assets/images/sicbo/gif_2.gif" loop="1">
          <img  v-else-if="am_saizi_result === 3" src="../assets/images/sicbo/gif_3.gif" loop="1">
          <img  v-else-if="am_saizi_result === 4" src="../assets/images/sicbo/gif_4.gif" loop="1">
          <img  v-else-if="am_saizi_result === 5" src="../assets/images/sicbo/gif_5.gif" loop="1">
          <img  v-else-if="am_saizi_result === 6" src="../assets/images/sicbo/gif_6.gif" loop="1">
          <img style="width: 40px;" v-else src="../assets/images/sicbo/sz08.png">
        </div>
        <div class="am-saizi" v-else >
          <img  v-if="am_saizi_result === 1" src="../assets/images/sicbo/d1.png">
          <img  v-else-if="am_saizi_result === 2" src="../assets/images/sicbo/d2.png">
          <img  v-else-if="am_saizi_result === 3" src="../assets/images/sicbo/d3.png">
          <img  v-else-if="am_saizi_result === 4" src="../assets/images/sicbo/d4.png">
          <img  v-else-if="am_saizi_result === 5" src="../assets/images/sicbo/d5.png">
          <img  v-else-if="am_saizi_result === 6" src="../assets/images/sicbo/d6.png">
        </div>

        <!-- 色盅 -->
        <div :class="am_saizong_1 ? 'am-saizong s1': 'am-saizong'">
          <!-- 晃动 -->
          <img v-if="am_saizong_1" src="../assets/images/sicbo/sz07.png">
          <!-- 静态 -->
          <img v-else src="../assets/images/sicbo/sz06.png">
        </div>

        <!-- 人物 -->
        <div class="am-girl">
          <img v-if="am_girl === 0" src="../assets/images/sicbo/girl_3.gif">
          <img v-else-if="am_girl === 1" src="../assets/images/sicbo/girl_2.png">
          <img v-else-if="am_girl === 2" src="../assets/images/sicbo/girl_1.png">
          <img v-else-if="am_girl === 3" src="../assets/images/sicbo/girl_0.gif">
        </div>


        <!--**************************************** 倒计时面板 ****************************************-->
        <div class="timer-box">
          <div class="countdown">
            <div>
              <h6 v-if="currentRoomGame.stage === this.stageEnum.bet">{{ $t('PARITY.SELECT_TIME') }}</h6>
              <h6 v-else-if="currentRoomGame.stage === this.stageEnum.wait">{{ $t('PARITY.RESULT_TIME3') }}</h6>
              <h6 v-else-if="currentRoomGame.stage === this.stageEnum.end">{{ $t('game.end_time') }}</h6>
              <h6 v-else-if="currentRoomGame.stage === this.stageEnum.pause">{{ $t('game.gamePaused') }}</h6>
              <h6 v-else-if="currentRoomGame.stage === 'next'">{{ $t('andarBahar.Wait_for_the_next_round') }}</h6>

              <strong v-show="currentRoomGame.stage === this.stageEnum.bet">
                <nut-countdown :endTime="currentRoomGame.betDeadline" millisecond format="mm:ss"
                               style="font-size:26px;color: #a6ffaa;"/>
              </strong>

              <strong v-show="currentRoomGame.stage === this.stageEnum.wait">
                <nut-countdown :endTime="currentRoomGame.waitDeadline" millisecond format="mm:ss"
                               style="font-size:26px; color: #ffc745;"/>
              </strong>

              <strong v-show="currentRoomGame.stage === this.stageEnum.end" style="font-size:12px; line-height: 20px; margin-top: -8px; display: block; color: #a6ffaa; ">
                {{ $t('game.showResults') }}
              </strong>

              <strong v-show="currentRoomGame.pause">
                <span style="font-size:14px;  color: #bf4141">{{ $t('game.gamePaused') }}</span>
            </strong>

            </div>
          </div>
        </div>
        <div class="my-bet-list">
          <h6 v-show="myBet.totalBetAmount > 0">{{ $t('PARITY.MY_CHOICE') }}</h6>
          <ul v-show="myBet.totalBetAmount > 0">
            <li v-for="play in this.myBet.currentPlays" :key="play.id" :class="getBetTargetContent(play.betTarget) === 'S' || getBetTargetContent(play.betTarget) === 'B' ? 'li-r' : 'li-g'">
              <strong>{{ getBetTargetContent(play.betTarget) }}</strong>
              <span>{{ $filters.currencySymbol(play.originBetAmount) }}</span>
            </li>
          </ul>
        </div>
      </div>
      
      <div class="rg-body">

        <!--**************************************** 下注目标 ****************************************-->
        <div class="bet-container">
          <h5 style="margin-top: 35px;">{{ $t('SICBO.BET_BIG_OR_SMALL') }} (1:2)</h5>

          <ul class="c-3">
           
            <li class="g">
              <div v-show="currentRoomGame.stage===stageEnum.wait" class="disabled">
              </div>
              <div class="p15" @click="callUpBetPanel(betBigSmallEnum.small)">
                <h5></h5>
                <strong>{{ $t('SICBO.BET_SMALL') }}</strong>
              </div>
            </li>

            <li class="r">
              <div v-show="currentRoomGame.stage===stageEnum.wait" class="disabled">
              </div>
              <div class="p15" @click="callUpBetPanel(betBigSmallEnum.big)">
                <h5></h5>
                <strong>{{ $t('SICBO.BET_BIG') }}</strong>
              </div>
            </li>

          </ul>

          <h5>{{ $t('SICBO.BET_NUMBER') }} (1:6)</h5>
          <div class="c-6-div">
            <ul class="c-6">
              <li class="s1" @click="callUpBetPanel('1')"><strong></strong><p>{{ $t('SICBO.BET') }}</p><span></span></li>
              <li class="s2" @click="callUpBetPanel('2')"><strong></strong><p>{{ $t('SICBO.BET') }}</p><span></span></li>
              <li class="s3" @click="callUpBetPanel('3')"><strong></strong><p>{{ $t('SICBO.BET') }}</p><span></span></li>
              <li class="s4" @click="callUpBetPanel('4')"><strong></strong><p>{{ $t('SICBO.BET') }}</p><span></span></li>
              <li class="s5" @click="callUpBetPanel('5')"><strong></strong><p>{{ $t('SICBO.BET') }}</p><span></span></li>
              <li class="s6" @click="callUpBetPanel('6')"><strong></strong><p>{{ $t('SICBO.BET') }}</p><span></span></li>
            </ul>
            <div v-show="currentRoomGame.stage===stageEnum.wait || me.vipLevel < 1"  class="disabled">
              <p v-show="me.vipLevel < 1"  @click="vipPopupClick">{{ $t('SICBO.VIP_1_UNLOCK') }}</p>
              <p v-show="me.vipLevel < 1">{{ $t('PARITY.VIP') }}</p>
              <!-- <p v-show="me.vipLevel < 1" @click="vipPopupClick">{{ $t('PARITY.VIP') }}</p> -->
            </div>
          </div>
        </div>

        <div class="tabs tabs-3">
          <strong style="width: 100%;height: 25px;" class="a">{{ $t('PARITY.RECORD') }}</strong>
        </div>

        <div class="result-announcement">
          <!--**************************************** 历史记录 ****************************************-->
          <div>
            <ul class="c-10">
              <li v-for="history in currentRoomGame.histories" :key="history.serialNum" :class="getResultClass(history.result)">
                <span>{{ history.shortSerialNum }}</span><strong> <!-- {{ history.result }}--> </strong>
              </li>
              <li class="x"><span>{{ getSerialNumPostfix(currentRoomGame.serialNum) }}</span><strong>?</strong></li>
            </ul>
          </div>

        </div>


        <div class="tabs tabs-2">
          <strong :class="orderTabActive ? 'a' : ''" @click="playersList">{{ $t('PARITY.EVERYONE_ORDER') }}</strong>
          <strong :class="orderTabActive ? '' : 'a'" @click="myHistoryList">{{ $t('PARITY.MY_ORDER') }}</strong>
        </div>
        <div class="result-announcement">
          <div class="g-players">

            <!--**************************************** 所有玩家的订单 ****************************************-->
            <ul v-show="orderTabActive">
              <li>
                <span class="l" style="width:130px;color: #eeff9a;">{{ $t('PARITY.USER') }}</span>
                <span class="l" style="width:70px;color: #eeff9a;">{{ $t('PARITY.SELECT') }}</span>
                <span class="r" style="color: #eeff9a;">{{ $t('PARITY.POINT') }}</span>
              </li>
              <li v-for="(item, index) in usersBetList" :key="index">
                <span class="l" style="width:130px"><img :src=" require('../assets/images/' + imgUrl + 'avatar/' + item.avatar.slice(item.avatar.length-6))" />{{ item.mobile }}</span>
                <span class="l" style="width:70px;">

                  <div v-if="item.betType === 'number'" class="dots number">
                    <strong>{{ item.betTarget }}</strong>
                  </div>

                  <div v-if="item.betType === 'big_small' && item.betTarget === 'big' " class="dots big">
                    <strong>B</strong>
                  </div>

                  <div v-if="item.betType === 'big_small' && item.betTarget === 'small' " class="dots small">
                    <strong>S</strong>
                  </div>

                </span>
                <span class="r" style="color: #bbaf78;">{{ $filters.currencySymbol(item.originBetAmount) }}</span>
              </li>
            </ul>

            <!--**************************************** 我的订单 ****************************************-->
            <ul v-show="!orderTabActive">
              <li>
                <span class="l" style="width:110px;color: #eeff9a;">{{ $t('PARITY.SERIAL_NUM') }}</span>
                <span class="l" style="width:40px;color: #eeff9a;">{{ $t('PARITY.POINT') }}</span>
                <span class="l" style="width:40px;color: #eeff9a;">{{ $t('PARITY.SELECT') }}</span>
                <span class="l" style="width:40px;color: #eeff9a;">{{ $t('PARITY.RESULT') }}</span>
                <span class="r" style="color: #eeff9a;">{{ $t('PARITY.AMOUNT') }}</span>
              </li>

              <li v-for="myPlay in myPlays" :key="myPlay.id" class="mb10">
                <span class="l" style="width:110px">{{ myPlay.serialNum }}</span>
                <span class="l" style="width:40px">{{ $filters.currencySymbol(myPlay.originBetAmount) }}</span>
                <span class="l" style="width:40px">
                  <div>
                    <strong>{{ getBetTargetContent(myPlay.betTarget) }}</strong> 
                  </div>
                </span>
                <span class="l" style="width:40px;">
                  <div :class="'dots '+ getResultClass(myPlay.result)">
                    <strong v-if="getResultClass(myPlay.result) !== 'x'">{{ myPlay.result }}</strong>
                    <strong v-else>?</strong>
                  </div>
                </span>
                <span v-if="myPlay.win" class="r" style="color: #015c00;">+ {{ $filters.currencySymbol(myPlay.winAmount) }}</span>
                <span v-else class="r" style="color: #015c00;">&nbsp;&nbsp;</span>
              </li>
              <li style="text-align: center;" @click="myPlayList">{{ $t('game.more') }} >></li>
              
            </ul>
            
          </div>
        </div>
      </div>


      <nut-overlay v-model:visible="overlayShow" :close-on-click-overlay="false" :z-index="2000" overlay-class="overlay-div overlay-dice">
      </nut-overlay>

    </div>

    <!--**************************************** 下注面板 ****************************************-->
    <nut-popup position="bottom" closeable round v-model:visible="showBetPanel" pop-class="betPanelBox"  @close="bettingPanelClose">
      <div class="betting-panel">
        <h2>{{ $t('PARITY.JOIN') }} <strong>{{ myBet.betTarget }}</strong> 
          <span v-if="myBet.betType === 'number'" class="btn-f12">1:6</span>
          <span v-else class="btn-f12">1:2</span>
        </h2>
        <div class="panel-hd">
          <div class="l">
            <span>{{ $t('PARITY.BALANCE') }}:</span>
            <br/>
            <strong>{{ $filters.currencySymbol(parseAmount(me.availableAmount)) }}</strong>
            <nut-button size="small" type="primary" @click="rechargeClick" style="min-winth:70px">
              {{ $t('home.recharge') }}
            </nut-button>
          </div>
          <div class="r">
            <li v-if="calculateFee(myBet.betAmount)">
              <span>{{ $t('PARITY.FEE') }}({{ currentRoom.feeRatio }}%):</span>
              <strong>{{ calculateFee(myBet.betAmount) }}</strong>
            </li>
            <li v-if="calculateActualBetAmount(myBet.betAmount)"><span>{{ $t('PARITY.AMOUNT') }}:</span><strong>{{ calculateActualBetAmount(myBet.betAmount) }}</strong></li>
          </div>
        </div>

        <div class="panel-bd">
          <h6>{{ $t('PARITY.CONTRACT_POINT') }}</h6>
          
          <div class="btnbox">
            <button v-for="(item, index) in currentRoomGame.recommendBetAmountList" :key="index" class="btn"
                    @click="btnBetClick(item)"
                    :disabled="this.currentRoomGame.stage !== stageEnum.bet || item > this.currentRoomGame.betAmountMax || item < this.currentRoomGame.betAmountMin">{{ item }}
            </button>
            <button class="btn" @click="btnBetClick(me.availableAmount)"
                    :disabled="this.currentRoomGame.stage !== stageEnum.bet">{{ $t('PARITY.ALL') }}
            </button>
          </div>

          <h6>{{ $t('PARITY.NUMBER') }}</h6>
          <div class="inpbox">
            <button class="btn-bet btn-sub" @click="btnSubtract"
                    :disabled="this.currentRoomGame.stage!==stageEnum.bet">
              -
            </button>
            <input v-model="myBet.betAmount" class="inp-bet" type="number"  @click="showKeyBoard"/>
            <button class="btn-bet btn-plus" @click="btnAddition"
                    :disabled="this.currentRoomGame.stage!==stageEnum.bet">
              +
            </button>
          </div>

          <div class="btn-confirm">
            <nut-button @click="bet" block type="info" class="btn-bg-g"
                        :disabled="this.currentRoomGame.stage!==stageEnum.bet">
              <nut-icon v-if="myBet.betting" name="loading" size="25" style="margin-top: 15px;"></nut-icon>
              <span v-else>{{ $t('PARITY.CONFIRM') }} {{ $filters.currencySymbol(myBet.betAmount) }}</span>
            </nut-button>
          </div>
        </div>
      </div>


    </nut-popup>
    <!--**************************************** 结算面板 ****************************************-->
    <nut-popup pop-class="pop-inventory" v-model:visible="showSettlePanel" @close="closeSettlePanel">
      <div :class="settle.totalWinAmount > 0 ? 'main win' : 'main'">
        <div class="hd">
          <strong :class="getResultClass(settle.result)">
            {{ settle.result }}
          </strong>
        </div>
        <ul>
          <li><span>{{ $t('PARITY.PERIOD') }}</span><strong>{{ settle.serialNum }}</strong></li>
          <li>
            <span>{{ $t('PARITY.WIN') }}</span>
            <strong v-if="settle.totalWinAmount > 0" class="c-g">+{{ $filters.currencySymbol(settle.totalWinAmount) }}</strong>
            <strong v-else class="c-r">+{{ $filters.currencySymbol(settle.totalWinAmount) }}</strong>
          </li>
        </ul>
        <div class="bd">
          <div v-for="play in settle.plays" :key="play.id" class="bd-c">
            <ol>
              <li>
                <span>{{ $t('PARITY.SELECT') }}</span>
                <strong>
                  <span>{{ getBetTargetContent(play.betTarget) }}</span>
                </strong>
              </li>
              <li><span>{{ $t('PARITY.POINT') }}</span><strong>{{ $filters.currencySymbol(play.originBetAmount) }}</strong></li>
              <li>
                <span>{{ $t('PARITY.WIN') }}</span>
                <strong v-if="play.winAmount > 0" class="c-g">+{{ $filters.currencySymbol(play.winAmount) }}</strong>
                <strong v-else class="c-r">+{{ $filters.currencySymbol(play.winAmount) }}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="closeSettlePanel">{{ $t('global.okText') }}</nut-button>
        </div>
      </div>
    </nut-popup>

    <!--**************************************** 卡点弹框 ****************************************-->
    <nut-popup pop-class="pop-withdrawal" v-model:visible="withdrawalPopup">
      <div class="hd">{{ $t('game.pleaseWithdraw1') }}</div>
      <div class="bd">
        {{ $t('game.pleaseWithdraw2') }}
        <strong style="color:#208f0a">{{ commonInfo.forceWithdrawThreshold }}+ </strong>&#127881;&#127881;&#127881;
        {{ $t('game.pleaseWithdraw3') }}<br/>
        <strong>{{ $t('game.pleaseWithdraw4') }}</strong>
        {{ $t('game.pleaseWithdraw5') }}
      </div>
      <div class="btn">
        <nut-button type="primary" @click="withdrawClick">{{ $t('game.go') }}</nut-button>
      </div>
    </nut-popup>

    <!--**************************************** 补满提醒弹框 ****************************************-->
    <nut-popup pop-class="pop-invite" v-model:visible="invitePopup">
      <div class="hd">{{ $t('game.info1') }}</div>
      <div class="bd">
        {{ $t('game.info2') }}<br/>
        <strong>{{ commonInfoAttr('registerReward') }}</strong>
        <div class="app-task" v-show="appTaskStatus === 'forbid' || appTaskStatus === 'can_receive'" @click="downloadApp">
          {{ $t('global.DOWNLOAD_APP_TEXT2') }}
          <span>{{ $filters.currencySymbol(appTaskReward) }}</span>
          {{ $t('global.DOWNLOAD_APP_TEXT3') }}
        </div>
      </div>
      <div class="btn">
        <nut-button type="primary" @click="invitePopup = false">{{ $t('game.gotitBtn') }}</nut-button>
      </div>
    </nut-popup>

    <!--**************************************** 游戏规则 ****************************************-->
    <nut-popup position="top" closeable round :style="{ height: '70%' }" v-model:visible="rulePopup">
        <GameRulesDice></GameRulesDice>
    </nut-popup>

    <!--**************************************** vip权益 ****************************************-->
    <nut-popup pop-class="pop-vip" v-model:visible="vipPopup">
      <VipRules :myLevel="me.vipLevel" :popTitle="popTitle" :tabActive="tabActive"></VipRules>
      <div class="login-btn m10">
        <nut-button block type="info" @click="rechargeClick" >{{$t('recharge.levelUpVip')}}</nut-button>
      </div>
    </nut-popup>

    <!--**************************************** tg 提醒 ****************************************-->
    <nut-popup pop-class="pop-vip pop-vip-lobby" v-model:visible="vipTgDialog" :z-index="100">
      <VipRules :myLevel="me.vipLevel" :popTitle="popTitle"></VipRules>
      <div class="bd">
        {{ $t('global.Prediction_Channel_Text') }}<br/>
      </div>
      <div class="btn" style="margin: 0;">
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="vipTgDialog = false">{{ $t('global.Got_It') }}</nut-button>
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="rechargeClick">{{ $t('global.Upgrade') }}</nut-button>
      </div>
    </nut-popup>

    <!--**************************************** 结果展示 ****************************************  -->
    <nut-popup pop-class="pop-top-result2" position="left" :closeable="false" :overlay="false" v-model:visible="showTopResult" :style="{ height: '160px' }" >
      <div class="bd">
        <div class="t1">
          <strong :class="getResultClass(currentRoomGame.result)"></strong>
        </div>
        <div class="t2">
            <strong>{{ currentRoomGame.result }}</strong>
            <span>Result</span>
        </div>
      </div>
    </nut-popup>

    <!--**************************************** Big win ****************************************-->
    <nut-popup pop-class="pop-top-bigwin" v-model:visible="bigwinData.show" position="left" :closeable="true" :overlay="false">
      <div class="bd">
        <div class="t1"><img class="avatar" :src="require('../assets/images/' + imgUrl + 'bigwin.png')" >
            <span>{{ bigwinData.data.mobile }}</span>
          </div>
        <div v-if="bigwinData.data.appTypeCode === 'crash'" class="t2 crash"><span>{{ bigwinData.data.crashEscapeRatio }}x</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'parity'" class="t2 parity"><span>{{ bigwinData.data.parityRoomName }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'wheel'" class="t2 wheel"><span> </span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'mine'" class="t2 mine"><span>{{ bigwinData.data.mineRowCount }} X {{ bigwinData.data.mineRowCount }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'andar'" class="t2 andar"><span></span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'dice'" class="t2 dice"><span></span></div>
        <div class="t3"><span>{{$t('PARITY.CONGRSTS_XXX_WIN_XXX_2')}}</span><strong>{{ bigwinData.data.winAmount }}</strong></div>
      </div>
    </nut-popup>

    <!--**************************************** Broadcast Msg ****************************************-->
    <nut-popup pop-class="pop-top-broadcast-msg" v-model:visible="broadcastMsgData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
      <div class="bd">
        <div class="t4" v-if="broadcastMsgData.type === 'parity_prediction'">&nbsp;</div>
        <div class="t5"><span>{{ broadcastMsgData.message}}</span></div>
      </div>
    </nut-popup>

    <tabbar v-show="!hideMenu" :activeIndex="tabbarActiveIndex"></tabbar>
  </div>

</template>

<script>
import {reactive, toRefs} from "vue";
import {useRouter} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import {Toast} from "@nutui/nutui";
import {isBlank, isNotBlank, isTrue} from "@/assets/js/commonUtil";
import router from "@/router";
import {commonEvent, createSocket, disconnectSocket,gameType} from "@/assets/js/game";
import axios from "axios";
import {getCommonInfoAttr} from "@/assets/js/commonInfo";
import GameRulesDice from "../components/GameRulesDice.vue";
import VipRules from "../components/VipRules2.vue";
import { getCountry, setLastTimeGameName } from "@/assets/js/environment";
export default {
  name: "diceHome",
  components: {
    tabbar: TabbarMain,
    GameRulesDice: GameRulesDice,
    VipRules: VipRules
  },
  data() {
    return {
      imgUrl: '',
      commonInfo: null,
      country: '',
      template: 's1',
      tabs1: true,
      tabs2: false,
      tabs3: false,
      tabs4: false,
      vipTgDialog: false,
      waitPredictionCome: false,
      hideMenu: false,
      am_saizi_0: false, //色子显示
      am_saizi_result: 0, //色子点数
      am_saizong_1: false, //色盅晃动
      am_girl: 0,
      serialNum:null,  //当前游戏序号
      streakExp:null,  //当前游戏连胜表达式
      betDeadline: 15, //下注时间
      waitDeadline: 4, //下注时间
      popTitle: this.$t('recharge.popTitle_1'),
      tabActive: 'parity',
      rulePopup: false,
      vipPopup: false,
      showBetPanel: false,
      showSettlePanel: false,
      orderTabActive: true,
      showAnimation1: false,
      showAnimation2: false,
      keyBoard: false,
      listNoData: true,
      showTopResult: false,
      overlayShow: true,
      userRechargeList: [],
      historyGame: {
        current: 1,
        total: null,
        hasNext: null,
        list: []
      },
      bigwinData: {
        show: false,
        start: false,
        list: [],
        data: {}
      },
      broadcastMsgData: {
        show: false,
        type: '',
        message: '',
      },
      currentRoom: {},
      currentRoomGame: {
        pause: false,
        serialNum: null,
        betDeadline: null,
        waitDeadline: null,
        endDeadline: null,
        stage: null,
        result: null,
        histories: [],
        defaultBetAmount: 20,
        betAmountMin: 10,
        betAmountMax: 100000,
        recommendBetAmountList:[],
      },
      settle: {
        serialNum: null,
        result: null,
        plays: [],
        totalWinAmount: 0,
        totalProfitAmount: 0,
        needWithdraw: false,
      },
      withdrawalPopup: false,
      showDialogFill: true,
      invitePopup: false,
      socket: null,
      binanceWebsocket: null,
      //event
      eventEnum: {
        startBet: "s_start_bet", //开始下注阶段 
        startWait: "s_start_wait", //开始等待阶段  
        startEnd: "s_start_end", //开始结束阶段
        pause: "s_pause", //游戏暂停
        betNotify: "s_bet", //任意玩家的下注通知
        settle: "s_settle", //结算信息
      },
      //游戏阶段
      stageEnum: {
        bet: "bet",
        wait: "wait",
        end: "end",
        pause: "pause"
      },
      //下注类型
      betTypeEnum: {
        bigSmall: "big_small",
        number: "number"
      },
      //下注大小
      betBigSmallEnum: {
        big: "big",
        small: "small",
      },
      //我的信息
      me: {
        id: null,
        vipLevel: 0,
        availableAmount: 0,
        rechargeAmount: null,
        withdrawAmount: null,
        //是否处于新手流程
        inRookieProcess: null,
        streak: 0, //连胜
      },
      //下注操作
      myBet: {
        betType: null,
        betTarget: null,
        betAmount: 10,
        betting: false,
        totalBetAmount: 0,
        //本局下注成功的订单
        currentPlays: [],
        //玩家本局已经下注的订单列表
        userOrders: [],
      },
      //房间玩家信息
      players: [],
      //我的历史订单
      myPlays: [],
      //下注列表
      usersBetList: [],
      appTaskStatus: '',
      appTaskReward: '',
    };
  },
  created() {
    this.country = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
    this.template = document.querySelector('body').getAttribute('s')
    //随机数
    for (var i = 0; i < 10; i++) {
      const text = this.$t('PARITY.CONGRSTS_XXX_WIN_XXX_1') + Math.round(600 + (999 - 600) * Math.random()) + '***' + Math.round(1000 + (9999 - 1000) * Math.random()) + this.$t('PARITY.CONGRSTS_XXX_WIN_XXX_2') + Math.round(10 + (2999 - 1) * Math.random())
      this.userRechargeList[i] = text
    }
  },
  setup() {
    const state = reactive({
      tabbarActiveIndex: 2,
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      telegramClick(){
        const commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
        window.open(commonInfo.telegramChannelUrl,"_self");
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },

  // ======================================== mounted ========================================
  mounted() {
    /**
     * 初始化接口
     */
    Toast.loading('Loading...', {
      id: "loading",
      duration: 0,
      bgColor: "rgba(0, 0, 0, 0.9)",
      cover: true,
    });

    axios.get("/api/dice/init").then((res) => {
        const data = res.data.data;
        //app下载奖励
        if(isNotBlank(data.appTaskStatus)){
          this.appTaskReward = data.appTaskReward
          this.appTaskStatus = data.appTaskStatus
        }
        //游客id
        if (isNotBlank(data.vtoken)) {
          localStorage.setItem("vtoken", data.vtoken);
        }
        //初始化socket（依赖房间信息）
        this.initSocket();
        //当前游戏序号
        this.currentRoomGame.serialNum = data.serialNum;
        //当前游戏阶段
        this.currentRoomGame.stage = data.stage;
        
        this.currentRoomGame.recommendBetAmountList = data.recommendBetAmountList
        //游戏是否暂停
        if (data.stage === this.stageEnum.pause) {
          this.currentRoomGame.pause = true;
        }
        this.currentRoomGame.betAmountMax = data.betAmountRange.max;
        this.currentRoomGame.betAmountMin = data.betAmountRange.min;

        this.loadHistory();

        //当前游戏下注阶段剩余毫秒数
        if (isNotBlank(data.betRemainMillis)) {
          let betRemainMillis = data.betRemainMillis;
          this.currentRoomGame.betDeadline = Date.now() + betRemainMillis;
          this.usersBetList = []
          //显示本局其他玩家下注列表
          for (let k in data.currentOrders) {
            let t = betRemainMillis - data.currentOrders[k].betWaitMillis
            if( t > 0 ){
              setTimeout(() => {
                if (this.usersBetList.length > 30) {
                  this.usersBetList.pop()
                }
                this.usersBetList.unshift(data.currentOrders[k])
              }, t)
            }else {
              this.usersBetList.unshift(data.currentOrders[k])
            }
          }
        }

        //当前游戏等待阶段剩余毫秒数
        if (isNotBlank(data.waitRemainMillis)) {
          let waitRemainMillis = data.waitRemainMillis;
          this.currentRoomGame.waitDeadline = Date.now() + waitRemainMillis;
          this.currentRoomGame.stage = this.stageEnum.wait;
          this.currentRoomGame.stage = 'next'
        }

        //当前游戏结束阶段剩余毫秒数
        if (isNotBlank(data.endRemainMillis)) {
          let endRemainMillis = data.endRemainMillis;
          this.currentRoomGame.endDeadline = Date.now() + endRemainMillis;
          this.currentRoomGame.stage = 'next'
        }




        //玩家信息
        this.me.vipLevel = data.vipLevel;
        this.me.availableAmount = data.availableAmount;
        this.me.rechargeAmount = data.rechargeAmount;
        this.me.withdrawAmount = data.withdrawAmount;
        this.me.inRookieProcess = data.inRookieProcess;
        this.me.streak = data.streak;
        //当前游戏连胜表达式
        this.streakExp = data.streakExp; 
        //是否卡点
        if (isTrue(data.needWithdraw)) {
          this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'));
        }
        this.showFillUpTipsIfSuitable();
        //我的本局已经下注的订单列表
        this.myBet.userOrders = data.userOrders;
        Toast.hide("loading");
        //设置最后进入的游戏
        setLastTimeGameName('dice')
    });

  },

  // ======================================== beforeRouteLeave ========================================
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket(gameType.DICE, this.socket);
    Toast.hide()
  },

  // ======================================== methods ========================================
  methods: {

    //打开TG链接
    toTelegram(){
        if(this.me.vipLevel > 0){
          window.open("https://t.me/yoswin_prediction_official","_self");
        }else{
          this.vipTgDialog = true
        }
    },

    rulePopClick() {
      this.rulePopup = true
    },
    rechargeClick() {
      if(this.commonInfo.firstRechargeAvailable){
        router.push('/firstRechargeBonus');
      }else{
        router.push('/rechargeBalance');
      }
    },
    vipPopupClick(){
      this.vipPopup = true
    },
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },

    //转化时间
    convertTime(timeSerial) {
      return timeSerial.substring(0, 2) + ':' + timeSerial.substring(2, 4)
    },

    // ======================================== 下注 ========================================
    /**
     * 显示下注面板
     * @param betTarget
     */
    callUpBetPanel(betTarget) {
      if (betTarget === this.betBigSmallEnum.small || betTarget === this.betBigSmallEnum.big) {
        this.myBet.betType = this.betTypeEnum.bigSmall;
      }else {
        //下注的数字
        this.myBet.betType = this.betTypeEnum.number;
      }
      this.myBet.betTarget = String(betTarget);

      this.showBetPanel = true;
      this.hideMenu = true;
    },
    /**
     * 下注
     */
    bet() {
      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= 0) {
        Toast.fail(this.$t('PARITY.PLEASE_CONFIRM_YOUR_INPUT'))
        return;
      }

      this.myBet.betting = true;
      axios.post("/api/dice/bet",
          {
            betType: this.myBet.betType,
            betTarget: this.myBet.betTarget,
            betAmount: this.myBet.betAmount
          }
      ).then((res) => {
        this.myBet.betting = false;
        let result = res.data;
        let data = result.data;
        if (result.code === 0) {
          this.showBetPanel = false;
          Toast.success(this.$t('global.successfully') + ' ' + this.myBet.betAmount);
          this.me.availableAmount = data.availableAmount;
          this.myBet.currentPlays = data.orders;

          //重新计算总下注金额
          this.myBet.totalBetAmount = 0;
          this.myBet.currentPlays.forEach(myPlay => {
            this.myBet.totalBetAmount = this.myBet.totalBetAmount + myPlay.originBetAmount;
          });
        } else if (result.bizCode === 'GAME_BET_NEED_WITHDRAW') {
          //卡点
          this.showBetPanel = false;
          this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'))
        }
      });
      this.hideMenu = false;
    },
    //按钮下注
    btnBetClick(a) {
      if (a > this.currentRoomGame.betAmountMax) {
        this.myBet.betAmount = Math.floor(this.currentRoomGame.betAmountMax)
      } else if (a < this.currentRoomGame.betAmountMin) {
        this.myBet.betAmount =  Math.floor(this.currentRoomGame.betAmountMin)
      } else {
        this.myBet.betAmount = a
      }
    },
    //关闭下注面板
    bettingPanelClose() {
      if(isBlank(this.myBet.betAmount)){
        this.myBet.betAmount = this.currentRoomGame.defaultBetAmount
      }
      this.hideMenu = false;
    },
    showKeyBoard() {
      this.keyBoard = true
    },
    //下注加减
    btnAddition() {
      if (isBlank(this.myBet.betAmount)) {
        this.myBet.betAmount = this.currentRoomGame.defaultBetAmount
        return
      }
      if (this.myBet.betAmount <= 99) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 10)
      } else if (this.myBet.betAmount <= 999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 100)
      } else if (this.myBet.betAmount <= 9999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000)
      } else if (this.myBet.betAmount <= 99999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 10000)
      } else if (this.myBet.betAmount <= 999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 100000)
      } else if (this.myBet.betAmount <= 9999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000000)
      } else if (this.myBet.betAmount <= 9999999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000000)
      }
      if (this.myBet.betAmount > this.currentRoomGame.betAmountMax) {
        Toast.text("Maxmum of " + Math.floor(this.currentRoomGame.betAmountMax));
        this.myBet.betAmount = Math.floor(this.currentRoomGame.betAmountMax)
      }
    },
    btnSubtract() {
      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= this.currentRoomGame.betAmountMin) {
        Toast.text("Minimum of " + this.currentRoomGame.betAmountMin);
        return
      }
      if (this.myBet.betAmount <= 100) {
        if (this.myBet.betAmount > this.currentRoomGame.betAmountMin) this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 10)
      } else if (this.myBet.betAmount <= 1000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 100)
      } else if (this.myBet.betAmount <= 10000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000)
      } else if (this.myBet.betAmount <= 100000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 10000)
      } else if (this.myBet.betAmount <= 1000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 100000)
      } else if (this.myBet.betAmount <= 10000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000000)
      } else if (this.myBet.betAmount <= 1000000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000000)
      }
      if (this.myBet.betAmount < this.currentRoomGame.betAmountMin) {
        this.myBet.betAmount = Math.floor(this.currentRoomGame.betAmountMin)
      }
    },
    //跳转到我的下注历史
    myPlayList() {
      router.push({
        path: "myPlayListDice"
      });
    },
    // ======================================== 结算 ========================================
    /**
     * 打开结算面板
     */
    callUpSettlePanel() {
      this.showSettlePanel = true

    },
    /**
     * 关闭结算面板
     */
    closeSettlePanel() {
      this.showSettlePanel = false;
      //如果到了卡点，弹框提醒
      if (this.settle.needWithdraw) {
        this.showNeedWithdrawDialog();
      } else {
        //没到卡点
        //如果低于提醒阈值，弹出补满提醒
        this.showFillUpTipsIfSuitable();
      }
    },
    /**
     * 刷新游戏历史
     */
    loadHistory() {
      axios({
        url: "/api/dice/history/paginate",
        method: "get",
        params: { current: 1 }
      }).then((res) => {
        this.currentRoomGame.histories = res.data.data.records
        if(this.currentRoomGame.histories.length >= 10){
          this.currentRoomGame.histories.splice(0, 1);
        }
      })
    },
    /**
     * 到达卡点金额，强制提现
     */
    showNeedWithdrawDialog() {
      this.withdrawalPopup = true
    },
    /**
     * 提示邀请补满
     */
    showFillUpTipsIfSuitable() {
      const fillUpAlertThreshold = this.commonInfoAttr('fillUpAlertThreshold');
      if (this.me.rechargeAmount === 0 && this.me.availableAmount <= fillUpAlertThreshold) {
        //未充值过且钱包余额不足
        if (this.showDialogFill) {
          this.showDialogFill = false
          this.invitePopup = true
        }
      }
    },

    // ======================================== 第3个面板 ========================================
    /**
     * 其他玩家的订单
     */
    playersList() {
      this.orderTabActive = true
    },
    /**
     * 我的订单
     */
    myHistoryList() {
      this.orderTabActive = false
      axios({
        url: "/api/dice/order/paginate",
        method: "get",
        params: {
          current: 1,
          queryLimit: 'today'
        }
      }).then((res) => {
        const data = res.data.data;
        this.myPlays = data.records
        if (this.myPlays.length > 0) {
          this.listNoData = false;
        }
      });
    },
    // ======================================== 其他 ========================================
    /**
     * 获取游戏序号前半部分
     * @param serialNum
     * @returns {string}
     */
    getSerialNumPrefix(serialNum) {
      if (isBlank(serialNum)) {
        return '';
      }
      return serialNum.substring(0, 11);
    },
    /**
     * 获取游戏序号后半部分
     * @param serialNum
     * @returns {string}
     */
    getSerialNumPostfix(serialNum) {
      if (isBlank(serialNum)) {
        return '?';
      }
      return serialNum.substring(11, 15);
    },
    parseAmount(amount) {
      if (isBlank(amount)) {
        return null;
      }
      return this.$math.format(amount, (value) => value.toFixed(2));
    },
    /**
     * 计算手续费
     * @param betAmount
     * @returns {string}
     */
    calculateFee(betAmount) {
      if (isBlank(betAmount) || isBlank(this.currentRoom.feeRatio)) {
        return null;
      }
      return this.$math.format(
          this.$math.divide(
              this.$math.multiply(
                  this.$math.bignumber(betAmount),
                  this.$math.bignumber(this.currentRoom.feeRatio)
              ),
              this.$math.bignumber(100)
          ),
          (value) => value.toFixed(2));
    },
    /**
     * 计算实际下注金额
     * @param betAmount
     * @returns {string}
     */
    calculateActualBetAmount(betAmount) {
      if (isBlank(betAmount)) {
        return null;
      }
      const fee = this.calculateFee(betAmount);
      if (isBlank(fee)) {
        return null;
      }

      return this.$math.format(
          this.$math.subtract(
              this.$math.bignumber(betAmount),
              this.$math.bignumber(fee)
          ),
          (value) => value.toFixed(2));
    },
    commonInfoAttr(key) {
      return getCommonInfoAttr(key);
    },
    withdrawClick() {
      router.push('/withdrawalBalance?amountType=coin');
    },

    /**
     * 根据下注目标返回小球显示的内容
     * @param betTarget
     * @returns {string}
     */
    getBetTargetContent(betTarget) {
      if (isBlank(betTarget)) {
        return '?';
      }
      switch (betTarget) {
        case this.betBigSmallEnum.small:
          return 'S';
        case this.betBigSmallEnum.big:
          return 'B';
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          return betTarget;
      }
    },
    /**
     * 根据结果返回Class
     * @param result
     * @returns {string}
     */
    getResultClass(result) {
      switch (result) {
        case 1:
          return 'sz1';
        case 2:
          return 'sz2';
        case 3:
          return 'sz3';
        case 4:
          return 'sz4';
        case 5:
          return 'sz5';
        case 6:
          return 'sz6';
      }
    },

    // ======================================== websocket ========================================
    initSocket() {
      if (this.socket != null) {
        return;
      }
      
      //创建
      this.socket = createSocket(gameType.DICE,this.bigwinData, this.broadcastMsgData);

      /**
       * 就绪
       */
      this.socket.on(commonEvent.ready, () => {
        this.socket.emit(commonEvent.join);
        this.overlayShow = false
      });

      /**
       * 进入下注阶段
       */
      this.socket.on(this.eventEnum.startBet, (event) => {
        //清空下注信息
        this.myBet.currentPlays = [];
        this.myBet.totalBetAmount = 0;

        this.currentRoomGame.stage = this.stageEnum.bet;
        this.currentRoomGame.pause = false;
        this.currentRoomGame.serialNum = event.serialNum;
        this.currentRoomGame.betDeadline = Date.now() + this.betDeadline * 1000;

        this.usersBetList = []

        //刷新玩家列表
        this.players.length = 0;
        //显示玩家下注列表
        for (let k in event.orders) {
          setTimeout(() => {
            if (this.usersBetList.length > 30) {
              this.usersBetList.pop()
            }
            this.usersBetList.unshift(event.orders[k])
          }, event.orders[k].betWaitMillis)
        }
        this.waitPredictionCome = true;
      });

      /**
       * 进入等待阶段
       */
      this.socket.on(this.eventEnum.startWait, () => {
        this.currentRoomGame.stage = this.stageEnum.wait;
        this.currentRoomGame.pause = false;
        this.currentRoomGame.waitDeadline = Date.now() + this.waitDeadline * 1000;
        this.am_saizong_1 = true; //色盅动画
        this.am_girl = 1;
        setTimeout(() => {
          this.am_saizi_0 = true; //显示色子
          this.am_girl = 3;
          setTimeout(() => {
            this.am_saizi_0 = false //色子动画
            this.am_girl = 2;
          },2000)
        }, 4000)
      });

      /**
       * 进入结束阶段
       */
      this.socket.on(this.eventEnum.startEnd, (event) => {
        this.currentRoomGame.stage = this.stageEnum.end;
        const serialNum = event.serialNum;
        this.am_saizi_result = Number(event.result); //色子结果
        setTimeout(() => {
          this.am_saizong_1 = false; //色盅动画
          this.am_saizi_result = 0
          this.am_girl = 0;
          this.currentRoomGame.stage = '';
        }, 3000)
        
        if(this.myBet.currentPlays.length === 0){
          setTimeout(() => {
            this.showTopResult = true;
              setTimeout(() => {
              this.showTopResult = false;
            }, 3000);
          }, 1000);

        }
        this.currentRoomGame.pause = false;
        this.currentRoomGame.result = event.result;
        
        //历史列表
        this.currentRoomGame.histories.push({
          serialNum: serialNum,
          shortSerialNum: this.getSerialNumPostfix(serialNum),
          result: event.result
        });
        if(this.currentRoomGame.histories.length >= 10){
          this.currentRoomGame.histories.splice(0, 1);
        }
        // if (serialNum.substring(serialNum.length - 1, serialNum.length) === '0') {
        //   //开的序号结尾是0，则需要删除头10个元素
        //   this.currentRoomGame.histories.splice(0, 10);
        // }
      });
      /**
       * 结算信息
       */
      this.socket.on(this.eventEnum.settle, (event) => {
        this.me.availableAmount = event.availableAmount;
        this.settle.serialNum = event.serialNum;
        this.settle.result = event.result;
        this.settle.plays = event.orders;
        this.settle.totalWinAmount = event.totalWinAmount;
        this.settle.totalProfitAmount = event.totalProfitAmount;
        this.settle.needWithdraw = event.needWithdraw;
        setTimeout(() => {
          this.callUpSettlePanel();
        }, 2000);
      });
    },
    //下载app
    downloadApp(){
      window.open(getCommonInfoAttr('appDownloadUrl'))
    }
  },
};
</script>

<style scoped src="../assets/css/game_sicb.css"></style>
<style scoped></style>
<style>
.pop-inventory { padding: 0 !important; width: 280px !important; border-radius: 15px;background: none !important; overflow: initial !important;}
.betPanelBox .nutui-popup__close-icon { color: #fff !important;}
.pop-forecaster{ width: 96% !important; height: 460px !important;}
.pop-top-result2{ background:none !important;  width: 140px !important; top:60px !important; left: 2% !important; right: auto !important;
   border-radius: 5px !important; box-shadow: 0px 0px 0px #fff !important; overflow: hidden;}
.parity-btn{ width: 120px; height: 30px;  position: absolute; left: 1px; top: 0px; z-index: 1000;
  background:#011f44 url(../assets/images/bg208.png)  center center no-repeat; background-size:110px auto; border-radius:16px;}
</style>



