<template>
<div>
  <nut-navbar
    :left-show="false"
    @on-click-icon="titIconClick"
    :title="$t('bankCard.h1')"
    titIcon="home"
    fixed=true
    class="mb0 nav-icon-right nut-navbar--fixed">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>

  <div class="login" v-if="country === 'ph'">
    
    <div class="login-bn">&nbsp;</div>
    <div class="login-bd">
      <h3>{{ edit ? editTitle : addTitle }}</h3>
      <p class="addcard-text">{{$t('bankCard.addcard_text')}}</p>
      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.GCash')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="GCashErrText">{{GCashErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.GCashPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo_ph.gCashMobile"
          @inputChange="(res) => (bankInfo_ph.gCashMobile = res)"
        />
      </div>
      
      <div class="login-btn">
        <nut-button block type="info" @click="addBankCard_PH"
          >{{$t('global.confirm')}}</nut-button
        >
      </div>
      
    </div>
  </div>
  <div class="login" v-else-if="country === 'br'">
    <div class="login-bn">&nbsp;</div>
    <div class="login-bd">
      <h3>{{ edit ? editTitle : addTitle }}</h3>
      <br/>
      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.realName')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="errText_br.realName">{{errText_br.realName}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.realName')"
          type="text"
          rule="^.{1,64}$"
          maxLength="64"
          v-model="bankInfo_br.realName"
          @inputChange="(res) => (bankInfo_br.realName = res)"
        />
      </div>

      <p class="addcard-text">{{$t('bankCard.addcard_text')}}</p>
      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.EMAIL')}}</p>
        <p class="err-info" v-show="errText_br.email">{{errText_br.email}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.EMAIL_PLACEHOLDER')"
          type="text"
          rule="^.{0,64}$"
          maxLength="64"
          v-model="bankInfo_br.email"
          @inputChange="(res) => (bankInfo_br.email = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.PHONE')}}</p>
        <p class="err-info" v-show="errText_br.phone">{{errText_br.phone}}</p>
        <login-input
          label="+55"
          label-width="40"
          :placeholder="$t('bankCard.PHONE_PLACEHOLDER')"
          type="text"
          rule="^.{0,64}$"
          maxLength="64"
          v-model="bankInfo_br.phone"
          @inputChange="(res) => (bankInfo_br.phone = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.CPF')}}</p>
        <p class="err-info" v-show="errText_br.cpf">{{errText_br.cpf}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.CPF_PLACEHOLDER')"
          type="text"
          rule="^.{0,64}$"
          maxLength="64"
          v-model="bankInfo_br.cpf"
          @inputChange="(res) => (bankInfo_br.cpf = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.CNPJ')}}</p>
        <p class="err-info" v-show="errText_br.cnpj">{{errText_br.cnpj}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.CNPJ_PLACEHOLDER')"
          type="text"
          rule="^.{0,64}$"
          maxLength="64"
          v-model="bankInfo_br.cnpj"
          @inputChange="(res) => (bankInfo_br.cnpj = res)"
        />
      </div> 
      <div class="login-btn">
        <nut-button block type="info" @click="addBankCard_BR"
          >{{$t('global.confirm')}}</nut-button
        >
      </div>
    </div>
  </div>
  <div class="login" v-else-if="country === 'vn'">
    <div class="login-bn">&nbsp;</div>
    <div class="login-bd">
      <h3>{{edit ? editTitle : addTitle}}</h3>
      <p class="addcard-text">{{$t('bankCard.addcard_text')}}</p>

      <nut-cell-group>
        <nut-cell
          :title="$t('bankCard.bankName')"
          is-link
          @click="showBankNamePopup"
          desc=" "
          class="icon-t1"
          style="padding: 3px 0px;"
        ></nut-cell>
      </nut-cell-group>

      <div class="login-inp">
        <p class="err-info" v-show="errText_vn.bankCode">{{errText_vn.bankCode}}</p>
        <login-input
          label=""
          readonly="true"
          :placeholder="$t('bankCard.bankNamePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          style="width:99% !important"
          v-model="bankInfo_vn.bankName"
          @click="showBankNamePopup"
          @inputChange="(res) => (bankInfo_vn.bankName = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999" v-if="bankInfo_vn.bankName === 'CLABE'">{{$t('bankCard.clabeNumber')}}</p>
        <p class="fs10 fc-999" v-else >{{$t('bankCard.bankAccount')}}</p>
        <p class="err-info" v-show="errText_vn.bankAccount">{{errText_vn.bankAccount}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.bankAccountPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo_vn.bankAccount"
          @inputChange="(res) => (bankInfo_vn.bankAccount = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.realName')}}</p>
        <p class="err-info" v-show="errText_vn.realName">{{errText_vn.realName}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.realNamePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo_vn.realName"
          @inputChange="(res) => (bankInfo_vn.realName = res)"
        />
      </div>
      <div class="login-btn">
        <nut-button block type="info" @click="addBankCard_VN"
          >{{$t('global.confirm')}}</nut-button>
      </div>
    </div>
  </div>

  <div class="login form-add" v-else>
    <div class="login-bn">&nbsp;</div>
    <div class="login-bd">
      <h3>{{edit ? editTitle : addTitle}}</h3>
      <p class="addcard-text">{{$t('bankCard.addcard_text')}}</p>
      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.realName')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="realNameErrText">{{realNameErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.realNamePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.realName"
          @inputChange="(res) => (bankInfo.realName = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.ifscCode')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="ifscCodeErrText">{{ifscCodeErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.ifscCodePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.ifscCode"
          @inputChange="(res) => (bankInfo.ifscCode = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.bankName')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="bankNameErrText">{{bankNameErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.bankNamePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.bankName"
          @inputChange="(res) => (bankInfo.bankName = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.bankAccount')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="bankAccountErrText">{{bankAccountErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.bankAccountPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.bankAccount"
          @inputChange="(res) => (bankInfo.bankAccount = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.upiVpa')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="upiVpaErrText">{{upiVpaErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.upiVpaPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.upiVpa"
          @inputChange="(res) => (bankInfo.upiVpa = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.email')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="emailErrText">{{emailErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.emailPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.email"
          @inputChange="(res) => (bankInfo.email = res)"
        />
      </div>

      <div class="login-btn">
        <nut-button block type="info" @click="addBankCard"
          >{{$t('global.confirm')}}</nut-button
        >
      </div>
    </div>
  </div>
</div>

    <!--**************************************** NG 弹框 ****************************************-->
    <nut-popup pop-class="pop-bankName" v-model:visible="bankNamePopup">
      <BankListVN v-if="country === 'vn'" @getDataVN="getDataVN"></BankListVN>
      <nut-button type="primary" @click="bankNamePopup = false">{{ $t('global.okText') }}</nut-button>
    </nut-popup>

</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import loginInput from "@/components/loginInput.vue";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import { getCountry } from "@/assets/js/environment";
import BankListVN from "../components/BankListVN.vue";

export default {
  components: {
    BankListVN: BankListVN,
    loginInput,
  },
  data() {
    return {
      country: '',
      editTitle: this.$t('bankCard.editTitle'),
      addTitle: this.$t('bankCard.addTitle'),
      edit:false,
      bankNamePopup: false,
      id: null,
      target: null,
      bankInfo: {
        realName: "",
        ifscCode: "",
        bankName: "",
        bankAccount: "",
        upiVpa:"",
        email: "",
      },
      bankInfo_ph: {
        gCashMobile: ""
      },
      bankInfo_br: {
        realName: "",
        cpf: "",
        phone: "",
        email: "",
        cnpj: ""
      },
      bankInfo_vn: {
        bankName: "",
        bankCode: "",
        bankAccount: "",
        realName: "",
      },
      errText_vn: {
        realName: '',
        bankCode: '',
        bankAccount: ''
      },
      errText_br: {
        realName: '',
        cpf: '',
        phone: '',
        email: '',
        cnpj: '',
      },
      realNameErrText: '',
      ifscCodeErrText: '',
      bankNameErrText: '',
      bankAccountErrText: '',
      upiVpaErrText: '',
      emailErrText: '',
      GCashErrText: '',
    };
  },

  methods: {
    getDataVN(data) {
      this.bankInfo_vn.bankName = data.name;
      this.bankInfo_vn.bankCode = data.id;
    },
    gettingData() {
      if(this.$route.query.id){
        this.edit = true
        this.id = this.$route.query.id
        if(this.country === 'ph'){
          this.bankInfo_ph.gCashMobile = this.$route.query.gCashMobile
        }else if(this.country === 'br'){
          this.bankInfo_br.realName = this.$route.query.realName
          this.bankInfo_br.cpf = this.$route.query.cpf
          this.bankInfo_br.phone = this.$route.query.phone
          this.bankInfo_br.email = this.$route.query.email
          this.bankInfo_br.cnpj = this.$route.query.cnpj
        }else if(this.country === 'vn'){
          this.bankInfo_vn.realName = this.$route.query.realName
          this.bankInfo_vn.bankName = this.$route.query.bankName
          this.bankInfo_vn.bankCode = this.$route.query.bankCode
          this.bankInfo_vn.bankAccount = this.$route.query.bankAccount
        }else {
          this.bankInfo.realName = this.$route.query.realName
          this.bankInfo.ifscCode = this.$route.query.ifscCode
          this.bankInfo.bankName = this.$route.query.bankName
          this.bankInfo.bankAccount = this.$route.query.bankAccount
          this.bankInfo.upiVpa = this.$route.query.upiVpa
          this.bankInfo.email = this.$route.query.email
        }
      }
      if(this.$route.query.target){
        this.target  = this.$route.query.target
      }
    },
    addBankCard_PH() {
      this.GCashErrText = ''
      this.realNameErrText = ''
      if(this.bankInfo_ph.gCashMobile === ""){
        this.GCashErrText = this.$t('global.errInfoRequired')
        return
      }else {
        axios.post("/api/user/bank/save", {
            id: this.id,
            bankInfo: this.bankInfo_ph
          })
          .then((response) => {
            if (response.data.code === 0) {
              Toast.success(this.$t('bankCard.success'));
              setTimeout(() => {
                if(this.target){
                  this.$router.push({
                    path: this.target,
                    query: {
                      amountType: 'coin'
                    }
                  });
                }else{
                  this.$router.push("/bankCard");
                }
              }, 1000);
            } else if (response.data.code === 1004){
              let res = response.data.data
              Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "gCashMobile":
                    this.GCashErrText = res[i].description
                    break;
                }
              }
            }
          })
      }
    },
    addBankCard_BR() {
      this.errText_br = {
        realName: '',
        cpf: '',
        phone: '',
        email: '',
        cnpj: '',
      }
      if(this.bankInfo_br.realName === ""){
        this.errText_br.realName = this.$t('global.errInfoRequired')
        return
      }else {
        axios.post("/api/user/bank/save", {
          id: this.id,
          bankInfo: this.bankInfo_br
        })
        .then((response) => {
          if (response.data.code === 0) {
            Toast.success(this.$t('bankCard.success'));
            setTimeout(() => {
            if(this.target){
                this.$router.push({
                  path: this.target,
                  query: {
                    amountType: 'coin'
                  }
                });
              }else{
                this.$router.push("/bankCard");
              }
            }, 1000);
          } else if (response.data.code === 1004){
            let res = response.data.data
            Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
            for(var i in res){	
              switch (res[i].fieldName) {
                case "realName":
                  this.errText_br.realName = res[i].description
                  break;
                case "cpf":
                  this.errText_br.cpf = res[i].description
                  break;
                case "phone":
                  this.errText_br.phone = res[i].description
                  break;
                case "email":
                  this.errText_br.email = res[i].description
                  break;
                case "cnpj":
                  this.errText_br.cnpj = res[i].description
                  break;
              }
            }
          }
        })
      }
    },
    addBankCard_VN(){
      this.errText_vn = {
        realName: '',
        bankCode: '',
        bankAccount: ''
      }
      if(this.bankInfo_vn.bankCode === ""){
        this.errText_vn.bankCode = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo_vn.bankAccount === ""){
        this.errText_vn.bankAccount = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo_vn.realName === ""){
        this.errText_vn.realName = this.$t('global.errInfoRequired')
        return
      }else {
        axios
          .post("/api/user/bank/save", {
            id: this.id,
            bankInfo: this.bankInfo_vn
          })
          .then((response) => {
            if (response.data.code === 0) {
              Toast.success(this.$t('bankCard.success'));
              setTimeout(() => {
                if(this.target){
                  this.$router.push({
                    path: this.target,
                    query: {
                      amountType: 'coin'
                    }
                  });
                }else{
                  this.$router.push("/bankCard");
                }
              }, 1000);
            } else if (response.data.code === 1004){
              let res = response.data.data
              Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "realName":
                    this.errText_vn.realName = res[i].description
                    break;
                  case "bankCode":
                    this.errText_vn.bankCode = res[i].description
                    break;
                  case "bankAccount":
                    this.errText_vn.bankAccount = res[i].description
                    break;
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            Toast.fail(this.$t('global.networkError'));
          });
      }
    },  
    addBankCard() {
      this.bankInfo.realName = this.bankInfo.realName.replace(/\s*/g,"")
      this.realNameErrText = ''
      this.ifscCodeErrText = ''
      this.bankNameErrText = ''
      this.bankAccountErrText = ''
      this.upiVpaErrText = ''
      this.emailErrText = ''
      
      this.bankInfo.realName = this.bankInfo.realName.trim()
      this.bankInfo.ifscCode = this.bankInfo.ifscCode.trim()
      this.bankInfo.bankName = this.bankInfo.bankName.trim()
      this.bankInfo.bankAccount = this.bankInfo.bankAccount.trim()
      this.bankInfo.upiVpa = this.bankInfo.upiVpa.trim()
      this.bankInfo.email = this.bankInfo.email.trim()
      
      if(this.bankInfo.realName === ""){
        this.realNameErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.ifscCode === ""){
        this.ifscCodeErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.bankName === ""){
        this.bankNameErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.bankAccount === ""){
        this.bankAccountErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.upiVpa === ""){
        this.upiVpaErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.email === ""){
        this.emailErrText = this.$t('global.errInfoRequired')
        return
      }else{
        axios
          .post("/api/user/bank/save", {
            id: this.id,
            bankInfo: this.bankInfo
          })
          .then((response) => {
            if (response.data.code === 0) {
              Toast.success(this.$t('bankCard.success'));
              setTimeout(() => {
                if(this.target){
                  this.$router.push({
                    path: this.target,
                    query: {
                      amountType: 'coin'
                    }
                  });
                }else{
                  this.$router.push("/bankCard");
                }
              }, 1000);
            } else if (response.data.code === 1004){
              let res = response.data.data
              Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "realName":
                    this.realNameErrText = res[i].description
                    break;
                  case "ifscCode":
                    this.ifscCodeErrText = res[i].description
                    break;
                  case "bankName":
                    this.bankNameErrText = res[i].description
                    break;
                  case "bankAccount":
                    this.bankAccountErrText = res[i].description
                    break;
                  case "upiVpa":
                    this.upiVpaErrText = res[i].description
                    break;
                  case "email":
                    this.emailErrText = res[i].description
                    break;
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            Toast.fail(this.$t('global.networkError'));
          });
      }
    },
    showBankNamePopup(){
      this.bankNamePopup = true
    }
  },
  watch: {
    '$route': 'gettingData'
  },
  created() {
    this.country = getCountry()
    this.gettingData()
  },
  setup() {
    const state = reactive({});
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      titIconClick(){
        router.push({
        name: "myHome",
      });
      }
    };
    return {
      ...methods,
      state,
    };
  },
};
</script>
<style scoped>


.addcard-text{ line-height: 16px; text-align: center; background: #fff5ed; padding: 10px; margin: 0 0 10px 0; color: #916d52; border-radius:5px;}
.c-red{color:rgb(243, 0, 0); margin-left: 5px;}
.login-inp{position: relative;}
.login {
  background: url(../assets/images/bg15_1225c.png) 0 -50px no-repeat;
  background-size: 100% auto;
}
.login-bd {
  padding: 20px;
  background: #fff;
  margin: 20px;
  box-shadow: 0px 0px 5px #d9d2f4;
  border-radius: 10px;
}
.login-bd h3 {
  margin: 10px 0 10px 0;
  font-size: 20px;
  color: #333;
  font-weight: 400;
  text-align: center;
}
.login-bd .nut-input {
  padding: 5px 0 5px 0px;
}
.login-btn {
  margin: 30px 0 10px 0;
}
.login-bn {
  padding: 20px 20px 0px 20px;
}
.login-bn h2 {
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: center;
  color: #efe7f4;
}
.otpbtn {
  float: right;
}
.nut-input-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.w100 .input-text {
  width: 100% !important;
}
</style>
<style>
.nut-input .input-text {
  padding: 0 0px 0 0px !important;
  width: 60% !important;
}
.pop-bankName {
  width: 98% !important;
  height: 520px;
  top: 50%;
  border-radius: 10px;
  background: #fff !important;  text-align: center;overflow-y: hidden !important;
}
.pop-bankName .bd{ margin: 10px;}
</style>